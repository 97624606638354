import React, { useContext, useMemo } from "react";
import { Grid, Hidden } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CardWrapper from "../../../../components/NewCardWrapper";
import { routes } from "../../../../App";
import NavContainer from "../../../../components/NewNavContainer";
import { BackStyle } from "../../Styles";
import CardWrapperFilled from "../../../../components/NewCardWrapper/CardWrapperFilled";
import {
  EmailIcon,
  BillingIssuesIcon,
  WorldIcon,
} from "../../../../assets/icons";
import { useGetSystemVariables } from "../../../../hooks/useGetSystemVariables";
import sendErrorToast from "../../../../utils/sendErrorToast";
import {
  Answer,
  EmailStyle,
  LinkStyle,
  Message,
  Question,
  RowContainer,
} from "./Styles";
import {
  BillingContext,
  BillingType,
} from "../../../../components/BillingProvider";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";

const leftItems = {
  "How do I pay for my internet?":
    "Your internet bill is separate from our operations and maintenance services. For details or queries about your bill and payment options, please contact your Internet Service Provider (ISP).",
  "Are there any penalties or late fees for overdue payments of the operations and maintenance service fees?":
    "Please keep your payment method current to avoid a $5.00 late fee.",
  "Can I view and download my invoice?":
    'Yes, view and download invoices from the Payment History in your Billing dashboard by clicking "View" on your chosen statement.',
  "Can I receive physical bills?":
    "We provide e-bills and e-statements for convenience and eco-friendliness. Visit your billing dashboard to view and download invoices anytime. This method ensures quick access and reduces paper waste.",
};

const BillingIssues = () => {
  const { prices } = useContext(BillingContext) as BillingType;
  const { provider } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const operatorEmail = useMemo(() => {
    const billingEmail = provider?.data?.provider?.Contacts?.find(
      (e) => e.type === "BILLING"
    );
    return (
      billingEmail?.email ??
      provider?.data?.provider?.Contacts?.[0]?.email ??
      ""
    );
  }, [provider]);

  const feeKey = `What is the extra $${
    prices?.cc_initial_processing_fee?.amount?.toFixed(2) ?? 1.5
  } fee on my bill?`;
  const rightItems = {
    [feeKey]: `The extra $${
      prices?.cc_initial_processing_fee?.amount?.toFixed(2) ?? 1.5
    } fee on your bill covers credit card processing. To avoid this, switch to ACH payment via your billing dashboard—it's quick and easy!`,
    "Where can I pay my bill or setup AutoPay?":
      "Our automated payment system charges your provided method on the 1st of each month, eliminating the need for manual payments.",
  };

  const navigate = useNavigate();
  const { data } = useGetSystemVariables({
    refetchOnWindowFocus: false,
    onError: () =>
      sendErrorToast(
        "There was an error getting the recover username link, please try again"
      ),
  });
  function handleRedirectToTroubleShootingPage() {
    window.open(
      `${data?.data?.system.support_ticket_url}`,
      "_blank",
      "noopener,noreferrer"
    );
  }
  function handleRedirectToEmail() {
    operatorEmail &&
      window.open(`mailto:${operatorEmail}`, "_blank", "noopener,noreferrer");
  }
  const backAction = (
    <BackStyle
      onClick={() => {
        console.log({ routes });
        console.log(routes.help);
        navigate(routes.help.path);
      }}
    >
      Back to Help
    </BackStyle>
  );

  return (
    <NavContainer title="Help">
      <CardWrapper title="Billing Issues" actions={backAction}>
        <Grid container>
          <Grid item md={12} lg={6}>
            {Object.entries(leftItems).map(([question, answer]) => (
              <Grid item key={question}>
                <Question>{question}</Question>
                <Answer>{answer}</Answer>
              </Grid>
            ))}
          </Grid>
          <Grid item md={12} lg={6} sx={{ zIndex: 2 }}>
            <Hidden lgUp>
              {Object.entries(rightItems).map(([question, answer]) => (
                <Grid item key={question}>
                  <Question>{question}</Question>
                  <Answer>{answer}</Answer>
                </Grid>
              ))}
            </Hidden>
            <Hidden lgDown>
              {Object.entries(rightItems).map(([question, answer]) => (
                <Grid item key={question} sx={{ marginLeft: "2rem" }}>
                  <Question>{question}</Question>
                  <Answer>{answer}</Answer>
                </Grid>
              ))}
            </Hidden>
            <CardWrapperFilled
              title="Need Further Assistance?"
              sx={{ marginTop: "3.9rem", zIndex: 2 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={7}>
                  <Message>
                    Our customer service is available to assist you with any
                    further needs or inquiries you may have.
                  </Message>
                  <Message>
                    Having trouble with your service? Please contact your
                    service provider.
                  </Message>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <RowContainer onClick={handleRedirectToTroubleShootingPage}>
                    <span style={{ verticalAlign: "middle" }}>
                      <WorldIcon style={{ width: "2rem" }} />
                    </span>
                    <LinkStyle>Click Here for Help</LinkStyle>
                  </RowContainer>
                  <RowContainer onClick={handleRedirectToEmail}>
                    <span style={{ verticalAlign: "middle" }}>
                      <EmailIcon />
                    </span>
                    <EmailStyle>{operatorEmail}</EmailStyle>
                  </RowContainer>
                </Grid>
              </Grid>
            </CardWrapperFilled>

            <div
              style={{
                float: "right",
                marginTop: "83px",
                marginRight: "32px",
              }}
            >
              <BillingIssuesIcon
                style={{
                  width: "150.2px",
                  height: "116px",
                }}
              />
            </div>
          </Grid>
        </Grid>
      </CardWrapper>
    </NavContainer>
  );
};
export default BillingIssues;
