// This component is not used right now
// This is a placeholder for future work
// import useViewport from "../../hooks/useViewport";
// import { theme } from "../../theme";
// import FoundEmail from "./FoundEmail";

const ForgotPassword = () => {
  // const { width, isMobile } = useViewport(1220);
  // const colors = theme["new"].colors;

  return (
    <>
      {/* <DeviceNumber /> */}
      {/* <SecurityQuestions /> */}
      {/* <FoundEmail />*/}
    </>
  );
};

export default ForgotPassword;
