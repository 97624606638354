import React from "react";
import { styled } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ReportIcon from "@mui/icons-material/Report";
import Tooltip from "@mui/material/Tooltip";

export enum StatusBadgeStatus {
  PAID = "Paid",
  FAILED = "Failed",
  PENDING = "Pending",
}

export interface StatusProps {
  status: StatusBadgeStatus;
  tooltip: string;
}

export const StatusBadge: React.FC<StatusProps> = ({
  status = StatusBadgeStatus.PAID,
  tooltip = "",
}) => {
  let icon: React.ElementType = ReportIcon;
  let label = StatusBadgeStatus.FAILED;
  let backgroundColor = "#ffd2d2";
  let textColor = "#ec0000";

  if (status === StatusBadgeStatus.PAID) {
    icon = DoneIcon;
    label = StatusBadgeStatus.PAID;
    backgroundColor = "#84e88e";
    textColor = "#0b8718";
  } else {
    icon = ReportIcon;
    label = StatusBadgeStatus.FAILED;
    backgroundColor = "#ffd2d2";
    textColor = "#ec0000";
  }

  const StatusWrapper = styled("span")`
    height: 21px;
    padding: 2px;
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
    vertical-align: middle;
    color: ${textColor};
    background-color: ${backgroundColor};
    border-radius: 16px;
  `;

  const IconWrapper = styled("span")`
    height: 15px;
    vertical-align: middle;
  `;

  return (
    <Tooltip title={tooltip}>
      <div style={{ textAlign: "center" }}>
        <StatusWrapper>
          <IconWrapper>
            {React.createElement(icon, { fontSize: "small" })}
          </IconWrapper>
          {label}
        </StatusWrapper>
      </div>
    </Tooltip>
  );
};

export default StatusBadge;
