export const useLocalStorage = () => {
  const getItemFromStorage = (key: string) =>
    JSON.parse(localStorage.getItem(key) ?? "{}");
  const setItemInStorage = (key: string, value: any) =>
    localStorage.setItem(key, JSON.stringify(value));
  const removeItemFromStorage = (key: string) => localStorage.removeItem(key);
  const clearStorage = () => localStorage.clear();

  return {
    getItemFromStorage,
    setItemInStorage,
    removeItemFromStorage,
    clearStorage,
  };
};
