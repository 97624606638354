import { styled } from "@mui/material";
import { theme } from "../../../../theme";

export const VbgTitle = styled("p")`
  width: 565px;
  height: 39px;
  margin: 42.4px 4px 17px 16px;
  font-family: Montserrat;
  font-size: 2rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${theme.new.colors.darkGreen};
`;

export const Location = styled("span")`
  width: 18px;
  height: 24px;
  color: ${theme.new.colors.teal};
`;

export const VbgInfo = styled("div")`
  height: 28px;
  flex-grow: 0;
  margin-left: 16px;
`;

export const StreetInfo = styled("span")`
  width: 128px;
  height: 25px;
  flex-grow: 0;
  margin: 2px 0 0 15px;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: ${theme.new.colors.darkGreen};
`;

export const Switch = styled("span")`
  width: 24px;
  height: 24px;
  flex-grow: 0;
  margin: 0 0 3px 8px;
  object-fit: contain;
`;

export const Router = styled("span")`
  width: 20px;
  height: 11.8px;
  flex-grow: 0;
  color: ${theme.new.colors.teal};
`;

export const Hardware = styled("span")`
  width: 121px;
  height: 25px;
  flex-grow: 0;
  margin: 3px 0 0 15px;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: ${theme.new.colors.darkGreen};
`;

export const MyService = styled("span")`
  margin: 0 0 24px;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.new.colors.darkGreen};
  float: left;
`;

export const ViewMyService = styled("span")`
  width: 107px;
  height: 18px;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.new.colors.teal};
  float: right;
  cursor: pointer;
`;

export const MyServiceRow = styled("div")`
  margin-top: 40px;
  margin-left: 16px;
`;
