import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";

/* We are using the History Router to be able to use History outside the router and in the fetchWrapper function.
We made sure there´s no mismatching of the history packages, so we avoid the warning which triggered
the unstable flag (you can read more here: https://reactrouter.com/docs/en/v6/routers/history-router)
*/

import { BrowserRouter } from "react-router-dom";

const queryClient = new QueryClient();
queryClient.setDefaultOptions({
  queries: {
    retry: 0,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
