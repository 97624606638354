import { Button, ButtonProps, CircularProgress } from "@mui/material";
import useGetThemePath from "../../hooks/useGetThemePath";
import { ButtonSize, getButtonStyles, getSpanStyles } from "./Styles";

interface ButtonExtendedProps extends ButtonProps {
  isLoading?: boolean;
  text: string;
}

const StyledButton = (props: ButtonExtendedProps) => {
  const themePath = useGetThemePath();
  return (
    <Button
      sx={getButtonStyles(
        props.size || ButtonSize.small,
        props.variant,
        themePath,
        props.color
      )}
      {...props}
    >
      {props.isLoading ? (
        <CircularProgress
          data-testid="progressSpinner"
          color="inherit"
          size={20}
        />
      ) : (
        <>
          <span style={getSpanStyles(props.size || ButtonSize.medium)}>
            {props.text}
          </span>
          {props.children}
        </>
      )}
    </Button>
  );
};

export default StyledButton;
