import { styled } from "@mui/material";

export const Question = styled("p")`
  color: var(--dark-green-font-color, #253331);

  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 3rem;
  margin-right: 2rem;
`;

export const Answer = styled("p")`
  color: var(--dark-green-font-color, #253331);

  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem;
  margin-right: 2rem;
`;

export const Message = styled("p")`
  color: var(--dark-green-font-color, #253331);

  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.9375rem;
  margin-bottom: 1.5rem;
`;

export const RowContainer = styled("div")`
  display: flex;
  justify-content: middle;
  vertical-align: middle;
  margin-bottom: 0.5rem;
  height: 2.5rem;
  cursor: pointer;
  margin: 0.75rem;
`;

export const LinkStyle = styled("span")`
  text-decoration: underline;
  margin-left: 1.25rem;
`;

export const EmailStyle = styled("span")`
  margin-top: 0.1875rem;
  margin-left: 1.25rem;
  text-decoration: underline;
`;
