import { useState, useEffect, useContext } from "react";
import { Grid, Typography, Stack } from "@mui/material";
import { theme } from "../theme";
import { useSubmitCreditCard } from "./useSubmitCreditCard";
import { useForm } from "react-hook-form";
import {
  BillingContext,
  BillingType,
  CreditCardInfo,
} from "../components/BillingProvider";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../components/AddressAndAccountProvider";
import { useUpdateAccount } from "../hooks/useUpdateAccount";
import { useUpdateUser } from "../hooks/useUpdateUser";
import Label from "../components/NewLabel";
import Input from "../components/NewInput";
import ErrorMessage from "../components/NewErrorMessage";
import useSetPaymentErrorCode from "./useSetPaymentErrorCode";
import formatExpirationDate from "../utils/formatExpirationDate";
import sendErrorToast from "../utils/sendErrorToast";
import getREMFromPX from "../utils/getREMFromPX";

const useCreditCardForm = (onSuccess?: Function) => {
  const colors = theme["new"].colors;
  const { paymentMethod } = useContext(BillingContext) as BillingType;
  const { userInfo, setUserInfo, selectedAccountId } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const [error, setError] = useState("");

  const userId = userInfo?.data?.user?.id ?? "";

  const form = useForm<CreditCardInfo>({
    mode: "onChange",
    defaultValues: {
      cardHolderName: "",
      cardNumber: "",
      cvc: "",
      cvv: "",
      expirationDate: "",
      zip: "",
    },
  });

  const errorMessage = useSetPaymentErrorCode(form, error);

  const { mutateAsync: updateUser } = useUpdateUser(userId, {
    onError: () => sendErrorToast("There was an error activating the user."),
  });

  const { mutateAsync: updateAccount } = useUpdateAccount(selectedAccountId, {
    onError: () => sendErrorToast("There was an error activating the account."),
  });

  useEffect(() => {
    if (paymentMethod) {
      form.setValue("cardHolderName", paymentMethod.owner_name);
      form.setValue(
        "cardNumber",
        paymentMethod.type === "card" && paymentMethod.last4
          ? `**** **** **** ${paymentMethod.last4}`
          : ""
      );
      form.setValue("cvc", paymentMethod.cvc);
      form.setValue("cvv", paymentMethod.cvv);
      form.setValue(
        "expirationDate",
        formatExpirationDate(paymentMethod.exp_month, paymentMethod.exp_year) ??
          ""
      );
      form.setValue("zip", paymentMethod.zip);
    }
  }, [form, paymentMethod]);

  const cardHolderName = form.watch("cardHolderName");
  const cardNumber = form.watch("cardNumber");
  const expirationDate = form.watch("expirationDate");
  const cvc = form.watch("cvc");
  const zip = form.watch("zip");

  const { mutateAsync, isLoading } = useSubmitCreditCard(selectedAccountId, {
    onError: console.log,
    onSuccess: (response) => {
      if (response.message?.includes("Your card number is incorrect")) {
        form.setError("cardNumber", {
          message: "This does not appear to be a valid credit card.",
        });
      } else if (response.message?.includes("expired")) {
        form.setError("expirationDate", {
          message:
            "This date is invalid. Verify expiration date and submit again.",
        });
      } else if (response.message) {
        setError(
          response.message.replace(
            "Error creating Stripe Customer Payment Method: ",
            ""
          )
        );
      } else {
        onSuccess?.(response);
        if (userInfo?.data?.user?.status === "PENDING") {
          // User and Account need to be set active because payment was accepted
          updateUser({
            status: "ACTIVE",
          });
          updateAccount({
            status: "ACTIVE",
          });
          setUserInfo((info: any) => ({
            ...info,
            data: {
              ...info?.data,
              user: {
                ...info?.data?.user,
                status: "ACTIVE",
              },
            },
          }));
        }
      }
    },
  });

  const submit = () => {
    if (!selectedAccountId) {
      sendErrorToast("No account ID provided");
      return;
    }

    let arr: string[] = ["utility"];
    // if(warranty?.insuranceSel)
    //   arr.push("vbg_insurance");

    const ccValues = form.getValues();

    const splitExp = ccValues.expirationDate?.split("/") ?? ["", ""];

    const body = {
      owner_name: ccValues.cardHolderName,
      number: ccValues.cardNumber?.replaceAll(/\s/g, ""),
      exp: ccValues.expirationDate,
      exp_month: parseInt(splitExp[0]),
      exp_year: parseInt(splitExp[1]),
      cvv: ccValues.cvv,
      cvc: ccValues.cvc,
      zip: ccValues.zip,
      items: arr,
      is_default_payment_method: true,
    };
    mutateAsync(body);
  };

  // function to validate credit card format
  function validateCreditCard(cardNumber: any) {
    cardNumber = cardNumber && cardNumber.trim();
    const tempCardNumber = cardNumber.replaceAll(/\s/g, "");

    return (
      !isNaN(+tempCardNumber) ||
      "This does not appear to be a valid credit card."
    );
  }

  // function to validate cvc value based on credit card number
  function validateCVC(cvcValue: any) {
    let isValid = false;
    if (cardNumber && cvcValue && !isNaN(+cvcValue)) {
      if (
        (cardNumber.indexOf("34") === 0 || cardNumber.indexOf("37") === 0) &&
        cvcValue.length === 4
      ) {
        isValid = true;
      } else if (
        !(cardNumber.indexOf("34") === 0 || cardNumber.indexOf("37") === 0) &&
        cvcValue.length === 3
      ) {
        isValid = true;
      }
    }

    return isValid || "Invalid cvc";
  }

  const validateExpiryDate = (expiryDate: any) => {
    if (!expiryDate) {
      return "Expiration date is required";
    }

    const MONTH_REGEX = /(0[1-9]|1[0-2])/;
    const MONTH_OUT_OF_RANGE = "Expiry month must be between 01 and 12";
    const YEAR_OUT_OF_RANGE = "Expiry year cannot be in the past";
    const DATE_OUT_OF_RANGE = "Expiry date cannot be in the past";
    const INVALID_EXPIRY_DATE = "Expiry date is invalid";

    const rawExpiryDate = expiryDate.replace(" / ", "").replace("/", "");

    if (rawExpiryDate.length === 4) {
      const month = rawExpiryDate.slice(0, 2);
      const year = `20${rawExpiryDate.slice(2, 4)}`;

      if (!MONTH_REGEX.test(month)) {
        return MONTH_OUT_OF_RANGE;
      }

      if (parseInt(year) < new Date().getFullYear()) {
        return YEAR_OUT_OF_RANGE;
      }

      if (
        parseInt(year) === new Date().getFullYear() &&
        parseInt(month) < new Date().getMonth() + 1
      ) {
        return DATE_OUT_OF_RANGE;
      }

      return true;
    }
    return INVALID_EXPIRY_DATE;
  };

  function cc_format(value: any) {
    const v = value
      .replace(/\s+/g, "")
      .replace(/[^0-9]/gi, "")
      .substr(0, 16);
    const parts = [];

    for (let i = 0; i < v.length; i += 4) {
      parts.push(v.substr(i, 4));
    }

    return parts.length > 1 ? parts.join(" ") : value;
  }

  const formatExpiry = (date: string) => {
    const prevExpiry = date.split(" / ").join("/");

    if (!prevExpiry) return "";
    let expiry: any = prevExpiry;

    // if number less than 10, prepend 0 to it
    if (/^[2-9]$/.test(expiry)) {
      expiry = `0${expiry}`;
    }

    // if month input exceeds 12, i.e 14 or 40 -> set to 01/4 or 04/
    if (prevExpiry.length === 2 && +prevExpiry > 12) {
      const [head, ...tail] = prevExpiry.split?.("");
      expiry = `0${head}/${tail.join("")}`;
    }

    expiry = expiry.match?.(/(\d{1,2})/g) || [];

    if (expiry.length === 1) {
      if (prevExpiry.includes("/")) {
        return expiry[0];
      }
      if (/\d{2}/.test(String(expiry))) {
        return `${expiry[0]} / `;
      }
    }

    if (expiry.length > 2) {
      const [, month = null, year = null] =
        expiry.join?.("").match(/^(\d{2}).*(\d{2})$/) || [];
      return [month, year].join(" / ");
    }

    return expiry.join?.(" / ");
  };

  const fields = (
    <>
      {!!errorMessage && (
        <Typography
          fontFamily="Montserrat"
          fontSize={getREMFromPX(14)}
          fontWeight="500"
          fontStyle="normal"
          lineHeight="25px"
          letterSpacing={0.28}
          textAlign="left"
          color={colors.errorRed}
          marginTop="15px"
        >
          {errorMessage}
        </Typography>
      )}
      <Grid container sx={{ margin: "40px 0 0 0", width: "100%" }}>
        <Grid item xs={12}>
          <Stack>
            <Label htmlFor="cardHolderName">Cardholder Name</Label>
            <Input
              id="cardHolderName"
              data-testid="cardHolderName"
              {...form.register("cardHolderName", { required: true })}
            />
            {!!form.formState.errors.cardHolderName &&
              !!cardHolderName?.length && (
                <ErrorMessage>
                  {form.formState.errors.cardHolderName.message}
                </ErrorMessage>
              )}
          </Stack>
        </Grid>
      </Grid>
      <Grid container sx={{ margin: "16px 0", width: "100%" }}>
        <Grid item xs={12}>
          <Stack>
            <Label htmlFor="name">Card Number</Label>
            <Input
              {...form.register("cardNumber", {
                required: true,
                validate: validateCreditCard,
              })}
              onChange={(event) =>
                form.setValue("cardNumber", cc_format(event.target.value), {
                  shouldValidate: true,
                })
              }
            />
            {!!form.formState.errors.cardNumber && !!cardNumber?.length && (
              <ErrorMessage>
                {form.formState.errors.cardNumber.message}
              </ErrorMessage>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Grid container sx={{ width: "100%" }}>
        <Grid item xs={4}>
          <Stack style={{ width: "95%" }}>
            <Label htmlFor="expiryDate">Expiration</Label>
            <Input
              data-testid="expiryDate"
              {...form.register("expirationDate", {
                required: true,
                validate: validateExpiryDate,
              })}
              value={formatExpiry(expirationDate ?? "")}
            />
            {!!form.formState.errors.expirationDate &&
              !!expirationDate?.length && (
                <ErrorMessage>
                  {form.formState.errors.expirationDate.message}
                </ErrorMessage>
              )}
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack style={{ width: "95%" }}>
            <Label htmlFor="cvc">CVC</Label>
            <Input
              id="cvc"
              data-testid="cvc"
              {...form.register("cvc", {
                required: true,
                validate: validateCVC,
              })}
            />
            {!!form.formState.errors.cvc &&
              !!cvc?.length &&
              !!cardNumber?.length && (
                <ErrorMessage>{form.formState.errors.cvc.message}</ErrorMessage>
              )}
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack>
            <Label htmlFor="zip">Zip Code</Label>
            <Input
              data-testid="zip"
              {...form.register("zip", {
                required: true,
                maxLength: 5,
                pattern: { value: /^\d{5}$/, message: "Invalid zip code" },
              })}
            />
            {!!form.formState.errors.zip && !!zip?.length && (
              <ErrorMessage>{form.formState.errors.zip.message}</ErrorMessage>
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );

  return {
    form,
    fields,
    submit,
    isLoading,
    error,
    setError,
  };
};

export default useCreditCardForm;
