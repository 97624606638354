import { styled } from "@mui/system";
import React from "react";
import { LabelStyles } from "./Styles";

const StyledLabel = styled("label")`
  ${LabelStyles}
`;

const Label = ({
  children,
  ...props
}: React.LabelHTMLAttributes<HTMLLabelElement>) => (
  <StyledLabel {...props}>{children}</StyledLabel>
);

export default Label;
