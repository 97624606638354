import { useMutation, UseMutationOptions } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { Notification } from "../api/interfaces/Notification";
import fetcher from "../utils/fetchWrapper";

const clearNotification = async (notification: Notification) => {
  const response = await fetcher(`${endpoints.message}/${notification.id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error();
  }
  return true;
};

export const useClearNotification = (
  options?: UseMutationOptions<any, Error, any>
) =>
  useMutation(
    (notification: Notification) => clearNotification(notification),
    options
  );
