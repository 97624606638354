import React from "react";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";

export const headerStyle = {
  fontSize: getREMFromPX(theme.spacing * 5),
  fontWeight: theme.fonts.weights.bolder,
};

export const linkStyle = {
  marginLeft: getREMFromPX(theme.spacing),
};

export const textStyle = {
  marginTop: getREMFromPX(theme.spacing * 4),
} as React.CSSProperties;

export const agreementSectionStyles = {
  background: theme.backgrounds.primarySelection,
};

export const buttonStyle = {
  marginLeft: "auto",
};

export const iconStyle = {
  marginRight: getREMFromPX(theme.spacing * 2),
};
