import React from "react";
import MyServices from "./MyServices";
import sendErrorToast from "../../utils/sendErrorToast";
import useGetUserId from "../../hooks/useGetUserId";
import { useGetMySubscriptions } from "../../hooks/useGetMySubscriptions";
import { Device } from "../../api/interfaces/Device";
import { Account } from "../../api/interfaces/Account";

const processData = (subscriptionsData: any) => {
  const subscriptions = subscriptionsData?.data?.subscription || [];

  return subscriptions.map((subscriptionData: any) => {
    return subscriptionData;
  });
};

interface MyServicesIndexProps {
  currentDevice: Device;
  currentAccount: Account;
}

const MyServicesIndex = ({
  currentDevice,
  currentAccount,
}: MyServicesIndexProps) => {
  const userId = useGetUserId();

  const { data: subscriptionsData } = useGetMySubscriptions(
    userId,
    currentAccount.id,
    {
      enabled: !!userId && !!currentAccount.id,
      refetchOnWindowFocus: false,
      onError: () =>
        sendErrorToast(
          "There was an error getting the subscriptions information, please try again"
        ),
    }
  );

  return <MyServices subscriptions={processData(subscriptionsData)} />;
};

export default MyServicesIndex;
