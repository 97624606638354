import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import CardWrapper from "../../../../components/NewCardWrapper";
import { ServiceProviderIcon } from "../../../../assets/icons";
import { routes } from "../../../../App";
import { Grid } from "@mui/material";
import {
  ContinueIcon,
  Description,
  ImageContainer,
  SubDescription,
  SupportInfo,
  iconStyles,
  IconText,
  ButtonService,
} from "../../Styles";
import { useGetMySubscriptions } from "../../../../hooks/useGetMySubscriptions";
import useGetUserId from "../../../../hooks/useGetUserId";
import sendErrorToast from "../../../../utils/sendErrorToast";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LogoContainer } from "../../../../routes/Services/components/CardService";

const ServiceProviders = ({
  styles,
}: {
  children?: React.ReactElement;
  styles: React.CSSProperties;
}) => {
  const navigate = useNavigate();

  const userId = useGetUserId();

  const { selectedAccountId } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const { data: subscriptionsData } = useGetMySubscriptions(
    userId,
    selectedAccountId,
    {
      enabled: !!userId && !!selectedAccountId,
      refetchOnWindowFocus: false,
      onError: () =>
        sendErrorToast(
          "There was an error getting the subscriptions information, please try again"
        ),
    }
  );

  const arrowAction = subscriptionsData?.data?.subscription?.length ? (
    <ContinueIcon
      onClick={() => navigate(routes.helpService.path)}
      data-cy="help-providers-arrow"
    />
  ) : (
    ""
  );

  const serviceAction = (
    <ButtonService
      onClick={() => navigate(routes.services.path)}
      style={{ pointerEvents: "auto" }}
    >
      Services
    </ButtonService>
  );

  return (
    <CardWrapper
      title="Service Provider Info"
      actions={arrowAction}
      sx={styles}
    >
      <Grid
        container
        spacing={2}
        onClick={() =>
          subscriptionsData?.data?.subscription?.length
            ? navigate(routes.helpService.path)
            : navigate(routes.services.path)
        }
      >
        <Grid item sm={12} md={6}>
          {subscriptionsData?.data?.subscription?.length > 1 ? (
            <Description>
              Need help with <br /> your service?
            </Description>
          ) : subscriptionsData?.data?.subscription?.length === 1 ? (
            <Description>
              <p>
                Need help with <br /> your service?
                <SubDescription>
                  Please contact your service <br />
                  provider.
                </SubDescription>
              </p>
            </Description>
          ) : (
            <Description>
              <div>
                You have not <br /> subscribed to any <br /> services <br />
                <SubDescription>
                  please head to services <br /> page to subscribe
                </SubDescription>
                {serviceAction}
              </div>
            </Description>
          )}
        </Grid>
        <Grid item sm={12} md={6}>
          {subscriptionsData?.data?.subscription?.length === 1 ? (
            <div>
              <LogoContainer width="100%" height="100%">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/provider/${subscriptionsData?.data?.subscription[0]?.Serviceplan?.Service.Provider?.id}.png`}
                  alt=""
                />
              </LogoContainer>
              <SupportInfo>
                <FontAwesomeIcon icon="globe" style={iconStyles} />
                <IconText>
                  {
                    subscriptionsData?.data?.subscription[0]?.Serviceplan
                      ?.Service?.support_url
                  }
                </IconText>
              </SupportInfo>
              <SupportInfo>
                <FontAwesomeIcon icon="envelope" style={iconStyles} />
                <IconText>
                  {
                    subscriptionsData?.data?.subscription[0]?.Serviceplan
                      ?.Service.Provider?.Contacts[0]?.email
                  }
                </IconText>
              </SupportInfo>
              <SupportInfo>
                <FontAwesomeIcon icon="phone" style={iconStyles} />
                <IconText>
                  {
                    subscriptionsData?.data?.subscription[0]?.Serviceplan
                      ?.Service.Provider?.Contacts[0]?.phone
                  }
                </IconText>
              </SupportInfo>
            </div>
          ) : (
            <ImageContainer>
              <ServiceProviderIcon
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  marginBottom: "1.84rem",
                }}
              />
            </ImageContainer>
          )}
        </Grid>
      </Grid>
    </CardWrapper>
  );
};
export default ServiceProviders;
