import React from "react";

export type SortConfig = {
  direction: string;
  key: string;
} | null;

const useSortableData = (
  items: any[],
  customSortFunction?: (config: SortConfig) => any[],
  config: null | SortConfig = null
) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      if (customSortFunction) {
        const sortableItems = customSortFunction(sortConfig);
        return sortableItems;
      }
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig, customSortFunction]);

  const requestSort = (key: string) => {
    let direction = "ascending";
    if (sortConfig?.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    if (sortConfig?.key === key && sortConfig.direction === "descending") {
      setSortConfig(null);
      return;
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};
export default useSortableData;
