import { Stack } from "@mui/material";
import SelectOption from "../../../../components/SelectOption";
import Select from "../../../../components/Select";
import Button from "../../../../components/Button";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { ServiceInfo } from "../../../../api/interfaces/ServiceInfo";
import { Inputs, FilterValueProperty } from "../../Plans";
import { useMemo } from "react";
import { searchButtonStyles, selectStyles } from "./styles";
import getProperLabelValue from "../../../../utils/getProperLabelValue";

const SelectContainer = ({
  validServices,
  inputs,
  setInputs,
  handleSearchResults,
}: {
  validServices: ServiceInfo[];
  inputs: Inputs;
  setInputs: React.Dispatch<React.SetStateAction<Inputs>>;
  handleSearchResults: () => void;
}) => {
  const selectValues = useMemo(
    () =>
      (
        JSON.parse(
          validServices[0].Servicetype.fields_template_json
        ) as FilterValueProperty[]
      )
        .filter((property) => property.filter === "1")
        .map(({ name, placeholder }) =>
          validServices
            .map((service) =>
              service.Plans.map((plan) => ({
                name: name,
                value: plan.fields[name],
                placeholder,
              })).flat()
            )
            .flat()
        )
        .flat()
        .reduce(
          (
            acc: {
              [x: string]: {
                values: string[] | number[];
                placeholder: string;
              };
            },
            currentValue
          ) => {
            acc[currentValue.name] = acc[currentValue.name] || {
              values: [],
              placeholder: "",
            };
            acc[currentValue.name].values = acc[currentValue.name].values || [];
            acc[currentValue.name].placeholder =
              acc[currentValue.name].placeholder || currentValue.placeholder;

            const doesValueExistInAcc = acc[currentValue.name].values.includes(
              currentValue.value as never
            );
            if (!doesValueExistInAcc) {
              acc[currentValue.name].values.push(currentValue.value as never);
            }
            return acc;
          },
          {}
        ),
    [validServices]
  );

  return (
    <Stack
      flex={1}
      flexWrap="wrap"
      direction="row"
      gap={getREMFromPX(theme.spacing * 4)}
      alignItems="center"
    >
      {Object.entries(selectValues)
        .map((selectValue) => {
          const [name, valueArray] = selectValue;
          const sortedValues = valueArray.values.sort(
            (a, b) => (a as number) - (b as number)
          );
          return {
            placeholder: valueArray.placeholder,
            name,
            options: sortedValues.map((option) => {
              const value = getProperLabelValue(name, option as string);
              return (
                <SelectOption
                  data-testid={option}
                  key={option}
                  value={value !== "" ? value : undefined}
                >
                  {value}
                </SelectOption>
              );
            }),
          };
        })
        .map(({ name, placeholder, options }) => {
          return (
            <Select
              aria-label={`filter by ${name}`}
              value={inputs[name] ? inputs[name].value : ""}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  [name]: {
                    ...inputs[name],
                    value: e.target.value as string,
                  },
                })
              }
              data-testid={name}
              key={name}
              style={selectStyles}
              placeholder={name}
            >
              {options}
            </Select>
          );
        })}
      <Button
        style={searchButtonStyles}
        size="large"
        text="Search"
        onClick={handleSearchResults}
      />
    </Stack>
  );
};
export default SelectContainer;
