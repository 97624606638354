import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { ButtonSize, getSpanStyles } from "../Button/Styles";
import useViewport from "../../hooks/useViewport";
import React from "react";
import getREMFromPX from "../../utils/getREMFromPX";
import { theme } from "../../theme";

interface NewButtonProps extends ButtonProps {
  text: string | React.ReactNode;
  isLoading?: boolean;
  mode: "default" | "secondary" | "black" | "disabled";
  dataCy?: string;
}

const StyledButton = (props: NewButtonProps) => {
  const { isMobile } = useViewport();

  const colors = theme["new"].colors;

  const commonStyles = {
    padding: isMobile ? "12px" : "20px",
    borderRadius: "15px",
    fontWeight: 700,
    fontSize: isMobile ? getREMFromPX(12) : getREMFromPX(20),
    lineHeight: "24px",
    fontFamily: "Montserrat",
  };

  const commonHoverStyle = {
    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
  };

  const disabledStyle = !!props.disabled
    ? {
        backgroundColor: "#E2E2E2",
        color: colors.mediumGreen,
        "&:hover": {
          backgroundColor: "#E2E2E2",
          ...commonHoverStyle,
        },
        "&active": {
          backgroundColor: "#E2E2E2",
        },
      }
    : {};

  const getModeStyle = () => {
    switch (props.mode) {
      case "secondary":
        return {
          backgroundColor: colors.teal,
          color: colors.white,
          "&:hover": {
            backgroundColor: colors.teal,
            ...commonHoverStyle,
          },
          "&active": {
            backgroundColor: colors.mediumGreen,
          },
        };
      case "black":
        return {
          backgroundColor: colors.formGreyDark,
          color: colors.white,
          "&:hover": {
            backgroundColor: colors.formGreyDark,
            ...commonHoverStyle,
          },
          "&active": {
            backgroundColor: "#5c6f6c",
          },
        };
      case "disabled":
        return {
          backgroundColor: "#E2E2E2",
          color: colors.mediumGreen,
          "&:hover": {
            backgroundColor: "#E2E2E2",
            ...commonHoverStyle,
          },
          "&active": {
            backgroundColor: "#E2E2E2",
          },
        };
      default:
        return {
          backgroundColor: colors.lightGreen,
          color: colors.darkGreen,
          "&:hover": {
            backgroundColor: colors.lightGreen,
            ...commonHoverStyle,
          },
          "&active": {
            backgroundColor: colors.dustyGreen,
          },
          "&:focus": {
            border: `2px solid ${colors.teal}`,
          },
        };
    }
  };

  return (
    <Button
      {...props}
      sx={{
        ...commonStyles,
        textTransform: "none",
        ...getModeStyle(),
        "&:disabled": {
          color: colors.formGrey,
          border: "none",
        },
        ...disabledStyle,
        ...props.sx,
      }}
      data-cy={props.dataCy}
    >
      {props.isLoading ? (
        <CircularProgress
          data-testid="progressSpinner"
          color="inherit"
          size={20}
        />
      ) : (
        <>
          <div style={getSpanStyles(props.size || ButtonSize.medium)}>
            {props.text}
          </div>
        </>
      )}
    </Button>
  );
};

export default StyledButton;
