import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";

export const getTableRowStyles = (
  isExpanded: boolean,
  selected?: boolean,
  themePath = "default",
  isExpandable?: boolean
) => ({
  cursor: isExpandable ? "pointer" : "auto",
  borderBottom: isExpanded
    ? 0
    : `1px solid ${theme[themePath].colors.textSecondary.secondary}`,
  background:
    selected || isExpanded
      ? theme.backgrounds.primarySelection
      : theme[themePath].colors.white,
});

export const getExpandedRowStyles = (themePath = "default") => ({
  cursor: "pointer",
  borderBottom: `1px solid ${theme[themePath].colors.textSecondary.secondary}`,
  background: theme.backgrounds.primarySelection,
});

export const expandableTableCellStyles = {
  padding: `0 ${getREMFromPX(theme.spacing * 2)} ${getREMFromPX(
    theme.spacing * 4
  )} ${getREMFromPX(theme.spacing * 2)}`,
};
