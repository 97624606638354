import { Backdrop, CircularProgress, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Port as PortInterface } from "../../../../api/interfaces/Port";
import { endpoints } from "../../../../api/constants/endpoints";
import fetcher from "../../../../utils/fetchWrapper";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";
import Button from "../../../../components/Button";
import { useGetPorts } from "../../../../hooks/useGetPorts";
import { useGetServicesWithPlans } from "../../../../hooks/useGetServicesWithPlans";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import useGetUserId from "../../../../hooks/useGetUserId";
import { useIsPortLive } from "../../../../hooks/useIsPortLive";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import sendErrorToast from "../../../../utils/sendErrorToast";
import { Step1Type, Step2Type, Step3Type } from "../../SubscribeToISP";
import Headers from "./components/Headers";
import Port from "./components/Port";
import { headerStyle, portSectionStyles } from "./Styles";
import useViewport from "../../../../hooks/useViewport";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";

interface Step1Props {
  changeStep: (args: Step1Type) => void;
  goBackOneStep: () => void;

  previousValues: {
    step1: Step1Type;
    step2: Step2Type;
    step3: Step3Type;
  };
}

const Step1 = ({ changeStep, previousValues, goBackOneStep }: Step1Props) => {
  const userId = useGetUserId();
  const { isMobile } = useViewport();
  const {
    selectedAccount,
    isLoading: isContextLoading,
    selectedAccountId,
    selectedDeviceId,
  } = useContext(AddressAndAccountContext) as AddressAndAccountContextType;

  const [port, setSelectedPort] = useState(previousValues.step1.port);
  const [showPortSelectedMessage, setShowPortSelectedMessage] = useState(false);
  const [livePorts, setLivePorts] = useState<(PortInterface | undefined)[]>([]);
  const [availablePorts, setAvailablePorts] = useState<
    (PortInterface | undefined)[]
  >([]);

  const portRef = useRef(port);

  const { getItemFromStorage } = useLocalStorage();
  const serviceId = getItemFromStorage("serviceId");

  // not port objects, actually user data
  const { data: ports, isLoading: arePortsLoading } = useGetPorts(
    userId,
    selectedAccountId,
    {
      enabled: !!userId && !!selectedAccountId,
      refetchOnWindowFocus: false,
      onError: () =>
        sendErrorToast(
          "There was an error getting the ports information, please try again"
        ),
    }
  );

  // list of port ids
  const mappedDevices = useMemo(
    () => [
      ...new Set(
        ports?.data?.user?.Accounts?.map((account) =>
          account.Devices?.filter((device) => device.id === selectedDeviceId)
            .filter(
              (port, idx, self) =>
                idx === self.findIndex((e) => e?.id === port?.id)
            ) // filter duplicate ports
            .map((device) =>
              device.Xrefaccountdevice?.map((device) =>
                JSON.parse(device.ports_json)
              ).flat()
            )
            .flat()
        ).flat()
      ),
    ],
    [ports, selectedDeviceId]
  );

  // list of port objects
  const mappedPorts = useMemo(
    () =>
      livePorts
        ? ports?.data?.user?.Accounts?.map((account) =>
            account.Devices?.filter((device) => device.id === selectedDeviceId)
              .map((device) => device.Portinterfaces)
              .flat()
          )
            .flat()
            .filter(
              (port, idx, self) =>
                idx === self.findIndex((e) => e?.id === port?.id)
            ) // filter duplicate ports
            .filter((port) =>
              mappedDevices?.length === 0
                ? port?.type === "LAN"
                : mappedDevices?.includes(port?.id)
            )
            .flat()
            .map((port) => {
              // assign the live object query result to the corresponding device port
              const live = livePorts.find((p) => p?.id === port?.id);
              if (port) {
                port.live = live?.live;
              }
              return port;
            })
            .flat()
        : undefined,
    [ports, livePorts, mappedDevices, selectedDeviceId]
  );

  useEffect(() => {
    if (mappedDevices?.length) {
      Promise.all(
        mappedDevices.map(async (p) => {
          const response = await fetcher(
            `${endpoints.isPortLive}/${selectedDeviceId}/portinterface/${p}/live`
          );

          const parsed = await response.json();

          if (response.ok) {
            return parsed;
          } else {
            throw Error(parsed.message);
          }
        })
      )
        .then((returnedPorts) => {
          setLivePorts(returnedPorts.map((p) => p?.data?.portinterface) ?? []);
        })
        .catch((err) => {
          if (!err.message?.includes(204)) {
            sendErrorToast(err.message);
          } else {
            console.log(err.message);
          }
        });
    }
  }, [mappedDevices, selectedDeviceId]);

  const { data: plans, isLoading: areServicesLoading } =
    useGetServicesWithPlans(serviceId as string, selectedAccount, {
      refetchOnWindowFocus: false,
      enabled: !!selectedAccount,
    });

  const { data: isPortLive, refetch } = useIsPortLive(
    port?.device_id as string,
    port?.id as string,
    {
      enabled: false,
      cacheTime: 0,
      onError: () =>
        sendErrorToast(
          "An error occurred verifying the status of the port, please try again"
        ),
    }
  );

  const themePath = useGetThemePath();

  const isLoading = isContextLoading || areServicesLoading || arePortsLoading;

  const disableButton = !port || !plans || !previousValues.step3 || isLoading;

  useEffect(() => {
    portRef.current = port;
  }, [port]);

  useEffect(() => {
    if (mappedPorts) {
      const availablePort = mappedPorts.find(
        (port) =>
          port?.Subscriptions &&
          port.Subscriptions.length === 0 &&
          port.live?.link_state !== "disabled"
      );

      if (availablePort) {
        setSelectedPort(availablePort);
        setShowPortSelectedMessage(true);
        setAvailablePorts(mappedPorts);
        return;
      }

      if (livePorts?.length) {
        sendErrorToast(
          "There are no ports available. Make sure the cable is connected"
        );
      }
      setAvailablePorts(mappedPorts);
    }
  }, [mappedPorts, livePorts]);

  useEffect(() => {
    if (isPortLive) {
      if (
        isPortLive.data?.portinterface.live?.enabled === false &&
        isPortLive.data?.portinterface.live?.link_state === "down"
      ) {
        sendErrorToast(
          "The selected port is not live. Make sure the cable is connected"
        );
        return;
      }
      changeStep({ port: portRef.current });
    }
  }, [isPortLive, changeStep]);

  return (
    <Stack spacing={getREMFromPX(theme.spacing * 4)}>
      <Typography component="h1" style={headerStyle}>
        Select a port from the gateway to associate with this service
      </Typography>
      {showPortSelectedMessage && (
        <Stack
          borderRadius="8px"
          padding={getREMFromPX(theme.spacing * 4)}
          style={portSectionStyles}
          spacing={getREMFromPX(theme.spacing * 4)}
          width={isMobile ? "-webkit-fill-available" : "inherit"}
        >
          <Typography
            fontWeight={theme.fonts.weights.bolder}
            color={theme[themePath].colors.textPrimary.primary}
          >
            A port has been detected and automatically selected for this
            service.
          </Typography>
        </Stack>
      )}
      <Headers />
      {availablePorts.map((availablePort) => (
        <Port
          key={availablePort?.id}
          port={availablePort as PortInterface}
          disabled={
            (availablePort?.Subscriptions &&
              availablePort?.Subscriptions.length > 0) ||
            availablePort?.live?.link_state === "disabled"
          }
          selected={availablePort?.id === port?.id}
          setSelectedPort={setSelectedPort}
        />
      ))}
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="flex-end"
        mt={getREMFromPX(theme.spacing * 6)}
      >
        <Button
          data-testid="continue_button"
          onClick={() => refetch()}
          text="Save And Continue"
          type="submit"
          size="medium"
          disabled={disableButton}
        />
      </Stack>
      <Backdrop open={isLoading}>
        <CircularProgress data-testid="progressSpinner" color="inherit" />
      </Backdrop>
    </Stack>
  );
};
export default Step1;
