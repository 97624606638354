import { useMutation, UseMutationOptions } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { AccountQueryList } from "../api/interfaces/Account";
import fetcher from "../utils/fetchWrapper";

interface LinkAccountUserBody {
  accountId: string;
  userId: string;
}

const linkAccountUser = async (body: LinkAccountUserBody) => {
  const response = await fetcher(`${endpoints.account}/${body.accountId}/user/${body.userId}/link`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      account_id: body.accountId,
      user_id: body.userId,
    }),
  });

  if (!response.ok) {
    if (response.status === 409) {
      const account = await response.json();
      return {
        ...account,
        message: "account already exists",
      };
    }
    throw new Error();
  }

  return await response.json();
};

export const useLinkAccountUser = (
  options?: UseMutationOptions<AccountQueryList, Error, LinkAccountUserBody>
) => useMutation((body: LinkAccountUserBody) => linkAccountUser(body), options);
