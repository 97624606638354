import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Typography } from "@mui/material";
import Box from "../Box";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import { getIconStyle } from "./Styles";
import useGetThemePath from "../../hooks/useGetThemePath";

const FinalStepScreen = () => {
  const themePath = useGetThemePath();

  return (
    <Box
      //width=//{getREMFromPX(theme.spacing * 150)}
      padding={getREMFromPX(theme.spacing * 12)}
      m="0 auto"
    >
      <Stack alignItems="center" justifyContent="center">
        <FontAwesomeIcon style={getIconStyle(themePath)} icon="thumbs-up" />
        <Typography
          fontSize={getREMFromPX(theme.spacing * 10)}
          fontWeight={theme.fonts.weights.bolder}
          component="h1"
          marginBottom={getREMFromPX(theme.spacing * 8)}
        >
          All Done!
        </Typography>
        <Typography
          fontSize={getREMFromPX(theme.spacing * 4)}
          fontWeight={theme.fonts.weights.bolder}
        >
          Your request is being processed. This may take a moment.
        </Typography>
        <Typography
          fontSize={getREMFromPX(theme.spacing * 4)}
          fontWeight={theme.fonts.weights.bolder}
        >
          The page will refresh once the task has been completed.
        </Typography>
      </Stack>
    </Box>
  );
};
export default FinalStepScreen;
