import { Fragment } from "react";
import { Stack, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { theme } from "../../theme";
import { MenuItemsFields } from "./NavContainer";
import CityIcon from "../../components/CityIcon";

type NavBarProps = {
  title: string;
  breakNav?: boolean;
  menuItems: Array<MenuItemsFields>;
};

const NavButton = ({ icon, current, title, route }: any) => {
  const colors = theme["new"].colors;
  const navigate = useNavigate();
  const cyText = `nav-${title.toLowerCase()}`;
  return (
    <Stack
      width="7.5rem"
      pb="2.5rem"
      sx={{
        cursor: "pointer",
      }}
      onClick={() => (route.path ? navigate(route.path) : route())}
      data-cy={cyText}
    >
      <Stack
        bgcolor={current ? colors.lightestGreen : "transparent"}
        height="3.5rem"
        width="10.5rem"
        left="0.875rem"
        borderRadius="1.75rem"
        alignItems="center"
        pl="1.875rem"
        ml="0.875rem"
        flexDirection="row"
        overflow="visible"
      >
        {icon}
        {current && (
          <Typography
            fontFamily="Montserrat"
            fontSize="1rem"
            fontWeight="bold"
            fontStyle="normal"
            textAlign="left"
            color={colors.darkGreen}
            ml="2.625rem"
            position="absolute"
          >
            {title}
          </Typography>
        )}
      </Stack>
      <Typography
        fontFamily="Montserrat"
        fontSize="0.75rem"
        fontWeight="bold"
        fontStyle="normal"
        textAlign="center"
        color={colors.darkGreen}
        mt="-10px"
      >
        {!current && title}
      </Typography>
    </Stack>
  );
};

const LogoContainer = styled("div")`
  width: 7rem;
  height: 8rem;
  margin-top: 2rem;
  margin-bottom: -1.5rem;
  img {
    width: 7.7rem;
    padding: 0.75rem;
    object-fit: cover;
  }
`;

export const NavBar = ({ title, breakNav = false, menuItems }: NavBarProps) => {
  return (
    <Stack alignContent="flex-start" alignItems="flex-start">
      <LogoContainer>
        <CityIcon />
      </LogoContainer>
      {menuItems.map((item, idx) => (
        <Fragment key={idx}>
          <NavButton
            icon={item.icon}
            current={title === item.title}
            title={item.title}
            route={item?.route}
          />
        </Fragment>
      ))}
    </Stack>
  );
};
