import { useContext, useState } from "react";
import { Grid, Stack, Typography, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import Label from "../../../components/NewLabel";
import Input from "../../../components/NewInput";
import { Controller, useForm } from "react-hook-form";
import { ContactAndLocationInfo } from "../../../components/NewContactAndLocationEdit/ContactAndLocationEdit";
import sendErrorToast from "../../../utils/sendErrorToast";
import { useGetEnums } from "../../../hooks/useGetEnums";
import { isPossiblePhoneNumber } from "libphonenumber-js/max";
import { theme } from "../../../theme";
import {
  phoneInputStyle,
  selectStyle,
} from "../../../components/ContactAndLocationEdit/Styles";
import InputMask from "react-input-mask";
import Select from "../../../components/NewSelect";
import SelectOption from "../../../components/SelectOption/SelectOption";
import Button from "../../../components/NewButton";
import getREMFromPX from "../../../utils/getREMFromPX";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../components/AddressAndAccountProvider";
import { useUpdateContactInformation } from "../../../hooks/useUpdateContactInformation";
import { SingleUser } from "../../../api/interfaces/User";
import { OnboardingBubble } from "../../../assets/icons";

type PropsType = {
  closeModal: Function;
};

const DetailsModal = ({ closeModal }: PropsType) => {
  const { userInfo, setUserInfo } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const colors = theme["new"].colors;

  const contact = userInfo?.data?.user?.Contact;

  const [isSuccess, setIsSuccess] = useState(false);

  const {
    watch,
    register,
    control,
    getValues,
    formState: { isValid, errors },
  } = useForm<ContactAndLocationInfo>({
    mode: "onChange",
    defaultValues: {
      name: contact?.first_name ?? "",
      lastName: contact?.last_name ?? "",
      phoneNumber: contact?.phone ?? "",
      address: contact?.Location?.address1 ?? "",
      address2: contact?.Location?.address2 ?? "",
      city: contact?.Location?.city ?? "",
      state: contact?.Location?.state ?? "",
      zipCode: contact?.Location?.zip ?? "",
    },
  });

  const { data } = useGetEnums({
    refetchOnWindowFocus: false,
    onError: () =>
      sendErrorToast("There was an error getting the states, please try again"),
  });

  const phoneNumber = watch("phoneNumber")?.trim();

  const {
    mutateAsync: updateContactInformation,
    isLoading: isUpdatingContactInformation,
  } = useUpdateContactInformation(userInfo?.data?.user?.id ?? "", {
    onError: () =>
      sendErrorToast(
        "There was an error saving Contact Information, please try again"
      ),
    onSuccess: () => setIsSuccess(true),
  });

  const submit = () => {
    const data = getValues();
    updateContactInformation({
      data,
      contactId: userInfo?.data?.user?.Contact?.id ?? "",
      locationId: userInfo?.data?.user?.Contact?.Location?.id ?? "",
    }).then((res) => {
      setUserInfo((info: SingleUser) => {
        return {
          ...info,
          data: {
            ...info.data,
            user: {
              ...info.data?.user,
              Contact: {
                ...info.data?.user?.Contact,
                ...res?.data?.contact,
                Location: {
                  ...info.data?.user?.Contact?.Location,
                  ...res.Location?.data?.location,
                },
              },
            },
          },
        };
      });
    });
  };

  return isSuccess ? (
    <Stack sx={{ alignItems: "center" }}>
      <OnboardingBubble />
      <Typography
        sx={{
          fontFamily: "Montserrat",
          fontSize: getREMFromPX(20),
          fontWeight: "500",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "center",
          color: "#000000",
          marginTop: "25px",
          marginBottom: "25px",
        }}
      >
        Your details have been updated
      </Typography>
      <Button
        text="OK"
        mode="default"
        disabled={!isValid}
        isLoading={isUpdatingContactInformation}
        sx={{ width: "218px" }}
        onClick={() => {
          closeModal();
        }}
        data-cy="detailsmodal-close"
      />
    </Stack>
  ) : (
    <>
      {!isSuccess && (
        <>
          <Typography
            sx={{
              position: "absolute",
              top: 30,
              left: 40,
              fontFamily: "Montserrat",
              fontSize: getREMFromPX(20),
              fontWeight: "500",
              fontStyle: "normal",
              letterSpacing: 0,
              textAlign: "left",
              color: colors.darkGreenFontColor,
            }}
          >
            Edit Details
          </Typography>
          <IconButton
            sx={{
              position: "absolute",
              top: 15,
              right: 15,
              color: colors.teal,
            }}
            onClick={() => closeModal()}
            data-cy="profile-mydetails-close"
          >
            <CloseIcon sx={{ fontWeight: "bolder" }} fontSize="large" />
          </IconButton>
        </>
      )}
      <Grid container spacing={2} maxWidth={getREMFromPX(700)}>
        <Grid item xs={12} md={6}>
          <Stack sx={{ marginTop: "1rem" }}>
            <Label htmlFor="name">First Name</Label>
            <Input
              id="name"
              {...register("name", { required: true })}
              data-testid="nameInput"
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack sx={{ marginTop: "1rem" }}>
            <Label htmlFor="lastName">Last Name</Label>
            <Input
              data-testid="lastNameInput"
              id="lastName"
              {...register("lastName", { required: true })}
            />
          </Stack>
        </Grid>
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack sx={{ width: "100%" }}>
            <Label htmlFor="phoneNumber">Phone Number</Label>
            <Controller
              name="phoneNumber"
              rules={{
                validate: (value) => isPossiblePhoneNumber(value, "US"),
              }}
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Stack
                  direction="row"
                  width="100%"
                  flexWrap="wrap"
                  alignItems="center"
                >
                  <>
                    <InputMask
                      alwaysShowMask
                      mask="(999)-999-9999"
                      placeholder="(000)-000-0000"
                      value={value}
                      onChange={onChange}
                    >
                      {/*@ts-ignore */}
                      {(inputProps) => (
                        <Input
                          {...inputProps}
                          type="tel"
                          error={!!errors.phoneNumber && !!phoneNumber.length}
                          id="phoneNumber"
                          style={phoneInputStyle}
                        />
                      )}
                    </InputMask>
                  </>
                </Stack>
              )}
            />
          </Stack>
        </Grid>
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack sx={{ width: "100%" }}>
            <Label htmlFor="address">Address 1</Label>
            <Input
              data-testid="addressInput"
              id="address"
              {...register("address", { required: true })}
            />
          </Stack>
        </Grid>
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack sx={{ width: "100%" }}>
            <Label htmlFor="address2">Address 2</Label>
            <Input
              data-testid="addressInput2"
              id="address2"
              {...register("address2")}
            />
          </Stack>
        </Grid>
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack sx={{ width: "100%" }}>
            <Label htmlFor="city">City</Label>
            <Input id="city" {...register("city", { required: true })} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack>
            <Label htmlFor="state">State</Label>
            <Controller
              name="state"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <div style={{ ...selectStyle }}>
                  <Select {...field} id="state" value={data ? field.value : ""}>
                    {data &&
                      Object.keys(data.data.system["States.US"]).map((key) => (
                        <SelectOption data-testid={key} key={key} value={key}>
                          {data.data.system["States.US"][key]}
                        </SelectOption>
                      ))}
                  </Select>
                </div>
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack>
            <Label htmlFor="zipCode">Zip</Label>
            <Input
              id="zipCode"
              {...register("zipCode", {
                required: true,
                maxLength: 5,
                pattern: { value: /^\d{5}$/, message: "Invalid zip code" },
              })}
            />
          </Stack>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "2rem",
            width: "100%",
            paddingBottom: getREMFromPX(10),
          }}
        >
          <Button
            text="Save"
            mode="default"
            disabled={!isValid}
            isLoading={isUpdatingContactInformation}
            sx={{ width: "218px" }}
            onClick={() => {
              submit();
            }}
          />
        </Box>
      </Grid>
    </>
  );
};

export default DetailsModal;
