import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { LiveDevice } from "../api/interfaces/Device";
import fetcher from "../utils/fetchWrapper";

const isDeviceOn = async (deviceId: string) => {
  const response = await fetcher(`${endpoints.isDeviceOn}/${deviceId}/power`);

  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const useIsDeviceOn = (
  deviceId: string,
  options?: UseQueryOptions<LiveDevice, Error, LiveDevice, Array<string>>
): UseQueryResult<LiveDevice, Error> =>
  useQuery([deviceId, "isDeviceOn"], () => isDeviceOn(deviceId), options);
