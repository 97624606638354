import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useGetThemePath from "../../hooks/useGetThemePath";
import useMenuAnchor from "../../hooks/useMenuAnchor";
import { FilterValueProperty, MappedPlan } from "../../routes/Plans/Plans";
import { theme } from "../../theme";
import getProperLabelValue from "../../utils/getProperLabelValue";
import getREMFromPX from "../../utils/getREMFromPX";
import { useGetConfig } from "../../hooks/useGetConfig";
import Box from "../Box";
import Button from "../Button";
import Menu from "../Menu";
import MenuItem from "../MenuItem";
import Rating from "../Rating";
import {
  buttonStyles,
  dividerStyles,
  iconStyles,
  logoStyles,
  planNameStyles,
} from "./styles";

const menuListProps = {
  "aria-labelledby": "toggle_plan_button",
};

const PlanBox = ({
  plan,
  idsInUse,
  allPlans,
  setIdsInUse,
}: {
  plan: MappedPlan;
  allPlans: MappedPlan[];
  idsInUse: string[];
  setIdsInUse: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedPlan, setSelectedPlan] = useState(plan);
  const { anchorEl, open, handleClick, handleClose } = useMenuAnchor();
  const serviceTypeFields = useMemo(
    () =>
      selectedPlan.serviceFields
        ? (JSON.parse(selectedPlan.serviceFields) as FilterValueProperty[])
        : { name: "" },
    [selectedPlan]
  );

  const { data: config } = useGetConfig();
  const showReviews = config?.data?.config?.enabled_features?.USER_REVIEWS?.enabled;

  const values = [
    ...showReviews ? [{
      label: "Rating",
      icon: "star",
      value: null,
      children: (
        <Rating
          data-testid={`plan_box_test_id_${selectedPlan.plan.ratings_sum}`}
          value={selectedPlan.plan.total_reviews ? Number(selectedPlan.plan.ratings_sum) / Number(selectedPlan.plan.total_reviews) : 0}
          fontSize={getREMFromPX(theme.spacing * 6)}
          planId={selectedPlan.plan.id}
          planName={selectedPlan.plan.name}
          readOnly
        />
      ),
    }] : [],
    ...Object.values(serviceTypeFields)
      .filter((field) => field.name && field.filter === "1")
      .map(({ name, icon }) => ({
        name,
        value: getProperLabelValue(
          name,
          selectedPlan.plan.fields[name] as string
        ),
        icon,
        children: null,
        label: name,
      })),
  ];
  const themePath = useGetThemePath();

  const availableOptions = useMemo(
    () => allPlans.filter((plan) => !idsInUse.includes(plan.id)),
    [allPlans, idsInUse]
  );

  const goToSubscribe = () => navigate(`/subscribe/${id}/${selectedPlan.id}`);

  return (
    <Box hasBoxShadow width={getREMFromPX(theme.spacing * 100)}>
      <Stack padding={getREMFromPX(theme.spacing * 6)}>
        <Stack
          direction="row"
          gap={getREMFromPX(theme.spacing * 2)}
          alignItems="center"
        >
          <Typography
            color={theme[themePath].colors.textPrimary.primary}
            fontSize={getREMFromPX(theme.spacing * 4.5)}
            fontWeight={theme.fonts.weights.mediumBold}
          >
            {selectedPlan.providerName}
          </Typography>
          <Divider flexItem orientation="vertical" />
          <Typography
            style={planNameStyles}
            color={theme[themePath].colors.textPrimary.placeholder}
          >
            {selectedPlan.planName}
          </Typography>
          {availableOptions.length > 0 && (
            <Stack
              borderRadius="50%"
              bgcolor={theme[themePath].colors.textSecondary.secondary}
              padding={getREMFromPX(theme.spacing * 3)}
            >
              <button style={buttonStyles} aria-label="toggle_plan_button">
                <FontAwesomeIcon
                  icon="caret-down"
                  style={iconStyles}
                  onClick={handleClick}
                />
              </button>
              <Menu
                MenuListProps={menuListProps}
                id="plans-menu"
                anchorEl={anchorEl}
                open={open && availableOptions.length > 0}
                onClose={handleClose}
              >
                {availableOptions.map((plan) => (
                  <MenuItem
                    key={plan.id}
                    onClick={() => {
                      setIdsInUse([
                        ...idsInUse.filter((ids) => ids !== selectedPlan.id),
                        plan.id,
                      ]);
                      setSelectedPlan(plan);
                      handleClose();
                    }}
                  >
                    {plan.planName}
                  </MenuItem>
                ))}
              </Menu>
            </Stack>
          )}
        </Stack>
        <Stack
          marginTop={getREMFromPX(theme.spacing * 10)}
          marginBottom={getREMFromPX(theme.spacing * 10)}
        >
          <img
            style={logoStyles}
            alt={selectedPlan.providerName}
            src={`${process.env.PUBLIC_URL}/assets/provider/${selectedPlan.providerId}.png`}
          />
        </Stack>
        <Stack
          justifyContent="space-between"
          alignItems="center"
          padding={getREMFromPX(theme.spacing * 4)}
          direction="row"
          bgcolor={theme.backgrounds.primarySelection}
          border="1px solid #E4E7E5"
          borderRadius="8px"
        >
          <Stack>
            <Typography
              lineHeight={1}
              color={theme[themePath].colors.textPrimary.secondary}
            >
              Total Monthly
            </Typography>
            <Typography
              marginTop={getREMFromPX(theme.spacing * 2)}
              lineHeight={1}
              color={theme[themePath].colors.textPrimary.primary}
              fontWeight={theme.fonts.weights.bolder}
              fontSize={getREMFromPX(theme.spacing * 6)}
            >
              ${selectedPlan.plan.fields.Price}
            </Typography>
          </Stack>
          <Button size="medium" text="Subscribe" onClick={goToSubscribe} />
        </Stack>
        <Stack
          spacing={getREMFromPX(theme.spacing * 4)}
          marginTop={getREMFromPX(theme.spacing * 8)}
        >
          {values.map(({ label, icon, children, value }, index) => (
            <Stack key={label}>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  gap={getREMFromPX(theme.spacing * 4)}
                >
                  <Stack
                    justifyContent="center"
                    flexDirection="row"
                    width={getREMFromPX(theme.spacing * 4)}
                  >
                    <FontAwesomeIcon icon={icon as IconProp} />
                  </Stack>
                  <Typography
                    color={theme[themePath].colors.textPrimary.secondary}
                  >
                    {label}
                  </Typography>
                </Stack>
                {children ? (
                  children
                ) : (
                  <Typography
                    color={theme[themePath].colors.textPrimary.primary}
                  >
                    {value}
                  </Typography>
                )}
              </Stack>
              {index !== values.length - 1 && <Divider style={dividerStyles} />}
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};
export default PlanBox;
