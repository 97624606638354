import { TypesOptionsFields } from "../../routes/Services/utils/intefaces/TypesOptionsFIelds";
import AppsIcon from "@mui/icons-material/Apps";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

export const typesOptions: Array<TypesOptionsFields> = [
  {
    label: "Square",
    icon: <AppsIcon />,
    active: true,
  },
  {
    label: "List",
    icon: <FormatListBulletedIcon />,
    active: false,
  },
];
