import { Box, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import getREMFromPX from "../../utils/getREMFromPX";
import { theme } from "../../theme";

interface ListSelectProps {
  label?: string;
  options: Array<{ label: string; isActive: boolean }>;
  handleChangeActiveTab: (v: number) => void;
}

const ListSelect = ({
  label,
  options,
  handleChangeActiveTab,
}: ListSelectProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOption, setSelectedOption] = useState<string>("All Services");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (option: string, index: number) => {
    setSelectedOption(option);
    handleChangeActiveTab(index);

    handleClose();
  };

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }} onClick={handleClick}>
        <Typography
          fontSize={getREMFromPX(12)}
          sx={{
            fontWeight: "600",
            fontFamily: "Montserrat",
            color: theme.new.colors.darkGreen,
          }}
        >
          {label || selectedOption}
        </Typography>
        <ArrowDropDownIcon />
      </Box>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {options.map(({ label }, idx) => (
          <MenuItem
            selected={selectedOption === label}
            onClick={() => handleOptionSelect(label, idx)}
            key={label}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default ListSelect;
