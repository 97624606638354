import * as React from "react";
import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import { styled } from "@mui/system";
import {
  getDefaultBorderAndOutlineStyles,
  InputSizeEnum,
  getInputStyles,
} from "./Styles";
import getREMFromPX from "../../utils/getREMFromPX";
import { theme } from "../../theme";
import useGetThemePath from "../../hooks/useGetThemePath";
import useViewport from "../../hooks/useViewport";

export interface InputProps extends InputBaseProps {
  ownerState?: InputBaseProps & {
    error: boolean;
    softError?: boolean;
  };
}

const StyledBigSizeInputElement = styled(InputBase)((props: InputProps) => {
  const themePath = useGetThemePath();
  return getInputStyles(themePath).concat(
    `padding: ${getREMFromPX(theme.spacing * 4)} ${getREMFromPX(
      theme.spacing * 6
    )};
`.concat(getDefaultBorderAndOutlineStyles(props, themePath))
  );
});

const StyledMediumSizeInputElement = styled(InputBase)((props: InputProps) => {
  const themePath = useGetThemePath();
  const { isMobile } = useViewport();
  const colors = theme["new"].colors;
  return getInputStyles(themePath)
    .concat(
      `padding: ${isMobile ? "13px 15px" : "18px 13px"};
      ${isMobile ? `font-size: ${getREMFromPX(12)};` : ""};
      border-radius: 15px;
      font-family: Montserrat;
      font-size: ${getREMFromPX(16)};
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.32px;
      text-align: left;
      color: ${colors.formGrey};
    `
    )
    .concat(getDefaultBorderAndOutlineStyles(props, themePath));
});

const StyledSmallSizeInputElement = styled("input")((props: InputProps) => {
  const themePath = useGetThemePath();
  const { isMobile } = useViewport();
  const colors = theme["new"].colors;
  return getInputStyles(themePath)
    .concat(
      `padding: ${isMobile ? "13px 15px" : "18px 13px"};
      ${isMobile ? `font-size: ${getREMFromPX(10)};` : getREMFromPX(16)};
      border-radius: 15px;
      font-family: Montserrat;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.32px;
      text-align: left;
      height: 24px;
      width: 140px;
      color: ${colors.formGrey};
    `
    )
    .concat(getDefaultBorderAndOutlineStyles(props, themePath));
});

const decideInputSize = (size?: keyof typeof InputSizeEnum) => {
  if (size === InputSizeEnum.medium) {
    const components = {
      Input: StyledMediumSizeInputElement,
    };
    return components;
  }
  if (size === InputSizeEnum.small) {
    const components = {
      Input: StyledSmallSizeInputElement,
    };
    return components;
  }
  const components = {
    Input: StyledBigSizeInputElement,
  };
  return components;
};

const CustomStyledInput = (
  props: InputProps & {
    size?: keyof typeof InputSizeEnum;
    softError?: boolean;
    crossOrigin?: string | undefined;
  },
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const Input = decideInputSize(props.size || InputSizeEnum.medium);
  return (
    <InputBase
      crossOrigin={props.crossOrigin ?? undefined}
      components={Input}
      {...props}
      ref={ref}
    />
  );
};

const CustomInput = React.forwardRef(CustomStyledInput);

export default CustomInput;
