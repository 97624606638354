import { Link, styled, LinkProps } from "@mui/material";
import useGetThemePath from "../../hooks/useGetThemePath";
import { getExternalLinkStyle, LinkSize } from "./Styles";

interface StyledLinkProps extends LinkProps {
  size?: keyof typeof LinkSize;
}

const StyledLinkComponent = styled(Link)<StyledLinkProps>(({ size }) => {
  const themePath = useGetThemePath();
  return getExternalLinkStyle(size || LinkSize.small, themePath);
});

const StyledExternalLink = ({ children, ...props }: StyledLinkProps) => (
  <StyledLinkComponent {...props} underline="none">
    {children}
  </StyledLinkComponent>
);
export default StyledExternalLink;
