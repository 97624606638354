import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const inputAdornmentStyle = {
  display: "flex",
  alignItems: "center",
};

export const iconStyleAdornment = {
  marginLeft: "-4rem",
};

export const iconStylePad = {
  borderRadius: "10%",
  marginLeft: getREMFromPX(theme.spacing * 3),
};
