import { Stack, Typography, Dialog, Box } from "@mui/material";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import { useGetPlanReviews } from "../../hooks/useGetPlanReviews";
import sendErrorToast from "../../utils/sendErrorToast";
import { Review } from "../../api/interfaces/Review";
import Rating from "../../components/Rating";

interface AllRatingsProps {
  show: boolean;
  onClose: Function;
  planId: string;
  planName: string;
}

const PlanReview = ({ comment, rating }: Review) => {
  const padding = getREMFromPX(theme.spacing * 4);
  return (
    <Stack pt={padding} pb={padding} flexDirection="row" alignItems="center" justifyContent="flex-start" borderTop={1} borderColor="rgba(100, 100, 100, 0.2)">
      <Stack>
        <Rating individual={true} readOnly={true} value={Number(rating)} />
      </Stack>
      <Typography pl={padding}>{comment}</Typography>
    </Stack>
  );
}

const AllRatings = ({ show, onClose, planId, planName }: AllRatingsProps) => {
  const { data } = useGetPlanReviews(
    planId as string,
    {
      onError: () =>
        sendErrorToast(
          "There was an error getting the reviews"
        ),
    }
  );

  const filtered = data?.data?.review?.filter((r: Review) => !!r.comment) ?? []

  return (
    <Dialog open={show} onClose={() => onClose()}>
      <Box
        padding={getREMFromPX(theme.spacing * 8)}
        width={getREMFromPX(theme.spacing * 150)}
        data-testid="dialogWrapper"
      >
        <Typography mb={getREMFromPX(theme.spacing * 6)}>Reviews for {planName}</Typography>
        {filtered.length ? (
          filtered.map((review: Review) => {
            return <PlanReview key={review.id} {...review} />
          })
        ) : (
          <Typography>No reviews with comments to show.</Typography>
        )}
      </Box>
    </Dialog>
  );
}

export default AllRatings;
