import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const dividerStyle = {
  height: getREMFromPX(theme.spacing * 22),
  alignSelf: "end",
};

export const iconStyle = {
  padding: getREMFromPX(theme.spacing),
};
