import { useMutation, UseMutationOptions } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { AccountQueryList } from "../api/interfaces/Account";
import fetcher from "../utils/fetchWrapper";

interface AccountCreateBody {
  account_number: string;
  name?: string;
  status: string;
  type: string;
}

const createAccount = async (body: AccountCreateBody) => {
  const response = await fetcher(`${endpoints.account}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    if (response.status === 409) {
      const account = await response.json();
      return {
        ...account,
        message: "account already exists",
      };
    }
    throw new Error();
  }

  return await response.json();
};

export const useCreateAccount = (
  options?: UseMutationOptions<AccountQueryList, Error, AccountCreateBody>
) => useMutation((body: AccountCreateBody) => createAccount(body), options);
