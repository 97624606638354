import { Grid, Box, Typography } from "@mui/material";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import CardWrapper from "../../../../components/NewCardWrapper";

const MyDevicePortsRow = ({
  portName,
  port,
}: {
  portName: string;
  port: any;
}) => {
  const portActiveColor =
    port?.data?.portinterface.live?.link_state === "up" ? "#84e88e" : "#d9d9d9";

  return (
    <>
      {port?.Subscriptions?.length === 0 ||
      port?.Subscriptions === undefined ? (
        <CardWrapper
          actions={""}
          sx={{ bgcolor: "#f0f0f0", boxShadow: "none", borderRadius: "10px" }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ marginTop: "-9px" }}
          >
            <Grid
              item
              xs={2}
              textAlign="center"
              style={{
                paddingTop: "3px",
                paddingBottom: "4px",
                paddingLeft: "0",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  backgroundColor: "#efefef",
                  borderRadius: "10px",
                  border: "1px solid #6d6d6d",
                  width: "65px",
                  margin: "0 auto",
                }}
              >
                <Typography
                  color={theme.new.colors.darkGreenFontColor}
                  fontSize={getREMFromPX(12)}
                  fontFamily="Montserrat"
                  fontWeight={500}
                  textAlign="center"
                  paddingTop="8px"
                  paddingLeft="0"
                  paddingBottom="8px"
                  width="100%"
                >
                  {portName}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardWrapper>
      ) : (
        <CardWrapper
          actions={""}
          sx={{
            borderRadius: "10px",
            boxShadow: "0px 4px 10px 0px rgba(201, 218, 216, 0.60)",
          }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ marginTop: "-14px" }}
          >
            <Grid
              item
              xs={2}
              textAlign="center"
              style={{
                paddingTop: "3px",
                paddingBottom: "4px",
                paddingLeft: "0",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  border: "1px solid #efefef",
                  width: "65px",
                  margin: "0 auto",
                }}
              >
                <Typography
                  color={theme.new.colors.darkGreenFontColor}
                  fontSize={getREMFromPX(12)}
                  fontFamily="Montserrat"
                  fontWeight={500}
                  textAlign="center"
                  paddingTop="8px"
                  paddingLeft="8px"
                  paddingBottom="8px"
                >
                  {portName}
                </Typography>
                <Box
                  sx={{
                    color: portActiveColor,
                    background: portActiveColor,
                    width: "10px",
                    height: "10px",
                    borderRadius: "10px",
                    border: "1px solid " + portActiveColor,
                    marginLeft: "4px",
                  }}
                >
                  &nbsp;
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={2}
              borderLeft="2px solid white"
              style={{
                paddingTop: "3px",
                paddingBottom: "4px",
                paddingLeft: "12px",
              }}
            >
              <Typography
                color={theme.new.colors.darkGreenFontColor}
                fontSize={getREMFromPX(10)}
                fontFamily="Montserrat"
                fontWeight={500}
                component="div"
              >
                {port?.Subscriptions?.map((Subscription: any) => (
                  <div key={Subscription.id}>
                    {Subscription?.Serviceplan?.Service?.Provider?.name || ""}
                  </div>
                ))}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              borderLeft="2px solid white"
              style={{
                paddingTop: "3px",
                paddingBottom: "4px",
                paddingLeft: "25px",
              }}
            >
              <Typography
                color={theme.new.colors.darkGreen}
                fontSize={getREMFromPX(10)}
                fontFamily="Montserrat"
                component="div"
              >
                {port?.Subscriptions?.map((Subscription: any) => (
                  <div key={Subscription.id}>
                    {Subscription?.Serviceplan?.name || ""}
                  </div>
                ))}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              borderLeft="2px solid white"
              style={{
                paddingTop: "3px",
                paddingBottom: "4px",
                paddingLeft: "30px",
              }}
            >
              <Typography
                color={theme.new.colors.darkGreen}
                fontSize={getREMFromPX(10)}
                fontFamily="Montserrat"
                fontWeight={500}
                component="div"
              >
                {port?.Subscriptions?.map((Subscription: any) => (
                  <div key={Subscription.id}>
                    {Subscription?.Serviceplan?.Service?.Servicetype.name || ""}
                  </div>
                ))}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              borderLeft="2px solid white"
              style={{
                paddingTop: "3px",
                paddingBottom: "4px",
                paddingLeft: "30px",
              }}
            >
              <Typography
                color={theme.new.colors.darkGreen}
                fontSize={getREMFromPX(10)}
                fontFamily="Montserrat"
                fontWeight={500}
              >
                {port?.data?.portinterface.live?.link_state === "up"
                  ? "Plugged In"
                  : "Not Plugged In"}
              </Typography>
            </Grid>
          </Grid>
        </CardWrapper>
      )}
    </>
  );
};

export default MyDevicePortsRow;
