import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import fetcher from "../utils/fetchWrapper";

const getConfig = async () => {
  const response = await fetcher(endpoints.getEnabledFeatures);

  if (!response.ok) {
    throw new Error();
  }

  return await response.json();
};

export const useGetConfig = (
  options?: UseQueryOptions<any, Error, any, Array<string>>
): UseQueryResult<any, Error> => useQuery(["config"], getConfig, options);
