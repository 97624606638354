import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";

export const selectableStyles = {
  padding: getREMFromPX(theme.spacing * 4),
};

export const iconStyles = {
  paddingLeft: getREMFromPX(theme.spacing * 1.5),
  paddingRight: getREMFromPX(theme.spacing * 1.5),
};

export const tableContainerStyles = {
  overflow: "auto",
  marginTop: getREMFromPX(theme.spacing * 4),
};

export const dividerStyles = {
  margin: `${getREMFromPX(theme.spacing * 6)} 0 ${getREMFromPX(
    theme.spacing * 8
  )} 0`,
};

export const linkStyles = {
  display: "block",
  marginTop: getREMFromPX(theme.spacing * 6),
};
