import React, { useState } from "react";
import CardWrapper from "../../components/NewCardWrapper";

import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import { Stack, Typography, Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { routes } from "../../App";
import { ForwardArrowIcon } from "../../assets/icons";
import { LocationIcon, RouterIcon, RotateIcon } from "../../assets/icons";
import { Device as DeviceType } from "../../api/interfaces/Device";
import useArePortsLive from "../../hooks/useArePortsLive";
import { Port } from "../../api/interfaces/Port";
import SelectDevice from "../../components/SelectDevice";
import BigDeviceBackModOne from "../../routes/HelpCenter/components/DeviceGuide/BigDeviceBackModOne";
import BigDeviceFrontModOne from "../../routes/HelpCenter/components/DeviceGuide/BigDeviceFrontModOne";

type DeviceStatusProps = {
  status: DeviceStatusType;
  isLive?: boolean;
};

type DeviceStatusType =
  | "ACTIVE"
  | "REPAIRED"
  | "INACTIVE"
  | "SUSPENDED"
  | "LOCKED"
  | "PAUSED"
  | "REPLACED"
  | "RMA"
  | "STANDBY"
  | "PENDING"
  | "PROVISION"
  | "PROVISIONING"
  | "PENDING_ACTIVATION";

const DeviceStatus = ({ status, isLive = false }: DeviceStatusProps) => {
  const colors = theme["new"].colors;

  const navigate = useNavigate();
  let properties;

  switch (status) {
    case "INACTIVE":
    case "SUSPENDED":
    case "LOCKED":
    case "PAUSED":
    case "REPLACED":
    case "RMA":
      properties = {
        backgroundColor: colors.minimalGrey,
        textColor: colors.formGrey,
        label: "Device is Disabled",
      };
      break;
    case "STANDBY":
    case "PENDING_ACTIVATION":
    case "PROVISIONING":
      properties = {
        backgroundColor: colors.minimalGrey,
        textColor: colors.formGrey,
        label: "Pending Status",
      };
      break;
    case "ACTIVE":
    case "REPAIRED":
      if (isLive) {
        properties = {
          backgroundColor: colors.brightGreen,
          textColor: colors.darkGreenFontColor,
          label: "Device Connected",
        };
      } else {
        properties = {
          backgroundColor: colors.errorRed,
          textColor: colors.lightGrey,
          label: "Device Needs Attention",
        };
      }
      break;
    default:
      properties = {
        backgroundColor: colors.errorRed,
        textColor: colors.lightGrey,
        label: "Device Needs Attention",
      };
  }

  return properties.label === "Device Needs Attention" ? (
    <Stack
      style={{
        flexDirection: "row",
        maxWidth: getREMFromPX(468),
        width: "100%",
        backgroundColor: colors.white,
        padding: "16px 24px",
        borderRadius: getREMFromPX(30),
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Montserrat",
          fontSize: getREMFromPX(14),
          fontWeight: "600",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "left",
          color: colors.darkGreenFontColor,
        }}
      >
        We’re having trouble connecting to your device. Please click Help to
        learn more.
      </Typography>
      <Stack
        sx={{
          backgroundColor: colors.errorRed,
          maxWidth: getREMFromPX(120),
          width: "100%",
          height: getREMFromPX(38),
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 19.2,
          marginLeft: getREMFromPX(8),
          cursor: "pointer",
        }}
        onClick={() => navigate(routes.helpDevice.path)}
      >
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: getREMFromPX(14),
            fontWeight: "600",
            fontStyle: "normal",
            letterSpacing: 0,
            color: colors.pink,
          }}
        >
          Help
        </Typography>
      </Stack>
    </Stack>
  ) : (
    <Box
      style={{
        maxWidth: getREMFromPX(294),
        height: getREMFromPX(48),
        borderRadius: 30,
        backgroundColor: properties.backgroundColor,
        display: "flex",
        flex: 1,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontSize: getREMFromPX(14),
          fontWeight: "bold",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "center",
          color: properties.textColor,
        }}
      >
        {properties.label}
      </Typography>
    </Box>
  );
};

const MyDevice = ({ device }: { device: DeviceType }) => {
  const colors = theme["new"].colors;

  const wanPort = (device.Portinterfaces as Port[]).filter((port) => {
    return port.name === "WAN";
  });
  const { data } = useArePortsLive(wanPort);
  const isLive =
    data[0]?.data?.portinterface?.live?.link_state === "up" || false;

  const [showFront, setShowFront] = useState(true);

  const navigate = useNavigate();

  const arrowAction = (
    <ForwardArrowIcon
      onClick={() => navigate(routes.helpDevice.path)}
      style={{ cursor: "pointer" }}
      data-cy="dashboard-my-device"
    />
  );
  const DeviceImage = showFront ? BigDeviceFrontModOne : BigDeviceBackModOne;

  const ports = (device.Portinterfaces as Port[]).filter(
    (portinterface) =>
      portinterface.display === true &&
      (portinterface.type === "LAN" || portinterface.type === "WAN")
  );

  const { data: portCollection } = useArePortsLive(ports);

  if (!device) {
    return <>Loading...</>;
  }

  return (
    <Grid item xl={12}>
      <CardWrapper
        sx={{ backgroundColor: isLive ? colors.white : colors.pink }}
        title="My Device"
        status="Paid"
        actions={arrowAction}
      >
        <Stack
          direction="row"
          spacing={getREMFromPX(theme.spacing * 2)}
          mt={getREMFromPX(20)}
          width="100%"
        >
          <LocationIcon />
          <SelectDevice
            sx={{ minWidth: "30%", height: "36px", borderRadius: "10px" }}
          />
        </Stack>
        <div
          style={{
            width: "100%",
            marginTop: getREMFromPX(25),
            textAlign: "center",
          }}
        >
          <DeviceImage ports={portCollection} />
        </div>
        <Stack
          onClick={() => setShowFront(!showFront)}
          sx={{
            marginTop: getREMFromPX(-30),
            width: "100%",
            maxWidth: getREMFromPX(550),
            paddingRight: getREMFromPX(30),
            alignSelf: "center",
            justifyContent: "flex-end",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: getREMFromPX(12),
              fontWeight: "500",
              fontStyle: "normal",
              letterSpacing: 0,
              textAlign: "left",
              color: colors.teal,
              marginRight: getREMFromPX(3),
            }}
          >
            {showFront ? "Back" : "Front"}
          </Typography>
          <RotateIcon />
        </Stack>
        <Stack
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginBottom: getREMFromPX(30),
          }}
        >
          <Grid item xs={6} sm={5} md={4}>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: getREMFromPX(20),
                fontWeight: "600",
                fontStyle: "normal",
                letterSpacing: 0,
                textAlign: "left",
                color: colors.darkGreenFontColor,
                marginTop: getREMFromPX(15),
                marginBottom: getREMFromPX(29),
              }}
            >
              Virtual Broadband Gateway
            </Typography>
            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <RouterIcon fill={colors.teal} />
              <Typography
                sx={{
                  marginLeft: getREMFromPX(15),
                  fontFamily: "Montserrat",
                  fontSize: getREMFromPX(16),
                  fontWeight: "bold",
                  fontStyle: "normal",
                  letterSpacing: 0,
                  textAlign: "left",
                  color: colors.darkGreenFontColor,
                }}
              >
                {device?.type} {device?.hardware_version}
              </Typography>
            </Stack>
          </Grid>
          <DeviceStatus
            status={device.status as DeviceStatusType}
            isLive={isLive}
          />
        </Stack>
      </CardWrapper>
    </Grid>
  );
};
export default MyDevice;
