import React, { useState, useContext, useMemo } from "react";
import { Stack } from "@mui/material";
import { useCreditCardValidator } from "react-creditcard-validator";
import getREMFromPX from "../../utils/getREMFromPX";
import { theme } from "../../theme";
import Label from "../Label";
import { useForm } from "react-hook-form";
import Input from "../Input";
import { endpoints } from "../../api/constants/endpoints";
import useGetThemePath from "../../hooks/useGetThemePath";
import { useUpdateAccount } from "../../hooks/useUpdateAccount";
import { useUpdateUser } from "../../hooks/useUpdateUser";
import sendErrorToast from "../../utils/sendErrorToast";
import sendSuccessToast from "../../utils/sendSuccessToast";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import fetcher from "../../utils/fetchWrapper";

export interface CreditCardInfo {
  stepName?: string;
  cardHolderName?: string;
  cardNumber?: string;
  expirationDate?: string;
  cvc?: string;
  zipCode?: string;
}

interface Location {
  billingStreet: string;
  billingCity: string;
  billingState: string;
  billingZip: string;
}

interface WarrantyInfo {
  paymentMethod: string;
  maintenanceSel: boolean;
  insuranceSel: boolean;
}

interface CreditCardProps {
  children?: React.ReactElement;
  onSubmit: Function;
  onSuccess?: Function;
  setIsValid: (isValid: boolean) => void;
  setIsLoading?: (isLoading: boolean) => void;
  previousValues: CreditCardInfo;
  disableForm?: boolean;
  accountId?: string;
  location?: Location;
  warranty?: WarrantyInfo;
}

const CreditCard = ({
  previousValues,
  children,
  onSubmit,
  setIsValid,
  onSuccess,
  disableForm,
  location,
  warranty,
  ...props
}: CreditCardProps) => {
  const themePath = useGetThemePath();
  const { userInfo } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const userId = userInfo?.data?.user?.id ?? "";
  const accountId = useMemo(
    () => props.accountId ?? userInfo?.data?.user?.Accounts?.[0]?.id ?? "",
    [userInfo, props.accountId]
  );

  const [cardNumberChanged, setCardNumberChanged] = useState(false);
  const [expirationDateChanged, setExpirationDateChanged] = useState(false);
  const [cvcChanged, setCvcChanged] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [cvc, setCvc] = useState("");

  const { mutateAsync: updateUser } = useUpdateUser(userId, {
    onError: () => sendErrorToast("There was an error updating the user."),
  });

  const { mutateAsync: updateAccount } = useUpdateAccount(accountId, {
    onError: () => sendErrorToast("There was an error updating the user."),
  });

  const {
    watch,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<CreditCardInfo>({
    mode: "onChange",
    defaultValues: {
      ...previousValues,
    },
  });

  const {
    // getCardNumberProps,
    getExpiryDateProps,
    // getCVCProps,
    meta: { erroredInputs },
  } = useCreditCardValidator();

  const cardHolderName = watch("cardHolderName");
  const zipCode = watch("zipCode");

  const onSubmitHandler = async () => {
    props.setIsLoading?.(true);
    if (!accountId) {
      sendErrorToast("No account ID found");
      return;
    }

    let arr = [];
    if (warranty?.maintenanceSel) arr.push("utility");
    if (warranty?.insuranceSel) arr.push("vbg_insurance");

    // Separate expiration date to month and year
    const splitExp = getExpiryDateProps()?.ref?.current?.value.split("/") ?? [
      "",
      "",
    ];
    if (!splitExp[0]) {
      return;
    }
    const body = {
      owner_name: cardHolderName,
      number: cardNumber.replaceAll(/\s/g, ""),
      exp: getExpiryDateProps()?.ref?.current?.value,
      exp_month: parseInt(splitExp[0]),
      exp_year: parseInt(splitExp[1]),
      cvv: cvc,
      cvc: cvc,
      zip: zipCode,
      items: arr,
      is_default_payment_method: true,
    };

    const res = await fetcher(
      `${endpoints.account}/${accountId}/billing/creditcard`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    const paymentResponse = await res.json();

    // subscriptionId means the payment was processed
    if (paymentResponse?.data?.billing?.subscriptionId) {
      sendSuccessToast("Payment was processed successfully");

      // User and Account need to be set active because payment was accepted
      await updateUser({
        status: "ACTIVE",
      });
      await updateAccount({
        status: "ACTIVE",
      });
      onSuccess?.();
      onSubmit({});
    } else {
      sendErrorToast(paymentResponse?.message);
    }

    props.setIsLoading?.(false);
  };

  const disable = !cardNumberChanged || !expirationDateChanged || !cvcChanged;

  // This will notify parent component if this form is valid or not.
  setIsValid(
    isValid &&
      !disable &&
      Object.values(erroredInputs).every((e) => e === undefined)
  );

  // Styling for the credit card input fields
  function getInputStyling(error: string | undefined) {
    return {
      "::placeholder": {
        color: theme[themePath].colors.textPrimary.placeholder,
        opacity: 1,
      },
      ":disabled": {
        border: `1px solid ${theme[themePath].colors.lightBorder}`,
      },
      ":disabled::placeholder": {
        opacity: 1,
        color: theme[themePath].colors.textPrimary.disabled,
      },
      width: "100%",
      fontWeight: theme.fonts.weights.normal,
      fontSize: getREMFromPX(theme.spacing * 4),
      fontFamily: theme.fontFamily,
      lineHeight: getREMFromPX(theme.spacing * 6),
      color: theme[themePath].colors.textPrimary.primary,
      background: theme[themePath].colors.white,
      borderRadius: "8px",
      padding: `${getREMFromPX(theme.spacing * 3)} ${getREMFromPX(
        theme.spacing * 4
      )}`,
      border: error
        ? `1px solid ${theme[themePath].colors.lightWarningStatus}`
        : `1px solid ${theme[themePath].colors.lightBorder}`,
      "&:hover": {
        outline: error
          ? "none"
          : `1px solid ${theme[themePath].colors.primary[500]}`,
      },
      "&:focus": {
        outline: error
          ? "none"
          : `1px solid ${theme[themePath].colors.primary[500]}`,
      },
    };
  }

  // function to validate credit card numbers using the Luhn algorithm
  function validateCreditCard(event: any) {
    let cardNumber = event.target.value;
    cardNumber = cardNumber && cardNumber.trim();
    setCardNumber(cardNumber);
    setCardNumberChanged(true);
    setCvc("");
    erroredInputs.cvc = "Invalid cvc";
    const tempCardNumber = cardNumber.replaceAll(/\s/g, "");

    if (!isNaN(+tempCardNumber)) {
      erroredInputs.cardNumber = undefined;
    } else {
      erroredInputs.cardNumber = "Invalid Card Number";
    }
  }

  function validateCVC(event: any) {
    let cvcValue = event.target.value;
    cvcValue = cvcValue && cvcValue.trim();
    setCvc(cvcValue);
    setCvcChanged(true);

    let isValid = false;
    if (cardNumber && cvcValue && !isNaN(+cvcValue)) {
      if (
        (cardNumber.indexOf("34") === 0 || cardNumber.indexOf("37") === 0) &&
        cvcValue.length === 4
      ) {
        isValid = true;
      } else if (
        !(cardNumber.indexOf("34") === 0 || cardNumber.indexOf("37") === 0) &&
        cvcValue.length === 3
      ) {
        isValid = true;
      }
    }
    if (isValid) {
      erroredInputs.cvc = undefined;
    } else {
      erroredInputs.cvc = "Invalid cvc";
    }
  }

  return (
    <>
      <Stack>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <Stack
            spacing={getREMFromPX(theme.spacing * 3)}
            mt={getREMFromPX(theme.spacing * 8)}
          >
            <Label htmlFor="cardHolderName">Card Holder Name</Label>
            <Input
              data-testid="cardHolderName"
              placeholder="John Doe"
              {...register("cardHolderName", { required: true })}
            />
            <Label htmlFor="cardNumber">Card Number</Label>
            <input
              style={getInputStyling(erroredInputs.cardNumber)}
              // value={cc_format(cardNumber)}
              placeholder="0000 0000 0000 0000"
              onChange={validateCreditCard}
            />
            <Stack direction="row" spacing={getREMFromPX(theme.spacing * 3)}>
              <Stack spacing={getREMFromPX(theme.spacing * 3)}>
                <Label htmlFor="expiryDate">Expiration</Label>
                <input
                  style={getInputStyling(erroredInputs.expiryDate)}
                  {...getExpiryDateProps({
                    onChange: () => setExpirationDateChanged(true),
                  })}
                />
              </Stack>
              <Stack spacing={getREMFromPX(theme.spacing * 3)}>
                <Label htmlFor="cvc">CVC</Label>
                <input
                  style={getInputStyling(erroredInputs.cvc)}
                  placeholder="000"
                  onChange={validateCVC}
                  value={cvc}
                />
              </Stack>
              <Stack spacing={getREMFromPX(theme.spacing * 3)}>
                <Label htmlFor="zipCode">Zip Code</Label>
                <Input
                  data-testid="zipCode"
                  {...register("zipCode", {
                    required: true,
                    maxLength: 5,
                    pattern: { value: /^\d{5}$/, message: "Invalid zip code" },
                  })}
                />
              </Stack>
            </Stack>
          </Stack>
          {children}
        </form>
      </Stack>
    </>
  );
};

export default CreditCard;
