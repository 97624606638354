import { Typography, TypographyProps } from "@mui/material";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

const ErrorMessage = ({ children, ...props }: TypographyProps) => {
  const themePath = useGetThemePath();
  return (
    <Typography
      fontSize={getREMFromPX(theme.spacing * 4)}
      color={theme[themePath].colors.lightWarningStatus}
      fontWeight={theme.fonts.weights.normal}
      {...props}
    >
      {children}
    </Typography>
  );
};

export default ErrorMessage;
