import { styled } from "@mui/material";
import React from "react";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import { theme } from "../../../../theme";
import {
  expandableTableCellStyles,
  getExpandedRowStyles,
  getTableRowStyles,
} from "./styles";

const StyledTableRow = styled("tr")`
  &:hover {
    background: ${theme.backgrounds.primarySelection} !important;
  }
`;

const TableRow = ({
  selected,
  children,
  expandedComponent,
  onClick,
  headersLength,
  isExpanded,
  ...props
}: {
  isExpanded: boolean;
  selected: boolean;
  headersLength: number;
  children: React.ReactElement;
  onClick?: () => void;
  expandedComponent?: React.ReactElement &
    React.HTMLAttributes<HTMLTableRowElement>;
}) => {
  const themePath = useGetThemePath();

  return (
    <>
      <StyledTableRow
        style={getTableRowStyles(
          isExpanded,
          selected,
          themePath,
          !!expandedComponent
        )}
        onClick={onClick}
        {...props}
      >
        {children}
      </StyledTableRow>
      {isExpanded && (
        <tr style={getExpandedRowStyles(themePath)}>
          <td
            style={expandableTableCellStyles}
            colSpan={isExpanded ? headersLength + 1 : headersLength}
          >
            {expandedComponent}
          </td>
        </tr>
      )}
    </>
  );
};
export default TableRow;
