import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Stack,
  Typography,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import NewButton from "../../../../components/NewButton";
import Input from "../../../../components/Input";
import Label from "../../../../components/Label";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { Step1Type, Step2Type, Step3Type } from "../../ForgotPassword";
import { useResetPassword } from "../../../../hooks/useResetPassword";
import sendErrorToast from "../../../../utils/sendErrorToast";
import isValidPassword from "../../../../utils/isValidPassword";
import useViewport from "../../../../hooks/useViewport";
import EntryPointLink from "../../../../components/NewEntryPointLink/EntryPointLink";
import PasswordValidation from "../../../../components/Validation/Password";
import ConfirmPasswordValidation from "../../../../components/Validation/ConfirmPassword";
import {
  iconStyle,
  inputAdornmentStyle,
  iconStyleAdornment,
  iconStylePad,
} from "./Styles";

interface Step3Props {
  changeStep: () => void;
  goBackOneStep: () => void;
  previousValues: {
    step1: Step1Type;
    step2: Step2Type;
    step3: Step3Type;
  };
  error?: string;
  setError?: (args: { message: string; step: number }) => void;
}

const Step3 = ({
  changeStep,
  goBackOneStep,
  previousValues,
  setError,
}: Step3Props) => {
  const { isMobile } = useViewport(600);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const {
    watch,
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<Step3Type>({
    defaultValues: {
      newPassword: previousValues.step3.newPassword,
      confirmPassword: previousValues.step3.confirmPassword,
    },
    mode: "onChange",
  });

  const { mutate: resetPassword, isLoading: isResetting } = useResetPassword({
    onSuccess: (data) => {
      if (data !== "invalid info") {
        return changeStep();
      }
      setError?.({
        message:
          "Answer to security question is incorrect. Answer is case sensitive.",
        step: 2,
      });
      goBackOneStep();
    },
    onError: () => {
      sendErrorToast(
        "There was an error resetting the password, please try again"
      );
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const newPassword = watch("newPassword").trim();
  const confirmPassword = watch("confirmPassword").trim();

  const passwordsMatch = !confirmPassword || confirmPassword === newPassword;

  const disableSubmitButton = !isValid || isResetting || !passwordsMatch;

  const onSubmit = () => {
    const body = {
      username: previousValues.step1.userName,
      password: newPassword,
      security_question1: previousValues.step2.questions[0].id,
      security_answer1: previousValues.step2.questions[0].answer,
      security_question2: previousValues.step2.questions[1].id,
      security_answer2: previousValues.step2.questions[1].answer,
    };
    resetPassword(body);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        sx={{
          flex: 1,
          width: "100%",
          maxWidth: 460,
          padding: "0 30px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            textAlign: "left",
            width: "100%",
            fontFamily: "Montserrat",
            fontSize: isMobile ? getREMFromPX(14) : getREMFromPX(20),
            fontWeight: 600,
            marginBottom: isMobile ? "8px" : "16px",
            color: theme.new.colors.textPrimary.primary,
          }}
        >
          Reset Password
        </Typography>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: isMobile ? getREMFromPX(10) : getREMFromPX(16),
            fontWeight: 500,
            lineHeight: isMobile ? 1.5 : 1.56,
            marginBottom: isMobile ? "24px" : "36px",
            textAlign: "left",
          }}
        >
          Please enter a new password for the account{" "}
          <span style={{ fontWeight: "900" }}>
            {previousValues.step1.userName}
          </span>{" "}
          below.
        </Typography>
        <Stack
          sx={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack
            width="100%"
            spacing={getREMFromPX(theme.spacing * 2)}
            direction="column"
          >
            <Label
              htmlFor="newPassword"
              aria-required="true"
              id="newPasswordLabel"
            >
              New Password
            </Label>
            <Input
              id="newPassword"
              data-testid="newPassword"
              type={showPassword ? "text" : "password"}
              autoComplete="new-password"
              placeholder="Enter your new password"
              error={
                (!!errors.newPassword && !!newPassword.length) ||
                !passwordsMatch
              }
              softError={
                !!errors.newPassword || !newPassword.length || !passwordsMatch
              }
              aria-describedby="passwordRequirements"
              aria-labelledby="newPasswordLabel"
              style={inputAdornmentStyle}
              endAdornment={
                <InputAdornment position="end" style={iconStyleAdornment}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    style={iconStylePad}
                    data-cy="editpassword-confirm-togglepasswordview"
                  >
                    {showPassword ? (
                      <FontAwesomeIcon icon="eye-slash" style={iconStyle} />
                    ) : (
                      <FontAwesomeIcon icon="eye" style={iconStyle} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              {...register("newPassword", {
                required: true,
                validate: (value) =>
                  isValidPassword(value) || "Invalid password",
              })}
              aria-required="true"
            />
          </Stack>
          <PasswordValidation password={newPassword} />
          <Stack
            width="100%"
            spacing={getREMFromPX(theme.spacing * 2)}
            direction="column"
            mt={getREMFromPX(theme.spacing * 4)}
          >
            <Label
              htmlFor="confirmPassword"
              id="confirmPasswordLabel"
              aria-required="true"
            >
              Confirm Password
            </Label>
            <Input
              id="confirmPassword"
              data-testid="confirmPassword"
              type={showPassword ? "text" : "password"}
              autoComplete="new-password"
              placeholder="Confirm your new password"
              aria-describedby="confirmPasswordRequirements"
              aria-labelledby="confirmPasswordLabel"
              error={
                (!!errors.confirmPassword && !!confirmPassword.length) ||
                !passwordsMatch
              }
              softError={
                !!errors.confirmPassword ||
                !confirmPassword.length ||
                !passwordsMatch
              }
              {...register("confirmPassword", {
                required: true,
                validate: (value) =>
                  value === newPassword || "Passwords do not match",
              })}
              aria-required="true"
            />
            <ConfirmPasswordValidation
              confirmPassword={confirmPassword}
              passwordsMatch={passwordsMatch}
            />
          </Stack>
        </Stack>
        <Box sx={{ width: "100%", marginTop: isMobile ? "40px" : "56px" }}>
          <>
            <NewButton
              text="Confirm"
              mode="default"
              type="submit"
              disabled={disableSubmitButton}
              sx={{
                width: "100%",
                marginBottom: `${isMobile ? "16px" : "30px"}`,
                borderRadius: `${isMobile ? "9.3px" : "15px"}`,
              }}
            />
            <NewButton
              text="Cancel"
              mode="secondary"
              onClick={() => navigate("/")}
              sx={{
                width: "100%",
                marginBottom: "20px",
                borderRadius: `${isMobile ? "9.3px" : "15px"}`,
              }}
            />
          </>
        </Box>
        <div style={{ marginTop: isMobile ? "15px" : "30px" }}>
          <EntryPointLink />
        </div>
      </Stack>
    </form>
  );
};
export default Step3;
