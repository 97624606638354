import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const getSelectStyles = (themePath = "default") => ({
  "&.MuiInputBase-root": {
    fontFamily: theme.fontFamily,
    fontWeight: theme.fonts.weights.normal,
    fontSize: getREMFromPX(theme.spacing * 4),
    color: theme[themePath].colors.textPrimary.primary,
    height: getREMFromPX(theme.spacing * 12.5),
    borderRadius: "8px",
  },
  "&:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme[themePath].colors.primary[500]}`,
    },
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${theme[themePath].colors.primary[500]}`,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${theme[themePath].colors.lightBorder}`,
  },
  "& .MuiSvgIcon-root": {
    color: theme[themePath].colors.textPrimary.primary,
  },
});

export const getPlaceholderStyle = (themePath = "default") => ({
  fontFamily: theme.fontFamily,
  fontWeight: theme.fonts.weights.normal,
  fontSize: getREMFromPX(theme.spacing * 4),
  color: theme[themePath].colors.textPrimary.primary,
});
