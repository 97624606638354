import { Box, Typography } from "@mui/material";
import Button from "../NewButton";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import useViewport from "../../hooks/useViewport";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const ConfirmSwitch = ({
  title,
  subtitle,
  onSwitch,
  onCancel,
}: {
  title: string;
  subtitle?: string;
  onSwitch?: () => void;
  onCancel?: () => void;
}) => {
  const colors = theme["new"].colors;
  const { isMobile } = useViewport();

  return (
    <>
      <Typography
        sx={{
          fontFamily: "Montserrat",
          fontSize: getREMFromPX(22),
          fontWeight: 600,
          color: colors.darkGreen,
          textAlign: "center",
          maxWidth: "493px",
          margin: "180px auto 17px auto",
          lineHeight: "46px",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontFamily: "Montserrat",
          fontSize: getREMFromPX(16),
          fontWeight: 500,
          color: colors.darkGreen,
          textAlign: "center",
          marginBottom: "207px",
        }}
      >
        {subtitle}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "52px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <KeyboardArrowLeftIcon
            sx={{
              color: colors.green,
            }}
          />
          <Typography
            sx={{
              cursor: "pointer",
              fontFamily: "Montserrat",
              fontSize: getREMFromPX(isMobile ? 10 : 20),
              fontWeight: 600,
              color: colors.green,
              textDecoration: "underline",
            }}
            onClick={onCancel}
          >
            Back
          </Typography>
        </Box>
        <Button
          text="Confirm"
          mode="default"
          data-testid="submit_button"
          sx={{ padding: "16px 67px" }}
          onClick={onSwitch}
        />
      </Box>
    </>
  );
};

export default ConfirmSwitch;
