import { Box, Typography } from "@mui/material";
import getREMFromPX from "../../../../../utils/getREMFromPX";
import { OverviewDataModel } from "../../../../../components/AllServices/AllServices";
import { useEffect } from "react";
import Loader from "../../../../../components/Loader";

const LoaderContent = ({
  modalOverviewData,
}: {
  modalOverviewData: OverviewDataModel;
}) => {
  useEffect(() => {
    const redirectToNewTabWithPost = (url: string, data: any) => {
      // Create a form element
      const form = document.createElement("form");
      form.method = "POST";
      form.action = url;
      form.target = "_blank"; // Open in a new tab

      // Append the data as hidden input fields
      Object.keys(data).forEach((key) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = data[key];
        form.appendChild(input);
      });

      // Append the form to the body and submit it
      document.body.appendChild(form);
      form.submit();

      // Remove the form from the document after submission
      document.body.removeChild(form);
    };

    const redirectToNewTabAfterDelay = () => {
      setTimeout(() => {
        if (modalOverviewData.subscription_redirect_url) {
          // Call the function to open a new tab with POST request
          redirectToNewTabWithPost(
            modalOverviewData.subscription_redirect_url,
            modalOverviewData
          );
        }
      }, 2000);
    };

    redirectToNewTabAfterDelay();
  }, [modalOverviewData]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        textAlign="left"
        fontSize={getREMFromPX(32)}
        fontFamily="Montserrat"
        color="#253331"
        fontWeight="bold"
        pt={"95px"}
      >
        You are now subscribed to
      </Typography>
      <img
        src={`${process.env.PUBLIC_URL}/assets/provider/${modalOverviewData.provider_id}.png`}
        alt=""
      />
      <Typography
        textAlign="left"
        fontSize={getREMFromPX(20)}
        fontFamily="Montserrat"
        color="#253331"
        fontWeight="600"
        pt={"25px"}
      >
        {modalOverviewData.fields.Name}
      </Typography>
      <Loader />
      <Typography
        textAlign="left"
        fontSize={getREMFromPX(20)}
        fontFamily="Montserrat"
        color="#253331"
        fontWeight="500"
        mt={"39px"}
      >
        You are now being redirected to{" "}
        {modalOverviewData.providerName || "the provider"}'s website
      </Typography>
    </Box>
  );
};

export default LoaderContent;
