import { useMutation, UseMutationOptions } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { User } from "../api/interfaces/User";
import { ResetPasswordBody } from "../api/interfaces/User/resetPasswordBody";
import fetcher from "../utils/fetchWrapper";

const resetPassword = async (body: ResetPasswordBody) => {
  const url = body.username 
    ? endpoints.resetPassword 
    : `${endpoints.updateUser}/${body.id}/ResetPassword`;
  
  const response = await fetcher(url, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    if (response.status === 409) {
      return "invalid info";
    }
    throw new Error();
  }
  return await response.json();
};

export const useResetPassword = (
  options?: UseMutationOptions<User | string, Error, ResetPasswordBody>
) => useMutation((body: ResetPasswordBody) => resetPassword(body), options);
