const VenoenDeviceFront = ({ ports }: { ports: any[] | null | undefined }) => {
  return (
    <svg
      width="100%"
      height="217"
      viewBox="0 0 518 217"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_5810_3631)">
        <path
          d="M55.4715 139.269L235.057 180.691C243.687 182.682 252.645 182.8 261.325 181.038L441.669 144.429C445.397 143.672 448.077 140.394 448.077 136.589L448.077 54.5001L444.221 57.8128L259.32 89.6071C251.937 90.8768 244.384 90.7976 237.029 89.3735L52.2479 53.599L49.2695 50L49.2695 131.474C49.2695 135.2 51.8413 138.432 55.4715 139.269Z"
          fill="url(#paint0_linear_5810_3631)"
        />
      </g>
      <path
        d="M194 113V138.507L217.531 143.517V117.761L194 113Z"
        fill="#B0B0B0"
      />
      <path
        d="M213.747 139.489V120.509L197.253 117.111V136.012L213.747 139.489Z"
        fill="#414141"
        stroke="#D9D9D9"
        strokeWidth="0.506107"
      />
      <ellipse
        cx="70.4114"
        cy="96.0636"
        rx="6.0999"
        ry="8.1332"
        transform="rotate(-9.93291 70.4114 96.0636)"
        fill="#D9D9D9"
      />
      <ellipse
        cx="70.4114"
        cy="96.0636"
        rx="6.0999"
        ry="8.1332"
        transform="rotate(-9.93291 70.4114 96.0636)"
        fill="#00A3FF"
      />
      <path
        d="M85.4871 102.42L85.4871 96.2081L95.0867 98.2386L104.756 100.284L104.756 106.478L85.4871 102.42Z"
        fill="#D1D1D1"
        stroke="#E7E6E6"
        strokeWidth="0.337405"
      />
      <path
        d="M147.287 109.416L143.746 109.91L144.735 117.327L148.69 116.338C150.174 114.36 149.759 109.91 147.287 109.416Z"
        fill="#717474"
      />
      <ellipse
        cx="144.664"
        cy="113.597"
        rx="2.38574"
        ry="3.89152"
        transform="rotate(-4.19759 144.664 113.597)"
        fill="#D9D9D9"
      />
      <ellipse
        cx="144.609"
        cy="113.62"
        rx="1.87887"
        ry="3.36644"
        transform="rotate(-4.19759 144.609 113.62)"
        fill="#A4A0A0"
      />
      <path
        d="M171 125.193L175.762 124.335C176.806 124.147 177.521 123.177 177.393 122.125L176.173 112.128C176.07 111.279 175.437 110.589 174.6 110.413L158.081 106.934C157.584 106.829 157.072 106.82 156.572 106.907L152 107.7L150.5 109L171 125.193Z"
        fill="#717473"
      />
      <path
        d="M151.159 120.641L169.773 124.725C171.436 125.089 172.968 123.723 172.796 122.03L171.969 113.883C171.859 112.804 171.066 111.919 170.006 111.691L169.901 112.18L170.006 111.691L153.303 108.11C151.886 107.806 150.508 108.767 150.304 110.203L149.219 117.847C149.035 119.142 149.881 120.36 151.159 120.641Z"
        fill="#6F86C2"
        stroke="#D9D9D9"
      />
      <path
        d="M181.64 116.523L178.1 117.018L179.088 124.434L183.044 123.446C184.527 121.468 184.113 117.018 181.64 116.523Z"
        fill="#717474"
      />
      <ellipse
        cx="179.018"
        cy="120.704"
        rx="2.38574"
        ry="3.89152"
        transform="rotate(-4.19759 179.018 120.704)"
        fill="#D9D9D9"
      />
      <ellipse
        cx="178.963"
        cy="120.728"
        rx="1.87887"
        ry="3.36644"
        transform="rotate(-4.19759 178.963 120.728)"
        fill="#A4A0A0"
      />
      <path
        d="M113.471 108.374L113.471 102.234L132.652 106.203L132.652 112.405L113.471 108.374Z"
        fill="#4485D1"
        stroke="#C4C4C4"
        strokeWidth="0.337405"
      />
      <path
        d="M444.671 57.4672L259.058 89.1057C251.71 90.3583 244.195 90.2753 236.876 88.8608L52.1419 53.1586C48.8318 52.5189 48.9308 47.7485 52.2645 47.2465L240.346 18.9286C244.432 18.3134 248.589 18.3515 252.663 19.0413L444.667 51.552C447.998 52.1159 448 56.8996 444.671 57.4672Z"
        fill="#C6C6C6"
        stroke="#C4C4C4"
        strokeWidth="2"
      />
      <path
        d="M444.671 56.4672L259.058 88.1057C251.71 89.3583 244.195 89.2753 236.876 87.8608L52.1419 52.1586C48.8318 51.5189 48.9308 46.7485 52.2645 46.2465L240.346 17.9286C244.432 17.3134 248.589 17.3515 252.663 18.0413L444.667 50.552C447.998 51.1159 448 55.8996 444.671 56.4672Z"
        fill="#777777"
      />
      <defs>
        <filter
          id="filter0_d_5810_3631"
          x="40.8395"
          y="50"
          width="432.548"
          height="166.017"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="8.44" dy="16.87" />
          <feGaussianBlur stdDeviation="8.435" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.494118 0 0 0 0 0.509804 0 0 0 0 0.505882 0 0 0 0.39 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5810_3631"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5810_3631"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_5810_3631"
          x1="453.577"
          y1="119.5"
          x2="60.5773"
          y2="118"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6E6E70" />
          <stop offset="0.461144" stopColor="#5E5E61" />
          <stop offset="0.520833" stopColor="#525254" />
          <stop offset="0.614763" stopColor="#5E5E5E" />
          <stop offset="1" stopColor="#545456" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default VenoenDeviceFront;
