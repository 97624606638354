import React from "react";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const iconStyles = {
  position: "absolute",
  cursor: "pointer",
} as React.CSSProperties;

export const logoStyles = {
  height: "100%",
  objectFit: "cover",
} as React.CSSProperties;

export const dividerStyles = {
  marginTop: getREMFromPX(theme.spacing * 4),
};

export const planNameStyles = {
  wordBreak: "break-all",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
} as React.CSSProperties;

export const buttonStyles = {
  appearance: "none",
  border: 0,
  padding: 0,
  background: "none",
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
} as React.CSSProperties;
