import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const getCheckboxStyles = (themePath = "default") => ({
  padding: 0,
  "& .MuiSvgIcon-root": {
    fontSize: getREMFromPX(theme.spacing * 4),
  },
  "&.Mui-checked": {
    color: theme[themePath].colors.primary[500],
  },
});
