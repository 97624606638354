import { useMutation, UseMutationOptions } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { LoginUser, SingleUser } from "../api/interfaces/User";
import fetcher from "../utils/fetchWrapper";

const loginUser = async (userInfo: LoginUser) => {
  const { userName, password } = userInfo;
  const response = await fetcher(
    `${endpoints.loginUser}?username=${userName}&password=${encodeURIComponent(
      password
    )}&application=${process.env.REACT_APP_APPLICATION_NAME}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  if (!response.ok) {
    if (response.status === 409) {
      const body = await response.json();
      if (
        body.message.replace(/[- .]/g, "") ===
        "Invalidusername/passwordcombination"
      ) {
        return "Invalid Email or Username / Password combination.";
      }
    }
    throw new Error();
  }
  return await response.json();
};

export const useLoginUser = (
  options?: UseMutationOptions<
    SingleUser | "Invalid Email or Username / Password combination.",
    Error,
    LoginUser
  >
) => useMutation((body: LoginUser) => loginUser(body), options);
