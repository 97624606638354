import React, { useState } from "react";
import { IPaymentHistoryProps } from "./interfaces";
import StatusBadge from "./components/StatusBadge";
import {
  Table,
  TableRow,
  TableHeader,
  TableCell,
  TableHeaderRow,
} from "./styles";
import {
  Box,
  CircularProgress,
  Modal,
  Typography,
  styled,
} from "@mui/material";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../AddressAndAccountProvider";
import { endpoints } from "../../api/constants/endpoints";
import fetcher from "../../utils/fetchWrapper";
import { theme } from "../../theme";
import useGetThemePath from "../../hooks/useGetThemePath";

const DownloadArea = styled("div")`
  width: 60px;
`;
const DownloadButton = styled("button")`
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #488b5b;
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
`;

export const PaymentHistory: React.FC<IPaymentHistoryProps> = ({ data }) => {
  const themePath = useGetThemePath();
  const { selectedAccountId } = React.useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const [isLoading, setIsLoading] = useState(false);

  const fetchPDF = async (invoice_id: string) => {
    setIsLoading(true);
    const url = `${endpoints.account}/${selectedAccountId}/billing/receiptPDF?invoice_id=${invoice_id}`;

    try {
      const response = await fetcher(url);
      if (!response.ok) {
        throw new Error("Failed to fetch the PDF file.");
      }

      const pdfBlob = await response.blob();
      const pdfObjectUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfObjectUrl, "_blank");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!data) <CircularProgress />;
  return (
    <>
      <Table data-cy="payment-history-table">
        <thead>
          <TableHeaderRow>
            <TableHeader style={{ textAlign: "left" }}>Description</TableHeader>
            <TableHeader>Amount</TableHeader>
            <TableHeader>Date</TableHeader>
            <TableHeader>Status</TableHeader>
            <TableHeader></TableHeader>
          </TableHeaderRow>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <TableRow key={index} data-cy={`payment-history-row-${index}`}>
              <TableCell
                style={{ textAlign: "left", paddingLeft: "28px" }}
                data-cy={`payment-history-row-${index}-description`}
              >
                {row.invoice}
              </TableCell>
              <TableCell data-cy={`payment-history-row-${index}-amount`}>
                ${row.amount.toFixed(2)}
              </TableCell>
              <TableCell data-cy={`payment-history-row-${index}-date`}>
                {row.date}
              </TableCell>
              <TableCell data-cy={`payment-history-row-${index}-status`}>
                <StatusBadge status={row.status} tooltip={row.error} />
              </TableCell>
              <TableCell data-cy={`payment-history-row-${index}-download`}>
                <DownloadArea>
                  <DownloadButton
                    onClick={() => fetchPDF(row.id)}
                    style={{ color: theme.new.colors.teal }}
                    data-cy={`payment-history-row-${index}-view`}
                  >
                    View
                  </DownloadButton>
                </DownloadArea>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <Modal open={isLoading} onClose={() => {}}>
        <Box
          width={499}
          height={224}
          borderRadius="10px"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: theme[themePath].colors.white,
            padding: "9px 3px 7px 2px",
          }}
        >
          <Typography
            fontSize="1.125rem"
            fontWeight={600}
            color={theme.new.colors.darkGreenFontColor}
            textAlign="center"
          >
            <CircularProgress
              size="4rem"
              style={{ color: "#488B5B", margin: "1.5rem" }}
            />
            <br />
            Your PDF is generating.
            <br />
            Please disable your pop-up blocker.
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default PaymentHistory;
