import { Stack, Typography } from "@mui/material";
import getREMFromPX from "../../utils/getREMFromPX";
import { theme } from "../../theme";
import Label from "../Label";
import Input from "../Input/Input";
import { isPossiblePhoneNumber, parsePhoneNumber } from "libphonenumber-js/max";
import ErrorMessage from "../ErrorMessage";
import { Controller, useForm } from "react-hook-form";
import { phoneInputStyle, selectStyle, usFlagStyles } from "./Styles";
import Select from "../Select/Select";
import SelectOption from "../SelectOption";
import { useGetEnums } from "../../hooks/useGetEnums";
import sendErrorToast from "../../utils/sendErrorToast";
import { Step2Type } from "../../routes/AccountSettings/AccountSettings";
import { CommonProps } from "@mui/material/OverridableComponent";
import InputMask from "react-input-mask";
import { useEffect, useMemo } from "react";
import { ReactComponent as USFlag } from "../../assets/USFlag.svg";

export type ContactAndLocationInfo = Step2Type;

interface ContactAndLocationEditProps extends CommonProps {
  children?: React.ReactElement;
  username?: string;
  previousValues: ContactAndLocationInfo;
  onSubmit: (options: ContactAndLocationInfo) => void;
  setIsValid: (isValid: boolean) => void;
}

const ContactAndLocationEdit = ({
  username,
  previousValues,
  children,
  onSubmit,
  setIsValid,
  ...props
}: ContactAndLocationEditProps) => {
  const {
    watch,
    register,
    reset,
    handleSubmit,
    control,
    formState: { isValid, errors },
  } = useForm<ContactAndLocationInfo>({
    mode: "onChange",
    defaultValues: useMemo(() => ({ ...previousValues }), [previousValues]),
  });

  useEffect(() => {
    reset({ ...previousValues });
  }, [previousValues, reset]);

  const { data } = useGetEnums({
    refetchOnWindowFocus: false,
    onError: () =>
      sendErrorToast("There was an error getting the states, please try again"),
  });

  useEffect(() => {
    // This will notify parent component if this form is valid or not.
    setIsValid(isValid);
  }, [isValid, setIsValid]);

  const name = watch("name").trim();
  const lastName = watch("lastName").trim();
  const phoneNumber = watch("phoneNumber").trim();
  const address = watch("address")?.trim();
  const city = watch("city");
  const state = watch("state");
  const zipCode = watch("zipCode").trim();

  const onSubmitHandler = () => {
    onSubmit({
      name,
      lastName,
      phoneNumber: parsePhoneNumber(phoneNumber, "US").nationalNumber,
      address,
      city,
      state,
      zipCode,
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
      {...props}
      data-testid="contactAndLocationForm"
    >
      <Stack
        spacing={getREMFromPX(theme.spacing * 5)}
        direction="row"
        alignItems="center"
        mt={getREMFromPX(theme.spacing * 8)}
      >
        <Stack width="50%" spacing={getREMFromPX(theme.spacing * 2)}>
          <Label htmlFor="name">Name</Label>
          <Input
            data-testid="nameInput"
            placeholder="Eg: John"
            id="name"
            {...register("name", { required: true })}
          />
        </Stack>
        <Stack width="50%" spacing={getREMFromPX(theme.spacing * 2)}>
          <Label htmlFor="lastName">Last Name</Label>
          <Input
            placeholder="Eg: Doe"
            id="lastName"
            {...register("lastName", { required: true })}
          />
        </Stack>
      </Stack>
      {username && (
        <Stack
          spacing={getREMFromPX(theme.spacing * 2)}
          mt={getREMFromPX(theme.spacing * 4)}
        >
          <Label htmlFor="email">Email</Label>
          <Input id="email" disabled value={username} />
        </Stack>
      )}
      <Stack
        spacing={getREMFromPX(theme.spacing * 2)}
        mt={getREMFromPX(theme.spacing * 4)}
      >
        <Label htmlFor="phoneNumber">Phone Number</Label>
        <Controller
          name="phoneNumber"
          rules={{ validate: (value) => isPossiblePhoneNumber(value, "US") }}
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <Stack
              direction="row"
              width="100%"
              flexWrap="wrap"
              gap={getREMFromPX(theme.spacing * 4)}
              alignItems="center"
            >
              <USFlag style={usFlagStyles} />
              <Typography>+1</Typography>
              <>
                <InputMask
                  alwaysShowMask
                  mask="(999)-999-9999"
                  value={value}
                  onChange={onChange}
                >
                  {/*@ts-ignore */}
                  {(inputProps) => (
                    <Input
                      {...inputProps}
                      type="tel"
                      error={!!errors.phoneNumber && !!phoneNumber.length}
                      id="phoneNumber"
                      style={phoneInputStyle}
                    />
                  )}
                </InputMask>
              </>
            </Stack>
          )}
        />
        {errors.phoneNumber && phoneNumber && (
          <ErrorMessage fontSize={getREMFromPX(theme.spacing * 2.5)}>
            Invalid Phone Number. Please enter a new one.
          </ErrorMessage>
        )}
      </Stack>
      <Stack
        spacing={getREMFromPX(theme.spacing * 2)}
        mt={getREMFromPX(theme.spacing * 4)}
      >
        <Label htmlFor="address">Street Address</Label>
        <Input id="address" {...register("address", { required: true })} />
      </Stack>
      <Stack
        spacing={getREMFromPX(theme.spacing * 2)}
        mt={getREMFromPX(theme.spacing * 4)}
      >
        <Label htmlFor="city">City</Label>
        <Input id="city" {...register("city", { required: true })} />
      </Stack>
      <Stack
        spacing={getREMFromPX(theme.spacing * 2)}
        mt={getREMFromPX(theme.spacing * 4)}
      >
        <Label htmlFor="state">State</Label>
        <Controller
          name="state"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <div style={selectStyle}>
              <Select {...field} id="state" value={data ? field.value : ""}>
                {data &&
                  Object.keys(data.data.system['States.US']).map((key) => (
                    <SelectOption data-testid={key} key={key} value={key}>
                      {data.data.system['States.US'][key]}
                    </SelectOption>
                  ))}
              </Select>
            </div>
          )}
        />
      </Stack>
      <Stack
        spacing={getREMFromPX(theme.spacing * 2)}
        mt={getREMFromPX(theme.spacing * 4)}
      >
        <Label htmlFor="zipCode">Zip Code</Label>
        <Input
          {...register("zipCode", {
            required: true,
            maxLength: 5,
            pattern: { value: /^\d{5}$/, message: "Invalid zip code" },
          })}
          id="zipCode"
        />
      </Stack>
      {/* <Stack
        direction="row"
        justifyContent="space-between"
        spacing={getREMFromPX(theme.spacing * 4)}
        mt={getREMFromPX(theme.spacing * 4)}
        flexWrap="wrap"
      >
        <Stack 
          spacing={getREMFromPX(theme.spacing * 2)} 
          mb={getREMFromPX(theme.spacing * 4)}
          >
          <Label htmlFor="city">City</Label>
          <Input id="city" {...register("city", { required: true })} />
        </Stack>
        <Stack 
          spacing={getREMFromPX(theme.spacing * 2)} 
          mb={`${getREMFromPX(theme.spacing * 4)} !important`}
          ml="0 !important"
          >
          <Label htmlFor="state">State</Label>
          <Controller
            name="state"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <div style={selectStyle}>
                <Select {...field} id="state" value={data ? field.value : ""}>
                  {data &&
                    Object.keys(data.data.system['States.US']).map((key) => (
                      <SelectOption data-testid={key} key={key} value={key}>
                        {data.data.system['States.US'][key]}
                      </SelectOption>
                    ))}
                </Select>
              </div>
            )}
          />
        </Stack>
        <Stack 
          spacing={getREMFromPX(theme.spacing * 2)} 
          mb={`${getREMFromPX(theme.spacing * 4)} !important`}
          ml="0 !important"
          >
          <Label htmlFor="zipCode">Zip Code</Label>
          <Input {...register("zipCode", { required: true })} id="zipCode" />
        </Stack>
      </Stack> */}
      {children}
    </form>
  );
};

export default ContactAndLocationEdit;
