import { Box, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Header = styled(Typography)`
  // width: 266px;
  height: 49.7px;
  margin-top: 2.5rem;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--dark-green);
`;

const Body = styled(Typography)`
  margin-top: 0.89rem;
  color: var(--neutrals-medium, #a9a9a9);
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
`;

export const Button = styled("button")`
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 3.5rem;
  padding: 16px 50px;
  border-radius: 15px;
  background-color: #d7f1da;
  flex-grow: 0;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark-green-font-color, #253331);
  border: none;
  cursor: pointer;
  :hover {
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
      0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  }
  :focus {
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
      0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  }
`;

const EmptyMyServicesMessage = () => {
  const navigate = useNavigate();
  return (
    <Box display="flex flex-row">
      <Header>Select the provider that suits you best.</Header>
      <Body>Search plans between the different available providers.</Body>
      <Box textAlign="center" style={{ justifyContent: "center" }}>
        <Button onClick={() => navigate(`/services/`)}>Find a Plan</Button>
      </Box>
    </Box>
  );
};

export default EmptyMyServicesMessage;
