import { useContext } from "react";
import { Navigate } from "react-router";
import { AuthContext, AuthenticationContext } from "../AuthProvider";
import { useGetConfig } from "../../hooks/useGetConfig";

const ProtectedRoute = ({
  redirectPath = "/",
  children,
}: {
  redirectPath?: string;
  children: React.ReactElement;
}) => {
  const { isAuthenticated } = useContext(AuthContext) as AuthenticationContext;
  const { data: config } = useGetConfig();
  const showBilling = config?.data?.config?.enabled_features?.BILLING?.enabled;

  const formerLocation = {
    formerLocation: window.location.pathname,
  };

  if (isAuthenticated === null) {
    return null;
  }

  if (formerLocation?.formerLocation === "/billing" && !showBilling) {
    return <Navigate to={"/"} />;
  }

  if (isAuthenticated === false) {
    return <Navigate to={redirectPath} state={formerLocation} />;
  }

  return children;
};
export default ProtectedRoute;
