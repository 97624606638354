import { Stepper, StepperProps } from "@mui/material";
import useGetThemePath from "../../hooks/useGetThemePath";
import { getStepperStyles } from "./Styles";

const StyledStepper = ({ children, ...props }: StepperProps) => {
  const themePath = useGetThemePath();
  return (
    <Stepper sx={getStepperStyles(themePath)} {...props}>
      {children}
    </Stepper>
  );
};
export default StyledStepper;
