import { Typography, Grid } from "@mui/material";
import { ChargesBox } from "../../../../components/NewCardWrapper";
import { theme } from "../../../../theme";
import formatNumber from "../../../../utils/formatNumber";
import { TypesOfStatus } from "../../../../components/NewPaymentMethodCard/PaymentMethodCard";
import getREMFromPX from "../../../../utils/getREMFromPX";

export interface MyPlanProps {
  estimatedFees?: number;
  operatorFees?: number;
  status?: TypesOfStatus;
  providerName?: string;
}

const MyPlan = ({
  estimatedFees,
  operatorFees,
  status,
  providerName = "Network Operator",
}: MyPlanProps) => {
  return (
    <>
      <Typography
        sx={{
          fontSize: "0.875rem",
          fontFamily: "Montserrat",
          fontWeight: 600,
          color: theme["new"].colors.mediumGrey,
          marginTop: "12px",
        }}
      >
        To see a full breakdown of your bill, please download your statement
      </Typography>
      <div style={{ height: "36px" }} />

      <Grid container sx={{ alignItems: "center", height: "100%" }}>
        <Grid item xs sm={6}>
          <ChargesBox
            title={"Estimated Service Provider Fees"}
            status={status}
            value={
              estimatedFees
                ? formatNumber(estimatedFees, { prefix: "$", decimals: 2 })
                : "..."
            }
          />
        </Grid>
        <Grid item xs={12} sm={1} />
        <Grid item xs sm={5}>
          <ChargesBox
            title={`${providerName} Fees`}
            status={status}
            value={
              operatorFees
                ? formatNumber(operatorFees, { prefix: "$", decimals: 2 })
                : "..."
            }
          />
        </Grid>
      </Grid>
      <Typography
        sx={{
          fontSize: "0.625rem",
          fontFamily: "Montserrat",
          fontWeight: 500,
          color: theme["new"].colors.dark,
          marginTop: "12px",
        }}
      >
        *Service fees provided are only an estimate. To know your exact service
        fees, please refer to your providers bill.
      </Typography>
    </>
  );
};

export default MyPlan;
