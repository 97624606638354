import { Box, Typography } from "@mui/material";
import React from "react";
import useViewport from "../../../hooks/useViewport";

const EmptyMyServicesMessage = () => {
  const { isMobile } = useViewport();
  return (
    <Box display="flex" justifyContent="center">
      <Typography
        textAlign="center"
        fontFamily="Montserrat"
        fontSize={isMobile ? "12px" : "18px"}
        fontWeight="500"
        color="#6d6d6d"
        marginTop="47px"
        maxWidth={isMobile ? 238 : 1000}
      >
        You are not subscribed to any services. Please go to 'All Services' to
        view and subscribe to services.
      </Typography>
    </Box>
  );
};

export default EmptyMyServicesMessage;
