import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const stepperContainerStyle = {
  margin: `${getREMFromPX(theme.spacing * 12)} auto ${getREMFromPX(
    theme.spacing * 4
  )} auto`,
  padding: `0 ${getREMFromPX(theme.spacing * 2)}`,
  maxWidth: getREMFromPX(theme.spacing * 170),
};
