import {
  Backdrop,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { Step1Type, Step2Type } from "../../AccountSettings";
import {
  headerStyle,
  dividerStyle,
  getSubHeaderStyle,
  iconStyle,
} from "./Styles";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import ContactAndLocationEdit, {
  ContactAndLocationInfo,
} from "../../../../components/ContactAndLocationEdit/ContactAndLocationEdit";
import { useState } from "react";
import { useGetEnums } from "../../../../hooks/useGetEnums";
import Button from "../../../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Step2Props {
  changeStep: (args: Step2Type, isFinalStep: boolean) => void;
  previousValues: {
    step1: Step1Type;
    step2: Step2Type;
  };
  goBackOneStep: () => void;
  isFinalStep?: boolean,
}

const Step2 = ({ changeStep, previousValues, goBackOneStep, isFinalStep }: Step2Props) => {
  const { isLoading } = useGetEnums({ refetchOnWindowFocus: false });
  const [isValid, setIsValid] = useState(false);

  const themePath = useGetThemePath();

  const onSubmit = (data: ContactAndLocationInfo) => {
    changeStep({ stepName: previousValues?.step2?.stepName, ...data }, isFinalStep ?? false);
  };

  const onValidChanged = (newValidValue: boolean) => {
    setIsValid(newValidValue);
  };

  return (
    <>
      <Stack spacing={getREMFromPX(theme.spacing * 2)}>
        <Typography sx={headerStyle} component="h1">
          Personal Information
        </Typography>
        <Typography sx={getSubHeaderStyle(themePath)}>
          Fill in the form below with your personal information.
        </Typography>
      </Stack>
      <ContactAndLocationEdit
        username={previousValues.step1.userName}
        previousValues={previousValues.step2}
        onSubmit={onSubmit}
        setIsValid={onValidChanged}
      >
        <>
          <Divider sx={dividerStyle} />
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            mt={getREMFromPX(theme.spacing * 6)}
          >
            <Button
              startIcon={
                <FontAwesomeIcon icon="angle-left" style={iconStyle} />
              }
              onClick={goBackOneStep}
              variant="text"
              text="Back"
              size="medium"
            />
            <Button
              size="medium"
              data-testid="continueButton"
              text="Continue"
              type="submit"
              disabled={!isValid}
            />
          </Stack>
        </>
      </ContactAndLocationEdit>
      <Backdrop open={isLoading}>
        <CircularProgress data-testid="progressSpinner" color="inherit" />
      </Backdrop>
    </>
  );
};
export default Step2;
