import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { SystemVariables } from "../api/interfaces/SystemVariables";
import fetcher from "../utils/fetchWrapper";

const getSystemVariables = async () => {
  const response = await fetcher(`${endpoints.getSystemVariables}`);

  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const useGetSystemVariables = (
  options?: UseQueryOptions<
    SystemVariables,
    Error,
    SystemVariables,
    Array<string>
  >
): UseQueryResult<SystemVariables, Error> =>
  useQuery(["systemVariables"], getSystemVariables, options);
