import decoder, { JwtPayload } from "jwt-decode";

const decodeJWT = (token: string) => {
  try {
    const decoded = decoder<JwtPayload>(token);
    return decoded;
  } catch {
    return null;
  }
};
export default decodeJWT;
