import { Box, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import useViewport from "../../hooks/useViewport";
import getREMFromPX from "../../utils/getREMFromPX";

interface StepProps {
  label: string;
  icon: any;
  isActive: boolean;
  isCompleted: boolean;
  customAlignment?: boolean;
}

const Step = ({ label, icon, isActive, isCompleted }: StepProps) => {
  const { isMobile } = useViewport(1220);

  return (
    <>
      {isMobile ? (
        <Box
          sx={{
            backgroundColor: `${
              !!isCompleted ? "#d7f1da" : isActive ? "#02575C" : "#c5c5c5"
            }`,
            color: `${isActive ? "#fff" : "#000"}`,
            fill: "#fff",
            width: `${isActive ? "29px" : "18.5px"}`,
            padding: `${isActive ? "17px" : "14px"}`,
            height: `${isActive ? "29px" : "18.5px"}`,
            fontSize: getREMFromPX(18),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "4rem",
          }}
        >
          {!isCompleted ? icon : <CheckIcon />}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginBottom: "0.5rem",
          }}
        >
          <div
            style={{
              backgroundColor: `${isActive ? "#F0F0F0" : ""} `,
              border: `${isActive ? "solid 1px #F0F0F0" : ""}`,
              // width: `${getDynamicWidthByStep()}`,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              borderRadius: "20px",
              padding: "7px 5px",
            }}
          >
            <Typography
              sx={{
                fontSize: getREMFromPX(14),
                marginRight: "1.1rem",
                fontWeight: `${isActive ? "bold" : "normal"}`,
                paddingLeft: "30px",
              }}
            >
              {label}
            </Typography>
            <Box
              sx={{
                backgroundColor: `${
                  !!isCompleted ? "#d7f1da" : isActive ? "#02575C" : "#c5c5c5"
                }`,
                color: `${isActive ? "#fff" : "#000"}`,
                fill: "#fff",
                width: 40,
                height: 40,
                fontSize: getREMFromPX(18),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "4rem",
              }}
            >
              {!isCompleted ? icon : <CheckIcon />}
            </Box>
          </div>
        </Box>
      )}
    </>
  );
};

export default Step;
