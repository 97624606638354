import { ReactNode } from "react";
import { BoxProps, Card, CardContent, styled } from "@mui/material";

const StyledTileCard = styled(Card)`
  display: flex;
  height: 8.18344rem;
  padding-bottom: 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 1.25rem;
  background: var(--white, #fff);
  box-shadow: 0px 0px 10px 0px rgba(201, 218, 216, 0.6);
`;

const StyledTileContent = styled(CardContent)`
  width: 100%;
`;

type TileWrapperProps = {
  children?: ReactNode;
} & BoxProps;

const TileWrapper = ({ children }: TileWrapperProps) => {
  return (
    <StyledTileCard>
      <StyledTileContent>{children}</StyledTileContent>
    </StyledTileCard>
  );
};

export default TileWrapper;
