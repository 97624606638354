import { CSSObject } from "@emotion/react";
import { theme } from "./theme";

export const globalStyle = {
  "*": {
    boxSizing: "border-box" as CSSObject["boxSizing"],
  },
  body: {
    fontFamily: theme.fontFamily,
    background: theme.new.gradients.greenMenuGradient,
    margin: 0,
    width: "100%",
    height: "100%",
  },
  html: {
    fontSize: "100%",
  },
};
