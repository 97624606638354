import { useEffect, useMemo, useState } from "react";
import { routes } from "../../../../App";
import BreadCrumbs from "../../../../components/BreadCrumbs";

const InternetServicesBreadCrumbs = ({
  isComparing,
  setIsComparing,
  serviceType,
  ...props
}: {
  isComparing: boolean;
  setIsComparing: (value: boolean) => void;
  serviceType: string;
  noDivider?: boolean;
}) => {
  const initialBc = useMemo(
    () => [
      {
        name: "Add New Service",
        pathname: routes.services.path,
      },
      {
        name: `New ${serviceType}`,
        pathname: "",
        onClick: () => setIsComparing(false),
      },
    ],
    [serviceType, setIsComparing]
  );
  const [breadCrumbs, setBreadCrumbs] = useState(initialBc);

  useEffect(() => {
    if (isComparing) {
      setBreadCrumbs((oldBc) => [
        ...oldBc,
        {
          name: "Compare Plans",
          pathname: "",
        },
      ]);
    } else {
      setBreadCrumbs(initialBc);
    }
  }, [isComparing, setIsComparing, initialBc]);

  return <BreadCrumbs {...props} breadCrumbs={breadCrumbs} />;
};

export default InternetServicesBreadCrumbs;
