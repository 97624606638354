import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { Enums } from "../api/interfaces/Enums";
import fetcher from "../utils/fetchWrapper";

const getEnums = async () => {
  const response = await fetcher(`${endpoints.getEnums}?e=States.US`);

  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const useGetEnums = (
  options?: UseQueryOptions<Enums, Error, Enums, Array<string>>
): UseQueryResult<Enums, Error> => useQuery(["enums"], getEnums, options);
