import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const alertStyles = {
  "& .MuiAlert-message": {
    width: "100%",
  },
  background: theme.backgrounds.secondarySelection,
};

export const getIconStyles = (themePath: string) => ({
  color: theme[themePath].colors.textPrimary.primary,
  fontSize: getREMFromPX(theme.spacing * 5),
});
