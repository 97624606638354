import { MenuItem, MenuItemProps } from "@mui/material";
import useGetThemePath from "../../hooks/useGetThemePath";
import { getMenuItemStyles } from "./Styles";

const StyledMenuItem = ({ children, ...props }: MenuItemProps) => {
  const themePath = useGetThemePath();
  return (
    <MenuItem sx={getMenuItemStyles(themePath)} {...props}>
      {children}
    </MenuItem>
  );
};

export default StyledMenuItem;
