import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import fetcher from "../utils/fetchWrapper";

interface AchInfo {
  type: string;
  owner_name: string;
  account_holder_type: string;
  account_type: string;
  bank_name: string;
  last4: string;
  routing_number: string;
}

interface CreditCardInfo {
  type: string;
  owner_name: string;
  brand: string;
  country: string;
  exp_month: number;
  exp_year: number;
  funding: string;
  last4: string;
}

interface PaymentMethodData {
  data: {
    billing: {
      payment_method: AchInfo & CreditCardInfo;
    };
  };
  duration: number;
  messages: [];
}

const getPaymentMethod = async (accountId: string) => {
  if (!accountId) {
    return null;
  }
  const response = await fetcher(
    `${endpoints.account}/${accountId}/billing/paymentmethod`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const useGetPaymentMethod = (
  accountId: string,
  options?: UseQueryOptions<
    PaymentMethodData,
    Error,
    PaymentMethodData,
    Array<string>
  >
): UseQueryResult<PaymentMethodData, Error> =>
  useQuery(
    ["paymentMethod", accountId],
    () => getPaymentMethod(accountId),
    options
  );
