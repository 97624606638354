import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";

export const iconStyles = {
  fontSize: getREMFromPX(theme.spacing * 2.5),
  marginRight: getREMFromPX(theme.spacing * 4),
};

export const getHeaderStyles = (width?: string | number, sortable?: boolean) =>
  ({
    cursor: sortable ? "pointer" : "auto",
    textAlign: "left",
    fontWeight: theme.fonts.weights.normal,
    fontSize: getREMFromPX(theme.spacing * 3),
    width,
  } as React.CSSProperties);
