import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { SecurityQuestion, SecurityQuestions } from '../api/interfaces/SecurityQuestion';
import fetcher from "../utils/fetchWrapper";

const getSecurityQuestions = async (): Promise<SecurityQuestions> => {
  const response = await fetcher(`${endpoints.getSecurityQuestions}`);

  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

const getUsersSecurityQuestions = async (username?: string, userId?: string): Promise<SecurityQuestion[]> => {
  let url = '';
  if (username || userId) {
    url = username 
      ? `${endpoints.getUsersSecurityQuestions}?username=${username}` 
      : `${endpoints.getUser}/${userId}/security`;
    
    const questions = await getSecurityQuestions()
    const response = await fetcher(url);

    const resData = await response.json(); 

    if (!response.ok) {
      throw new Error(resData?.message);
    }
    
    const res: SecurityQuestion[] = []
    Object.values(resData.data.user).forEach(questionId => {
      const fullQuestion = questions.data.securityquestion.find(q => q.id === questionId);
      if (fullQuestion) { 
        res.push(fullQuestion); 
      }
    });
    return res;
  } else {
    return [];
  }
  
  
};

export const useGetSecurityQuestions = (
  options?: UseQueryOptions<
    SecurityQuestions,
    Error,
    SecurityQuestions,
    Array<string>
  >
): UseQueryResult<SecurityQuestions, Error> =>
  useQuery(["securityQuestions"], getSecurityQuestions, options);

export const useGetUsersSecurityQuestions = (
  username?: string,
  userId?: string,
  options?: UseQueryOptions<
    SecurityQuestion[],
    Error,
    SecurityQuestion[],
    Array<string>
    >
): UseQueryResult<SecurityQuestion[], Error> =>
  useQuery(["userSecurityQuestions", username ?? '', userId ?? ''], () => getUsersSecurityQuestions(username, userId), options);
