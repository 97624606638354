import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { useContext, useMemo, useState, useEffect } from "react";
import getREMFromPX from "../../../../../utils/getREMFromPX";
import { theme } from "../../../../../theme";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../../components/AddressAndAccountProvider";
import useArePortsLive from "../../../../../hooks/useArePortsLive";
import SelectDevice from "../../../../../components/SelectDevice";
import useViewport from "../../../../../hooks/useViewport";
import Link from "../../../../../components/Link";

const TableContent = ({
  selectedPort,
  setSelectedPort = (param) => {},
  deviceIsConnected = false,
}: {
  selectedPort: string;
  setSelectedPort: (param: string) => void;
  deviceIsConnected?: boolean;
}) => {
  const { currentDevice } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const { isMobile } = useViewport();

  const [showPortSelectedMessage, setShowPortSelectedMessage] = useState(false);
  const handleRadioChange = (id: string) => setSelectedPort(id);

  const devicePorts = useMemo(
    () =>
      currentDevice.Portinterfaces?.filter((port) => port.type === "LAN") ?? [],
    [currentDevice]
  );

  const { data: livePorts, isLoading: areLivePortsLoading } =
    useArePortsLive(devicePorts);

  const mappedWithLive = useMemo(
    () =>
      livePorts
        ? devicePorts.map((port) => {
            const live = livePorts.find(
              (p) => p?.data?.portinterface?.id === port?.id
            )?.data?.portinterface;
            return {
              ...port,
              live: live?.live,
            };
          })
        : devicePorts,
    [devicePorts, livePorts]
  );

  const mappedForTable = mappedWithLive.map((port) => {
    return {
      id: port.id,
      name: port.display_name,
      isActiveServiceInPort: !!port.Subscriptions?.length,
      linkState: areLivePortsLoading ? "loading..." : port.live?.link_state,
    };
  });

  useEffect(() => {
    if (mappedWithLive && !showPortSelectedMessage) {
      const availablePort = mappedWithLive.find(
        (port) =>
          port?.Subscriptions &&
          port.Subscriptions.length === 0 &&
          port.live?.link_state !== "disabled"
      );

      if (availablePort) {
        setSelectedPort(availablePort.id);
        setShowPortSelectedMessage(true);
        return;
      }
    }
  }, [mappedWithLive, setSelectedPort, showPortSelectedMessage]);

  return (
    <Box style={{ margin: isMobile ? 0 : getREMFromPX(10) }}>
      <Typography
        textAlign="center"
        fontSize={getREMFromPX(20)}
        fontFamily="Montserrat"
        color={theme.new.colors.darkGreen}
        fontWeight="bold"
        pb="15px"
      >
        Select a device to associate with this service
      </Typography>
      <Box
        sx={{
          width: isMobile ? "100%" : getREMFromPX(600),
          mx: isMobile ? getREMFromPX(5) : getREMFromPX(10),
        }}
      >
        <SelectDevice sx={{ width: "100%" }} />
      </Box>
      <Box>
        {!deviceIsConnected && (
          <>
            <Typography
              textAlign="center"
              fontSize={getREMFromPX(20)}
              fontFamily="Montserrat"
              color={theme.new.colors.errorRed}
              fontWeight="bold"
              pt="15px"
            >
              Your device is not connected to our systems.
              <br />
              Please make sure it is plugged into a power source.
            </Typography>
            <Typography
              textAlign="center"
              fontSize={getREMFromPX(20)}
              fontFamily="Montserrat"
              color={theme.new.colors.darkGreen}
              fontWeight="bold"
              py="12px"
            >
              For additional help please visit the{" "}
              <Link to="/help/device" size="large">
                Device Help Page.
              </Link>
            </Typography>
          </>
        )}

        {deviceIsConnected && (
          <>
            <Typography
              textAlign="center"
              fontSize={getREMFromPX(20)}
              fontFamily="Montserrat"
              color={theme.new.colors.darkGreen}
              fontWeight="bold"
              py="8px"
            >
              Select a port from the gateway to associate with this service
            </Typography>
            <>
              {showPortSelectedMessage && (
                <Typography
                  textAlign="center"
                  fontSize={getREMFromPX(16)}
                  fontFamily="Montserrat"
                  color={theme.new.colors.darkGreen}
                  fontWeight={600}
                  py="8px"
                >
                  A port has been detected and automatically selected for this
                  service.
                </Typography>
              )}
            </>
          </>
        )}
      </Box>
      <TableContainer
        sx={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Table sx={{ border: "none" }} style={{ maxWidth: "647px" }}>
          <TableHead>
            <TableRow
              style={{ height: "35px" }}
              sx={{
                backgroundColor: theme.new.colors.softGrey,
              }}
            >
              <TableCell
                sx={{
                  py: 0,
                  borderBottom: "none",
                  borderTopLeftRadius: "20px",
                  borderBottomLeftRadius: "20px",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  p: 0,
                  borderBottom: "none",
                }}
              >
                <Typography
                  textAlign="center"
                  fontSize={getREMFromPX(isMobile ? 10 : 14)}
                  fontFamily="Montserrat"
                  fontWeight="500"
                  sx={{ color: " #7e8281" }}
                >
                  Port
                </Typography>
              </TableCell>

              <TableCell
                sx={{
                  p: 0,
                  borderBottom: "none",
                }}
              >
                <Typography
                  textAlign="center"
                  fontSize={getREMFromPX(isMobile ? 10 : 14)}
                  fontFamily="Montserrat"
                  fontWeight="500"
                  sx={{ color: " #7e8281" }}
                >
                  Active Service in Port
                </Typography>
              </TableCell>

              <TableCell
                sx={{
                  p: 0,
                  borderBottom: "none",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                }}
              >
                <Typography
                  textAlign="center"
                  fontSize={getREMFromPX(isMobile ? 10 : 14)}
                  fontFamily="Montserrat"
                  fontWeight="500"
                  sx={{ color: " #7e8281" }}
                >
                  Link State
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mappedForTable.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  backgroundColor:
                    selectedPort === row.id
                      ? theme.new.colors.softGrey
                      : "inherit",
                }}
              >
                <TableCell
                  sx={{
                    borderTop: 10,
                    borderBottom: 10,
                    borderColor: "white",
                    py: 0,
                    borderRadius: "20px 0 0 20px",
                  }}
                >
                  <RadioGroup value={deviceIsConnected ? selectedPort : null}>
                    <FormControlLabel
                      label=""
                      disabled={row.isActiveServiceInPort || !deviceIsConnected}
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color:
                                selectedPort === row.id
                                  ? theme.new.colors.teal
                                  : theme.new.colors.softGrey,
                            },
                          }}
                        />
                      }
                      value={row.id}
                      onChange={() => handleRadioChange(row.id)}
                    />
                  </RadioGroup>
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: 10,
                    borderBottom: 10,
                    borderColor: "white",
                    py: 0,
                    borderRadius: "none",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    textAlign="center"
                    fontSize={getREMFromPX(isMobile ? 12 : 16)}
                    fontFamily="Montserrat"
                    color={
                      row.isActiveServiceInPort || !deviceIsConnected
                        ? theme.new.colors.subtitleGrey
                        : theme.new.colors.darkGreen
                    }
                    fontWeight="600"
                  >
                    {row.name}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: 10,
                    textAlign: "center",
                    borderBottom: 10,
                    borderColor: "white",
                    py: 0,
                    borderRadius: "none",
                  }}
                >
                  <Typography
                    textAlign="center"
                    fontSize={getREMFromPX(isMobile ? 12 : 16)}
                    fontFamily="Montserrat"
                    color={
                      row.isActiveServiceInPort || !deviceIsConnected
                        ? theme.new.colors.subtitleGrey
                        : theme.new.colors.darkGreen
                    }
                    fontWeight="600"
                  >
                    {!deviceIsConnected
                      ? "N/A"
                      : row.isActiveServiceInPort
                      ? "Service Activated "
                      : "No Active Service"}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: 10,
                    borderBottom: 10,
                    textAlign: "center",
                    borderColor: "white",
                    py: 0,
                    borderRadius: "0 20px 20px 0",
                  }}
                >
                  <Typography
                    textAlign="center"
                    fontSize={getREMFromPX(isMobile ? 12 : 16)}
                    fontFamily="Montserrat"
                    color={
                      row.isActiveServiceInPort || !deviceIsConnected
                        ? theme.new.colors.subtitleGrey
                        : theme.new.colors.darkGreen
                    }
                    fontWeight="600"
                    textTransform="capitalize"
                  >
                    {!deviceIsConnected ? "N/A" : row.linkState}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TableContent;
