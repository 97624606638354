import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getHeaderStyles, iconStyles } from "./styles";
import { Stack } from "@mui/material";
import { SortConfig } from "../../../../hooks/useSortableData";

const TableHeader = ({
  width,
  sortable,
  onClick,
  name,
  sortConfig,
  sortKey,
  style = {},
  ...props
}: {
  name: string;
  sortKey?: string;
  width?: string | number;
  sortable?: boolean;
  sortConfig: SortConfig;
  onClick?: (name: string) => void;
  style?: React.CSSProperties;
} & React.HTMLAttributes<HTMLTableCellElement>) => {
  const handleOnClick = () => onClick?.(name);
  const composedStyles = {
    ...style,
    ...getHeaderStyles(width, sortable),
  };
  const getSortIcon = () => {
    if (sortConfig?.key !== sortKey) {
      return <FontAwesomeIcon icon="sort" style={iconStyles} />;
    }
    if (sortConfig?.key === sortKey) {
      return sortConfig?.direction === "ascending" ? (
        <FontAwesomeIcon icon="sort-up" style={iconStyles} />
      ) : (
        <FontAwesomeIcon icon="sort-down" style={iconStyles} />
      );
    }
  };
  return (
    <th
      style={composedStyles}
      onClick={(sortable && handleOnClick) || undefined}
      {...props}
    >
      {sortable ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {name}
          {getSortIcon()}
        </Stack>
      ) : (
        name
      )}
    </th>
  );
};
export default TableHeader;
