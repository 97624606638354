import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import fetcher from "../utils/fetchWrapper";

export interface BillingError {
  code: string;
  created_at: string;
  decline_code: string;
  message: string;
}

interface BillingStatus {
  ach_verification_required: Boolean;
  microdeposit_arrival_date: string;
  microdeposit_type: string;
  errors?: BillingError[];
}

interface BillingStatusData {
  data: {
    billing: {
      billing_status: BillingStatus;
    };
  };
  duration: number;
  messages: [];
}

const getBillingStatus = async (accountId: string) => {
  if (!accountId) {
    return null;
  }
  const response = await fetcher(
    `${endpoints.account}/${accountId}/billing/status`
  );

  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const useGetBillingStatus = (
  accountId: string,
  options?: UseQueryOptions<
    BillingStatusData,
    Error,
    BillingStatusData,
    Array<string>
  >
): UseQueryResult<BillingStatusData, Error> =>
  useQuery(
    ["billingStatus", accountId],
    () => getBillingStatus(accountId),
    options
  );
