import { Fragment } from "react";
import ModalStep from "./ModalStep";
import styled from "@emotion/styled";
import getREMFromPX from "../../../../utils/getREMFromPX";

interface ModalStepperProps {
  steps: Array<{
    label: string;
    icon: any;
    isActive: boolean;
    isCompleted: boolean;
  }>;
}

const MobileDivision = styled("div")(({ theme }) => ({
  position: "relative",
  width: "32%",
  height: "50px",
  borderRadius: "50%",
  margin: "0 16px",
  "&:not(:last-child)::after": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "3px",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "transparent",
    borderBottom: `dashed 2px #cbcbcb`,
  },
}));

const ModalStepper = ({ steps = [] }: ModalStepperProps) => {
  return (
    <div
      style={{
        height: "40px",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        margin: `0 ${getREMFromPX(10)}`,
      }}
    >
      {steps?.map(({ icon, label, isActive, isCompleted }, idx) => (
        <Fragment key={idx}>
          <ModalStep
            icon={icon}
            label={label}
            isActive={isActive}
            isCompleted={isCompleted}
            key={idx}
          />
          {idx < steps.length - 1 && <MobileDivision />}
        </Fragment>
      ))}
    </div>
  );
};

export default ModalStepper;
