import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import { useGetBillingStatus } from "../../hooks/useGetBillingStatus";
import { useGetBillingPrices } from "../../hooks/useGetBillingPrices";
import { useGetPaymentMethod } from "../../hooks/useGetPaymentMethod";
import { useGetConfig } from "../../hooks/useGetConfig";
import { BillingPrices } from "../../hooks/useGetBillingPrices";

import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import {
  AuthContext,
  AuthenticationContext,
} from "../../components/AuthProvider";
import CustomModal from "../../components/NewCustomModal";
import Microdeposit from "./Microdeposit";
import ConfirmSwitch from "./ConfirmSwitch";
import PaymentFormWrapper from "./PaymentFormWrapper";
import SummaryCard from "./SummaryCard";
import moment from "moment";
import { useGetPaymentHistory } from "../../hooks/useGetPaymentHistory";
import { StatusBadgeStatus } from "../NewPaymentHistory/components/StatusBadge";
import { InvoiceData } from "../NewPaymentHistory/interfaces";

export type ErrorType = {
  created_at: string;
  code: string;
  decline_code: string;
  message: string;
};

export type BillingType = {
  verificationRequired: boolean;
  microdepositDate: string;
  microdepositType: string;
  paymentHistory: any[];
  errors?: ErrorType[];
  isLoading: boolean;
  refetch: Function;
  paymentMethod: any;
  setOpenModal: any;
  prices: BillingPrices;
};

type BillingProps = {
  children: React.ReactElement;
};

// stripe's error codes
export const errorCodes = {
  DEBIT_NOT_AUTHORIZED: "debit_not_authorized",
  MICRODEPOSIT_FAILED: "payment_method_microdeposit_failed",
  CLOSED_ACCOUNT: "account_closed",
  ACCOUNT_NOT_FOUND: "no_account",
  INSUFFICIENT_FUNDS: "insufficient_funds",
  INVALID_CURRENCY: "invalid_currency",
  CC_EXPIRED: "credit_expired",
  CC_INVALID: "credit_invalid",
  INCORRECT_BILLING_CODE: "incorrect_billing_code",
  CVC_INVALID: "cvc_invalid",
  CC_DECLINED: "credit_declined",
  CARD_DECLINED: "card_declined",
};

export const BillingContext = React.createContext<BillingType | {}>({});

const BillingProvider = ({ children }: BillingProps) => {
  const [userStatus, setUserStatus] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const { userInfo, currentAccount } = React.useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const { isAuthenticated } = React.useContext(
    AuthContext
  ) as AuthenticationContext;

  useEffect(() => {
    setUserStatus(userInfo?.data?.user?.status ?? "");
  }, [userInfo]);

  const { data: config } = useGetConfig();
  const showACH = config?.data?.config?.enabled_features?.ACH_PAYMENT?.enabled;
  const showBilling = config?.data?.config?.enabled_features?.BILLING?.enabled;

  const { data, isLoading, refetch } = useGetBillingStatus(currentAccount?.id, {
    enabled: showBilling,
    refetchOnWindowFocus: false,
  });

  const { data: pricesData } = useGetBillingPrices({
    enabled: showBilling,
    refetchOnWindowFocus: false,
  });

  const {
    data: paymentMethodData,
    isSuccess,
    refetch: refetchPaymentMethod,
  } = useGetPaymentMethod(currentAccount?.id, {
    enabled: showBilling,
    refetchOnWindowFocus: false,
  });

  const [verificationRequired, setVerificationRequired] = useState<
    Boolean | undefined
  >();
  const [microdepositDate, setMicrodepositDate] = useState<
    string | undefined
  >();
  const [microdepositType, setMicrodepositType] = useState<
    string | undefined
  >();

  const [billingErrors, setBillingErrors] = useState<ErrorType[] | undefined>(
    []
  );
  const [paymentType, setPaymentType] = useState("ACH");
  const [activeStep, setActiveStep] = useState(0);
  const [confirmSwitch, setConfirmSwitch] = useState<Boolean>(false);
  const [finishedSteps, setFinishedSteps] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState<any>();

  // Create a ref to store the latest value of isAuthenticated for timeout to use accurate value
  const isAuthenticatedRef = useRef(isAuthenticated);

  const processPaymentHistoryData = (data: any) => {
    const invoices = data?.data?.billing?.invoices || [];
    return invoices.map((invoice: InvoiceData) => {
      return {
        id: invoice.id,
        invoice:
          "Network Operator Service - " +
          moment(invoice.created).format("MMMM YYYY"),
        amount: invoice.total,
        date: moment(invoice.created).format("MMM D, YYYY"),
        status:
          invoice.status === "paid"
            ? StatusBadgeStatus.PAID
            : StatusBadgeStatus.FAILED,
        error: invoice.payment_intent?.message,
      };
    });
  };

  const { data: paymentHistoryData } = useGetPaymentHistory(
    currentAccount?.id,
    {
      enabled: showBilling,
      refetchOnWindowFocus: false,
    },
    isSuccess
  );

  useEffect(() => {
    isAuthenticatedRef.current = isAuthenticated;
  }, [isAuthenticated]);

  const paymentTypeBasedOnError = useMemo(() => {
    switch (billingErrors?.[0]?.code) {
      case errorCodes.DEBIT_NOT_AUTHORIZED:
      case errorCodes.MICRODEPOSIT_FAILED:
      case errorCodes.CLOSED_ACCOUNT:
      case errorCodes.ACCOUNT_NOT_FOUND:
      case errorCodes.INSUFFICIENT_FUNDS:
      case errorCodes.INVALID_CURRENCY:
        return "ACH";
      case errorCodes.CC_EXPIRED:
      case errorCodes.CC_INVALID:
      case errorCodes.INCORRECT_BILLING_CODE:
      case errorCodes.CVC_INVALID:
      case errorCodes.CC_DECLINED:
      case errorCodes.CARD_DECLINED:
        return "Credit Card";
      default:
        return null;
    }
  }, [billingErrors]);

  useEffect(() => {
    if (isAuthenticated) {
      refetch();
      refetchPaymentMethod();
    }
  }, [isAuthenticated, refetch, refetchPaymentMethod]);

  useEffect(() => {
    const pType =
      paymentMethodData?.data?.billing?.payment_method?.type === "card" ||
      !showACH
        ? "Credit Card"
        : "ACH";
    setPaymentType(paymentTypeBasedOnError || pType);
    setPaymentMethod(paymentMethodData?.data?.billing?.payment_method);
  }, [paymentMethodData, paymentTypeBasedOnError, showACH]);

  useEffect(() => {
    // set initial values based on server data
    const billingStatus = data?.data?.billing?.billing_status;
    setVerificationRequired(billingStatus?.ach_verification_required);
    setMicrodepositDate(
      moment(billingStatus?.microdeposit_arrival_date).format("MMM D, YYYY") ||
        "..."
    );
    setMicrodepositType(billingStatus?.microdeposit_type);

    setBillingErrors(billingStatus?.errors);

    // reset or initialize to the beginning if there are errors
    if (billingStatus?.errors?.length) {
      setFinishedSteps(false);
      setActiveStep(0);
    }
  }, [data]);

  useEffect(() => {
    // if modal was opened by the user (and not by errors from API) we need to
    // explicitly set finishedSteps to false so the confirmation screen will show
    if (openModal) {
      setFinishedSteps(false);
    }
  }, [openModal]);

  const setOpenModalWrapper = useCallback(
    (open: boolean) => {
      // popup should never open if not signed in or if showBilling is disabled
      if (!!isAuthenticatedRef.current && showBilling !== false && open) {
        setOpenModal(true);
      } else if (!open) {
        setOpenModal(false);
      }
    },
    [isAuthenticatedRef, showBilling]
  );

  // provider context values available to children
  const value = useMemo(
    () => ({
      verificationRequired,
      microdepositType,
      microdepositDate,
      paymentHistory: processPaymentHistoryData(paymentHistoryData),
      errors: billingErrors,
      isLoading,
      refetch,
      paymentMethod,
      prices: pricesData?.data?.billing?.prices,
      setOpenModal: setOpenModalWrapper,
    }),
    [
      billingErrors,
      pricesData,
      isLoading,
      refetch,
      verificationRequired,
      microdepositType,
      microdepositDate,
      paymentHistoryData,
      paymentMethod,
      setOpenModalWrapper,
    ]
  );

  // when microdeposit is verified, set value accordingly
  const onMicrodepositSuccess = (isVerificationRequired: Boolean) => {
    setVerificationRequired(isVerificationRequired);
  };

  useEffect(() => {
    // automatically navigate to microdeposit step if ACH and verificationRequired and no errors
    if (
      value.verificationRequired &&
      paymentType === "ACH" &&
      !value.errors?.length
    ) {
      setActiveStep(1);
      setFinishedSteps(false);
    }
  }, [paymentType, value]);

  useEffect(() => {
    // modal should open when verification is required or from payment errors, and stay open until the steps are complete
    setOpenModalWrapper(
      !!value.verificationRequired ||
        !!value.errors?.length ||
        !finishedSteps ||
        userStatus === "PENDING"
    );
  }, [
    finishedSteps,
    userStatus,
    value.errors?.length,
    value.verificationRequired,
    setOpenModalWrapper,
  ]);

  const closeModal = useCallback(() => {
    setOpenModal(false);
    setTimeout(async () => {
      // if window was closed without fixing errors, refetch and trigger popup again until billing has been resolved
      await refetch().then((res) => {
        if (
          res.data?.data?.billing?.billing_status?.errors?.length ||
          res.data?.data?.billing?.billing_status?.ach_verification_required
        ) {
          setOpenModalWrapper(true);
        }
      });
    }, 30000);
  }, [refetch, setOpenModalWrapper]);

  // when switching between payment types
  const onSwitch = () => {
    // set the corresponding payment type
    if (paymentType === "ACH") {
      setPaymentType("Credit Card");
    } else {
      setPaymentType("ACH");
    }

    // reset the flow to the first step
    setActiveStep(0);
    setConfirmSwitch(false);
  };

  const nextStep = () => {
    setActiveStep((current) => current + 1);
  };

  // StepsComponent is navigated with the activeStep state value
  // PaymentFormWrapper displays the form for either Credit Card or ACH
  // Microdeposit is only displayed with ACH and verificationRequired as true
  // SummaryCard displays the payment details for either Credit Card or ACH
  const stepsComponents: JSX.Element[] = useMemo(
    () => [
      <PaymentFormWrapper
        paymentType={paymentType}
        accountId={currentAccount?.id}
        setConfirmSwitch={setConfirmSwitch}
        setVerificationRequired={setVerificationRequired}
        setBillingErrors={setBillingErrors}
        nextStep={nextStep}
      />,
      ...((paymentType === "ACH" || paymentMethod?.type === "card") &&
      value.verificationRequired
        ? [
            <Microdeposit
              accountId={currentAccount?.id}
              onCancel={() => setActiveStep((current) => current - 1)}
              onSuccess={onMicrodepositSuccess}
            />,
          ]
        : []),
      <SummaryCard
        close={() => {
          closeModal();
          setActiveStep(0);
          setFinishedSteps(true);
        }}
        goBack={() => setActiveStep((current) => current - 1)}
        paymentType={paymentType}
      />,
    ],
    [currentAccount?.id, value, paymentType, closeModal, paymentMethod]
  );

  const cardProcessingFee =
    pricesData?.data?.billing?.prices?.cc_initial_processing_fee?.amount?.toFixed(
      2
    ) ?? 1.5;

  return (
    <BillingContext.Provider value={value}>
      <CustomModal open={openModal} onClose={closeModal}>
        {confirmSwitch ? (
          <ConfirmSwitch
            onSwitch={onSwitch}
            onCancel={() => setConfirmSwitch(false)}
            title={`Are you sure you want switch your payment method to ${
              paymentType === "ACH" ? "Credit Card" : "ACH"
            }?`}
            subtitle={
              paymentType === "ACH"
                ? `A $${cardProcessingFee} processing fee will be added when using a credit card.`
                : ""
            }
          />
        ) : (
          stepsComponents[activeStep]
        )}
      </CustomModal>
      {children}
    </BillingContext.Provider>
  );
};
export default BillingProvider;
