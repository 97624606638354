import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { LivePort } from "../api/interfaces/Port";
import fetcher from "../utils/fetchWrapper";

const isPortLive = async (deviceId: string, portId: string) => {
  const response = await fetcher(
    `${endpoints.isPortLive}/${deviceId}/portinterface/${portId}/live`
  );

  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const useIsPortLive = (
  deviceId: string,
  portId: string,
  options?: UseQueryOptions<LivePort, Error, LivePort, Array<string>>
): UseQueryResult<LivePort, Error> =>
  useQuery([deviceId, portId], () => isPortLive(deviceId, portId), options);
