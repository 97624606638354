import { Box, Typography } from "@mui/material";
import { theme } from "../../theme";
import { TypesOfStatus } from "../NewPaymentMethodCard/PaymentMethodCard";

export const ChargesBox = ({
  title,
  value,
  status,
}: {
  title: string;
  value: string;
  status?: TypesOfStatus;
}) => {
  const colors = theme["new"].colors;

  return (
    <Box
      flex={2}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        backgroundColor: `${
          status === "Paid" ? colors.lightGreen : colors.pink
        }`,
        borderRadius: "20px",
        height: "100px",
      }}
    >
      <Typography
        sx={{
          padding: "5px",
          fontSize: "1rem",
          fontFamily: "Montserrat",
          fontWeight: 600,
          color: colors.darkGreen,
        }}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <Typography
        sx={{
          padding: "5px",
          fontSize: "1.5rem",
          fontFamily: "Montserrat",
          fontWeight: 500,
          color: colors.darkGreen,
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};
