import { useState, useRef } from "react";
import styled from "@emotion/styled";
import { Rating, RatingProps, Popover, Stack, Typography } from "@mui/material";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import Button from "../Button";
import AllRatings from "./AllRatings";
import "./style.css"

interface ExtendedRatingProps extends RatingProps {
  fontSize?: string | number;
  planId?: string;
  planName?: string;
  individual?: boolean;
}

const StyledRating = styled(Rating)<ExtendedRatingProps>(
  ({ fontSize, color }) => {
    const themePath = useGetThemePath();
    return {
      "& .MuiRating-iconFilled": {
        color: color || theme[themePath].colors.okStatus,
      },
      fontSize: fontSize || getREMFromPX(theme.spacing * 4),
    };
  }
);

const CustomizedRating = ({
  fontSize,
  color,
  individual,
  planId,
  planName,
  ...props
}: ExtendedRatingProps) => {
  const [openedPopover, setOpenedPopover] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const popoverAnchor = useRef(null);

  const popoverEnter = () => {
    setOpenedPopover(true)
  };

  const popoverLeave = () => {
    setOpenedPopover(false)
  };

  const seeAll = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowDialog(true);
  }

  return (
    <>
      <div ref={popoverAnchor}
        aria-owns="mouse-over-popover"
        aria-haspopup="true"
        onMouseEnter={popoverEnter}
        onMouseLeave={popoverLeave}>
        <StyledRating color={color} fontSize={fontSize} precision={props.readOnly ? 0.2 : 1} {...props} />
      </div>
      {!individual && (
        <>
          <Popover
            id="mouse-over-popover"
            className="popover"
            classes={{
              paper: "popoverContent"
            }}
            open={openedPopover}
            anchorEl={popoverAnchor.current}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{onMouseEnter: popoverEnter, onMouseLeave: popoverLeave}}
          >
              <Stack padding={2} alignItems="center" justifyContent="center">
                <Typography>{props.value ? `${Math.round(props.value * 100) / 100} out of 5` : 'No reviews'}</Typography>
                {!!props.value && <Button variant="outlined" text="See all comments" onClick={seeAll} />}
              </Stack>
          </Popover>
          {planId && planName && showDialog && <AllRatings show={showDialog} onClose={() => setShowDialog(false)} planId={planId} planName={planName} />}
        </>
      )}
    </>
  );
}

export default CustomizedRating;
