import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import decodeJWT from "../utils/decodeJWT";

const useGetUserId = () => {
  const [userId, setUserId] = useState("");
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const getUserId = () => {
      const token = localStorage.getItem("token");
      try {
        const decodedToken: any = decodeJWT(token as string);
        setUserId(decodedToken.user_id);
      } catch {
        return setUserId("");
      }
    };
    getUserId();
  }, [userId, pathname]);

  return userId;
};
export default useGetUserId;
