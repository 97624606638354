import getREMFromPX from "../../../utils/getREMFromPX";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
export const renderStars = (
  averageStars: string,
  size?: number,
  customMargin?: string
) => {
  const stars = [];
  const filledStars = Number(averageStars) > 5 ? 5 : parseInt(averageStars);
  const remainingStars = 5 - filledStars;

  for (let i = 0; i < filledStars; i++) {
    stars.push(
      <StarIcon
        key={i}
        sx={{
          color: "#f9cb28",
          fontSize: getREMFromPX(size ?? 21),
          marginRight: customMargin ?? "8px",
        }}
      />
    );
  }

  for (let i = 0; i < remainingStars; i++) {
    stars.push(
      <StarOutlineIcon
        key={i + filledStars}
        sx={{
          color: "#ccc",
          fontSize: getREMFromPX(size ?? 21),
          marginRight: customMargin ?? "8px",
        }}
      />
    );
  }

  return stars;
};
