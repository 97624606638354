import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { NotificationQuery } from "../api/interfaces/Notification";
import fetcher from "../utils/fetchWrapper";

const getNotifications = async (userId: string) => {
  if (!userId) {
    return;
  }

  const response = await fetcher(
    `${endpoints.getUser}/${userId}/messageportaluser`
  );

  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const useGetNotifications = (
  userId: string,
  options?: UseQueryOptions<
    NotificationQuery,
    Error,
    NotificationQuery,
    Array<string>
  >
): UseQueryResult<NotificationQuery, Error> =>
  useQuery(["notifications", userId], () => getNotifications(userId), options);
