import { useMutation, UseMutationOptions } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import fetcher from "../utils/fetchWrapper";

type ReturnType = {
  message?: string;
  code?: string;
  duration: number;
  data?: any;
};

type MicrodepositType = {
  amounts?: string[];
  descriptor_code?: string;
};

const submitMicrodeposit = async (
  accountId: string,
  body: MicrodepositType
) => {
  const res = await fetcher(
    `${endpoints.account}/${accountId}/billing/achverify`,
    {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );

  return await res.json();
};

export const useSubmitMicrodeposit = (
  accountId: string,
  options?: UseMutationOptions<ReturnType, Error, MicrodepositType>
) =>
  useMutation(
    (body: MicrodepositType) => submitMicrodeposit(accountId, body),
    options
  );
