import NavContainer from "../../components/NewNavContainer/NavContainer";
import { Grid } from "@mui/material";
import Notifications from "./Notifications";
import UserDetails from "./UserDetails/UserDetails";
import SecurityDetails from "./SecurityDetails";

const UserProfile = () => {
  return (
    <NavContainer title="Profile">
      <Grid container spacing={3}>
        <UserDetails />
        <Notifications />
        <SecurityDetails />
      </Grid>
    </NavContainer>
  );
};

export default UserProfile;
