import { Stack } from "@mui/material";
import { useState } from "react";
import PlanBox from "../../../../components/PlanBox";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { MappedPlan } from "../../Plans";

const CompareScreen = ({ MappedPlans }: { MappedPlans: MappedPlan[] }) => {
  const [idsInUse, setIdsInUse] = useState(
    MappedPlans.map((plan) => plan.plan.id)
  );

  return (
    <Stack
      flexWrap="wrap"
      direction="row"
      justifyContent="center"
      gap={getREMFromPX(theme.spacing * 4)}
    >
      {MappedPlans.map((MappedPlan) => (
        <PlanBox
          setIdsInUse={setIdsInUse}
          idsInUse={idsInUse}
          key={MappedPlan.id}
          plan={MappedPlan}
          allPlans={MappedPlans}
        />
      ))}
    </Stack>
  );
};

export default CompareScreen;
