import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const LabelStyles = `
font-family: Montserrat;
font-size: ${getREMFromPX(16)};
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
margin-bottom: 8px;
color: ${theme["new"].colors.formGreyDark};
`;
