import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../App";
import NavContainer from "../../../../components/NewNavContainer";
import CardWrapper from "../../../../components/NewCardWrapper";
import { BackStyle } from "../../Styles";
import {
  SupportInfo,
  iconStyles,
  IconText,
  ServiceProviderMultiSubHeader,
  ServiceProviderMultDesc,
} from "../../Styles";
import { useGetMySubscriptions } from "../../../../hooks/useGetMySubscriptions";
import useGetUserId from "../../../../hooks/useGetUserId";
import sendErrorToast from "../../../../utils/sendErrorToast";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LogoContainer } from "../../../../routes/Services/components/CardService";
import { Box, Grid } from "@mui/material";

const ServiceProviders = ({ children }: { children?: React.ReactElement }) => {
  const navigate = useNavigate();

  const userId = useGetUserId();

  const { selectedAccountId } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const { data: subscriptionsData } = useGetMySubscriptions(
    userId,
    selectedAccountId,
    {
      enabled: !!userId && !!selectedAccountId,
      refetchOnWindowFocus: false,
      onError: () =>
        sendErrorToast(
          "There was an error getting the subscriptions information, please try again"
        ),
    }
  );

  const backAction = (
    <BackStyle
      onClick={() => {
        console.log({ routes });
        console.log(routes.help);
        navigate(routes.help.path);
      }}
    >
      Back to Help
    </BackStyle>
  );

  return (
    <NavContainer title="Help">
      <CardWrapper title="Service Provider Info" actions={backAction}>
        <ServiceProviderMultiSubHeader>
          Contact Your Service Providers
        </ServiceProviderMultiSubHeader>
        <ServiceProviderMultDesc>
          For any issues with your services. Please directly contact your
          service provider through the contacts below.
        </ServiceProviderMultDesc>
        <Grid container spacing={2}>
          {subscriptionsData?.data?.subscription?.map(
            (subscription: any, idx: any) => (
              <Grid item sm={6} md={4}>
                <Box
                  padding="19.5px 26px 23.5px 25px"
                  sx={{
                    width: "336px",
                    borderRadius: "15px",
                    boxShadow: "0 4px 10px 0 rgba(201, 218, 216, 0.6)",
                    maxHeight: "100%",
                    "&:hover": {
                      boxShadow: "0 0 17px 0 rgba(126, 130, 129, 0.39)",
                      shadowOpacity: 1,
                    },
                  }}
                >
                  <div>
                    <LogoContainer width="234" height="100%">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/provider/${subscription?.Serviceplan?.Service.Provider?.id}.png`}
                        alt=""
                      />
                    </LogoContainer>
                    <SupportInfo>
                      <FontAwesomeIcon icon="globe" style={iconStyles} />
                      <IconText>
                        {subscription?.Serviceplan?.Service?.support_url}
                      </IconText>
                    </SupportInfo>
                    <SupportInfo>
                      <FontAwesomeIcon icon="envelope" style={iconStyles} />
                      <IconText>
                        {
                          subscription?.Serviceplan?.Service.Provider
                            ?.Contacts[0]?.email
                        }
                      </IconText>
                    </SupportInfo>
                    <SupportInfo>
                      <FontAwesomeIcon icon="phone" style={iconStyles} />
                      <IconText>
                        {
                          subscription?.Serviceplan?.Service.Provider
                            ?.Contacts[0]?.phone
                        }
                      </IconText>
                    </SupportInfo>
                  </div>
                </Box>
              </Grid>
            )
          )}
        </Grid>
      </CardWrapper>
    </NavContainer>
  );
};
export default ServiceProviders;
