import getREMFromPX from "../../utils/getREMFromPX";
import { theme } from "../../theme";

export const selectStyle = {
  width: "100%",
};

export const phoneInputStyle = {
  flex: 1,
};

export const usFlagStyles = {
  width: getREMFromPX(theme.spacing * 16.25),
};
