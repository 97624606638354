import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";

export const headerStyle = {
  fontSize: getREMFromPX(theme.spacing * 5),
  fontWeight: theme.fonts.weights.bolder,
};

export const getSubHeaderStyle = (themePath = "default") => ({
  fontSize: getREMFromPX(theme.spacing * 4.5),
  fontWeight: theme.fonts.weights.mediumBold,
  color: theme[themePath].colors.textPrimary.secondary,
});

export const dividerStyle = {
  margin: `${getREMFromPX(theme.spacing * 8)} 0 ${getREMFromPX(
    theme.spacing * 6
  )} 0`,
};

export const buttonStyle = {
  marginLeft: "auto",
};

export const iconStyle = {

};

export const inputAdornmentStyle = {
  display: "flex",
  alignItems: "center",
};

export const iconStyleAdornment = {
  marginLeft: "-4rem",
};

export const iconStylePad = {
  borderRadius: "10%",
  marginLeft: getREMFromPX(theme.spacing * 3),
};