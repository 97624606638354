interface FormatNumberSettings {
  prefix?: string;
  suffix?: string;
  decimals?: number;
}
export const formatNumber = (
  originalValue: number,
  { prefix = "", suffix = "", decimals = 0 }: FormatNumberSettings
) => {
  const value = originalValue.toFixed(decimals);
  return `${prefix}${value}${suffix}`;
};

export default formatNumber;
