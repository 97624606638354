import { SortFilterLabel } from "../../../components/AllServices/interfaces";
import { Plan } from "./intefaces/Plan";

export const sortByProviderName = (a: Plan, b: Plan): number => {
  if (a.providerName < b.providerName) {
    return -1;
  }
  if (a.providerName > b.providerName) {
    return 1;
  }
  return 0;
};

export const applyExtraFilters = (
  arr: Array<Plan>,
  selectedTypeServiceFilter: string,
  sortFilter: SortFilterLabel | null
): Array<Plan> => {
  const sortMappings: {
    [key in SortFilterLabel]: (a: Plan, b: Plan) => number;
  } = {
    priceHighToLow: (a: Plan, b: Plan) =>
      Number(b.fields.Price) - Number(a.fields.Price),
    priceLowToHigh: (a: Plan, b: Plan) =>
      Number(a.fields.Price) - Number(b.fields.Price),
    rating: (a: Plan, b: Plan) =>
      parseInt(b.total_reviews, 10) - parseInt(a.total_reviews, 10),
    provider: (a: Plan, b: Plan) => sortByProviderName(a, b),
  };

  let preparedArr: Array<Plan> =
    selectedTypeServiceFilter === "All Services"
      ? arr
      : arr.filter((p) => p.name === selectedTypeServiceFilter);

  if (sortFilter && preparedArr.length > 1) {
    preparedArr.sort(sortMappings[sortFilter]);
  }

  return preparedArr;
};
