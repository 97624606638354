export const endpoints = {
  account: `${process.env.REACT_APP_BE_URL}/account`,
  device: `${process.env.REACT_APP_BE_URL}/device`,
  validateUser: `${process.env.REACT_APP_BE_URL}/user/available`,
  createUser: `${process.env.REACT_APP_BE_URL}/User`,
  createReview: `${process.env.REACT_APP_BE_URL}/Review`,
  getPlanReviews: `${process.env.REACT_APP_BE_URL}/Serviceplan`,
  getUser: `${process.env.REACT_APP_BE_URL}/User`,
  updateUser: `${process.env.REACT_APP_BE_URL}/User`,
  updateContact: `${process.env.REACT_APP_BE_URL}/Contact`,
  updateLocation: `${process.env.REACT_APP_BE_URL}/Location`,
  getSecurityQuestions: `${process.env.REACT_APP_BE_URL}/Securityquestion`,
  getUsersSecurityQuestions: `${process.env.REACT_APP_BE_URL}/User/Security`,
  getEnums: `${process.env.REACT_APP_BE_URL}/System/enums`,
  loginUser: `${process.env.REACT_APP_BE_URL}/User/Authenticate`,
  resetPassword: `${process.env.REACT_APP_BE_URL}/User/ResetPassword`,
  getServices: `${process.env.REACT_APP_BE_URL}/servicetype`,
  getProvider: `${process.env.REACT_APP_BE_URL}/provider/${process.env.REACT_APP_GUID}`,
  getAllServicesWithPlans: `${process.env.REACT_APP_BE_URL}/servicetype`,
  getPorts: `${process.env.REACT_APP_BE_URL}/user`,
  isPortLive: `${process.env.REACT_APP_BE_URL}/device`,
  isDeviceOn: `${process.env.REACT_APP_BE_URL}/device`,
  deviceSearch: `${process.env.REACT_APP_BE_URL}/device/search`,
  createSubscription: `${process.env.REACT_APP_BE_URL}/account`,
  deleteSubscription: `${process.env.REACT_APP_BE_URL}/Subscription`,
  getSystemVariables: `${process.env.REACT_APP_BE_URL}/system/variables`,
  getEnabledFeatures: `${process.env.REACT_APP_BE_URL}/config/enabledfeatures`,
  message: `${process.env.REACT_APP_BE_URL}/messageportaluser`,
};
