import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";

export const selectStyles = {
  flex: "1",
  minWidth: getREMFromPX(theme.spacing * 30),
};

export const searchButtonStyles = {
  marginLeft: getREMFromPX(theme.spacing * 2),
};
