import React, { useContext } from "react";
import { Grid, Hidden } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CardWrapper from "../../../../components/NewCardWrapper";
import { routes } from "../../../../App";
import NavContainer from "../../../../components/NewNavContainer";
import CardWrapperFilled from "../../../../components/NewCardWrapper/CardWrapperFilled";
import { EmailIcon, FaqIcon, WorldIcon } from "../../../../assets/icons";
import { useGetSystemVariables } from "../../../../hooks/useGetSystemVariables";
import sendErrorToast from "../../../../utils/sendErrorToast";
import { BackStyle } from "../../Styles";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";
import {
  Answer,
  EmailStyle,
  LinkStyle,
  Message,
  Question,
  RowContainer,
  MessageTop,
} from "./Styles";
import { useGetConfig } from "../../../../hooks/useGetConfig";

const leftItems = {
  "How do I shop for internet service providers (ISPs) or see what other speed offerings are available?":
    "Check out our Services page for a variety of internet service providers and other offerings. Browse to find the solution that best fits your needs.",
  "How do I change my payment method or update my credit card?":
    'Easily manage your payment preferences: Visit the Billing page, find the "Payment methods" section, and update as needed.',
  "How do I unsubscribe from an internet service provider (ISPs)?":
    'To unsubscribe from your ISP, go to our Services page, select the "My Services" tab, and click on "unsubscribe".',
};
const rightItems = {
  "How do I access my internet service provider (ISP) bill?":
    "Visit your ISP's website for information or contact them directly for personalized help. Their team is ready to assist with your billing needs.",
};

const Faq = () => {
  const { provider } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const contacts =
    provider &&
    provider.data &&
    provider.data.provider &&
    provider.data.provider.Contacts;
  let email =
    provider &&
    provider.data &&
    provider.data.provider &&
    provider.data.provider.Contacts &&
    provider.data.provider.Contacts[0].email;
  if (Array.isArray(contacts)) {
    const aux = contacts.filter((contact) => contact.type === "SUPPORT");
    if (aux.length > 0) {
      email = aux[0].email;
    }
  }
  const navigate = useNavigate();
  const { data: config } = useGetConfig();

  const showBilling = config?.data?.config?.enabled_features?.BILLING?.enabled;

  const updatedLeftItems = showBilling
    ? leftItems
    : Object.fromEntries(
        Object.entries(leftItems).filter(
          ([key]) =>
            key !==
            "How do I change my payment method or update my credit card?"
        )
      );

  const { data } = useGetSystemVariables({
    refetchOnWindowFocus: false,
    onError: () =>
      sendErrorToast(
        "There was an error getting the recover username link, please try again"
      ),
  });
  function handleRedirectToTroubleShootingPage() {
    window.open(
      `${data?.data?.system.support_ticket_url}`,
      "_blank",
      "noopener,noreferrer"
    );
  }
  function handleRedirectToEmail() {
    window.open(`mailto:${email}`, "_blank", "noopener,noreferrer");
  }

  const backAction = (
    <BackStyle
      onClick={() => {
        console.log({ routes });
        console.log(routes.help);
        navigate(routes.help.path);
      }}
    >
      Back to Help
    </BackStyle>
  );

  return (
    <NavContainer title="Help">
      <CardWrapper title="Frequently Asked Questions" actions={backAction}>
        <Grid container>
          <Grid item md={12} lg={6}>
            {Object.entries(updatedLeftItems).map(([question, answer]) => (
              <Grid item key={question}>
                <Question>{question}</Question>
                <Answer>{answer}</Answer>
              </Grid>
            ))}
          </Grid>
          <Grid item md={12} lg={6} sx={{ zIndex: 2 }}>
            <Hidden lgUp>
              {Object.entries(rightItems).map(([question, answer]) => (
                <Grid item key={question}>
                  <Question>{question}</Question>
                  <Answer>{answer}</Answer>
                </Grid>
              ))}
            </Hidden>
            <Hidden lgDown>
              {Object.entries(rightItems).map(([question, answer]) => (
                <Grid item key={question} sx={{ marginLeft: "2rem" }}>
                  <Question>{question}</Question>
                  <Answer>{answer}</Answer>
                </Grid>
              ))}
            </Hidden>

            <CardWrapperFilled
              title="Need Further Assistance?"
              sx={{ marginTop: "3.9rem", zIndex: 2 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={7}>
                  <MessageTop>
                    Our customer service is available to assist you with any
                    further needs or inquiries you may have.
                  </MessageTop>
                  <Message>
                    Having trouble with your service? Please contact your
                    service provider.
                  </Message>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <RowContainer onClick={handleRedirectToTroubleShootingPage}>
                    <span style={{ verticalAlign: "middle" }}>
                      <WorldIcon style={{ width: "2rem" }} />
                    </span>
                    <LinkStyle>Click Here for Help</LinkStyle>
                  </RowContainer>
                  {email && (
                    <RowContainer onClick={handleRedirectToEmail}>
                      <span style={{ verticalAlign: "middle" }}>
                        <EmailIcon />
                      </span>
                      <EmailStyle>{email}</EmailStyle>
                    </RowContainer>
                  )}
                </Grid>
              </Grid>
            </CardWrapperFilled>

            <div
              style={{
                float: "right",
                marginTop: "83px",
                marginRight: "32px",
              }}
            >
              <FaqIcon />
            </div>
          </Grid>
        </Grid>
      </CardWrapper>
    </NavContainer>
  );
};
export default Faq;
