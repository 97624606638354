import React, { useContext } from "react";
import { CircularProgress } from "@mui/material";
import { useGetEstimatedFees } from "../../../../hooks/useGetEstimatedFees";
import MyPlan from "./MyPlan";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";
import {
  BillingContext,
  BillingType,
} from "../../../../components/BillingProvider";
import { TypesOfStatus } from "../../../../components/NewPaymentMethodCard/PaymentMethodCard";

export interface MyPlanIndexProps {
  onTotal: (total: number | undefined) => void;
  status?: TypesOfStatus;
}

const MyPlanIndex = ({ onTotal, status = "Paid" }: MyPlanIndexProps) => {
  const { currentAccount, provider } = React.useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const { prices } = useContext(BillingContext) as BillingType;

  const { data: estimateData, isLoading: isLoadingEstimate } =
    useGetEstimatedFees(currentAccount?.id, {
      refetchOnWindowFocus: false,
    });

  const operatorFees = prices?.utility_fee?.amount;
  const estimatedFees = estimateData?.data?.subscription?.reduce(
    (sum, item) => {
      return sum + parseFloat(item.Serviceplan.fields.Price);
    },
    0
  );

  onTotal((operatorFees || 0) + (estimatedFees || 0));

  if (isLoadingEstimate || !estimateData) <CircularProgress />;
  return (
    <MyPlan
      status={status}
      estimatedFees={estimatedFees}
      operatorFees={operatorFees}
      providerName={provider?.data?.provider?.name}
    />
  );
};

export default MyPlanIndex;
