import {
  Backdrop,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import Button from "../../../../components/Button";
import {
  MappedProviderWithPlan,
  Step1Type,
  Step2Type,
  Step3Type,
} from "../../SubscribeToISP";
import {
  headerStyle,
  buttonStyle,
  iconStyle,
  dividerStyles,
  normalisedButtonStyles,
  valueIconStyle,
  getSelectedItemStyles,
  wifiIconStyle,
  getBorderRadiusStyles,
  phantomFormStyles,
} from "./Styles";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { theme } from "../../../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "../../../../components/Table";
import { useContext, useMemo, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";
import { useGetServicesWithPlans } from "../../../../hooks/useGetServicesWithPlans";
import useMenuAnchor from "../../../../hooks/useMenuAnchor";
import Menu from "../../../../components/Menu";
import MenuItem from "../../../../components/MenuItem";
import useCreateSubscription from "../../../../hooks/useCreateSubscription";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Step3Props {
  goBackOneStep: () => void;
  changeStep: () => void;
  previousValues: {
    step1: Step1Type;
    step2: Step2Type;
    step3: Step3Type;
  };
}

const tableHeaders = [
  {
    name: "Service",
    style: {
      paddingLeft: getREMFromPX(theme.spacing * 4),
    },
  },
  { name: "Description" },
  { name: "Price" },
];

const tableColumns = [
  {
    value: "service",
    style: {
      paddingLeft: getREMFromPX(theme.spacing * 4),
    },
  },
  { value: "description" },
  { value: "price" },
];

const menuListProps = {
  "aria-labelledby": "toggle_plan_button",
};

const Step3 = ({ goBackOneStep, changeStep, previousValues }: Step3Props) => {
  const { serviceId } = useParams();
  const formRef = useRef<HTMLFormElement>(null);

  const { selectedAccount } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const { data } = useGetServicesWithPlans(
    serviceId as string,
    selectedAccount,
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedAccount,
    }
  );

  const [selectedPlan, setSelectedPlan] = useState(
    previousValues.step3.selectedPlan
  );

  const isDoneFunction = useCallback(
    () =>
      selectedPlan?.provider.subscription_redirect_url
        ? formRef.current?.submit()
        : changeStep(),
    [changeStep, selectedPlan?.provider.subscription_redirect_url]
  );

  const { handleSubscription, loading, subscriptionBody } =
    useCreateSubscription(
      isDoneFunction,
      selectedPlan as MappedProviderWithPlan
    );

  const { anchorEl, open, handleClick, handleClose } = useMenuAnchor();

  const themePath = useGetThemePath();

  const values = [
    {
      label: "Speed",
      icon: "gauge",
      value: selectedPlan?.plan.fields.LinkSpeed,
      borderRadiusLeft: true,
    },
    {
      label: "Down",
      icon: "angle-down",
      value: selectedPlan?.plan.fields.Down,
    },
    {
      label: "Up",
      icon: "angle-up",
      value: selectedPlan?.plan.fields.Up,
      borderRadiusRight: true,
    },
    {
      label: "Data",
      icon: "infinity",
      value:
        !selectedPlan?.plan.fields["Data Cap"] ||
        selectedPlan?.plan.fields["Data Cap"] === "None"
          ? "Unlimited"
          : selectedPlan?.plan.fields["Data Cap"],
    },
  ];

  const tableItems = [
    {
      service: selectedPlan?.provider?.Servicetype?.name,
      description: "Monthly",
      price: `$${selectedPlan?.plan.fields.Price}`,
      id: "0",
    },
    {
      service: "Total",
      description: "",
      price: `$${selectedPlan?.plan.fields.Price}`,
      id: "1",
    },
  ];

  const selectedRow = [tableItems[1]];

  const availableOptions = useMemo(
    () =>
      data?.data.service
        .filter(
          (service) =>
            service.Provider &&
            service.Provider.id === selectedPlan?.provider?.provider_id
        )
        .map((service) =>
          service.Plans.map((plan) => ({
            plan: plan,
            provider: service,
          }))
        )
        .flat()
        .filter((service) => service.plan.id !== selectedPlan?.plan.id),
    [data, selectedPlan]
  );

  return (
    <Stack spacing={getREMFromPX(theme.spacing * 4)}>
      <Typography component="h1" style={headerStyle}>
        Service Confirmation
      </Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center">
          <Typography fontSize={getREMFromPX(theme.spacing * 4.5)}>
            {selectedPlan?.provider?.Provider?.name}
          </Typography>
          <Divider flexItem orientation="vertical" style={dividerStyles} />
          <Typography color={theme[themePath].colors.textPrimary.secondary}>
            {selectedPlan?.plan.name}
          </Typography>
        </Stack>
        {availableOptions && availableOptions.length > 0 && (
          <button
            style={normalisedButtonStyles}
            onClick={handleClick}
            aria-label="toggle_plan_button"
          >
            <Typography
              fontWeight={theme.fonts.weights.bolder}
              color={theme[themePath].colors.primary[500]}
            >
              Change
            </Typography>
          </button>
        )}
        <Menu
          MenuListProps={menuListProps}
          id="plans-menu"
          anchorEl={anchorEl}
          open={
            (open && availableOptions && availableOptions.length > 0) || false
          }
          onClose={handleClose}
        >
          {availableOptions?.map((plan) => (
            <MenuItem
              key={plan.plan.id}
              onClick={() => {
                setSelectedPlan(plan);
                handleClose();
              }}
            >
              {plan.plan.name}
            </MenuItem>
          ))}
        </Menu>
      </Stack>
      <Stack direction="row" flexWrap="wrap">
        {values.map(
          (
            { label, value, icon, borderRadiusLeft, borderRadiusRight },
            index
          ) => (
            <Stack
              // margin={
              //   index === values.length - 1
              //     ? `0 ${getREMFromPX(theme.spacing * 2)}`
              //     : `0 ${getREMFromPX(theme.spacing * 2)} 0 0`
              // }
              margin={`0 ${getREMFromPX(theme.spacing * 2)} 0 0`}
              borderRadius={index === values.length - 1 ? "8px" : 0}
              key={label}
              alignItems="center"
              justifyContent="center"
              style={getBorderRadiusStyles(borderRadiusLeft, borderRadiusRight)}
              padding={getREMFromPX(theme.spacing)}
              border={`1px solid ${theme[themePath].colors.textSecondary.secondary}`}
              width={getREMFromPX(theme.spacing * 24)}
            >
              <FontAwesomeIcon
                icon={icon as IconProp}
                fontSize={getREMFromPX(theme.spacing * 6)}
                style={valueIconStyle}
              />
              <Typography
                color={theme[themePath].colors.textPrimary.secondary}
                fontSize={getREMFromPX(theme.spacing * 3)}
              >
                {label}
              </Typography>
              <Typography
                fontWeight={theme.fonts.weights.bolder}
                color={theme[themePath].colors.textPrimary.secondary}
              >
                {value}
              </Typography>
            </Stack>
          )
        )}
        <Stack
          borderRadius="8px"
          flex={1}
          direction="row"
          alignItems="center"
          style={getSelectedItemStyles(themePath)}
          padding={getREMFromPX(theme.spacing * 5)}
          spacing={getREMFromPX(theme.spacing)}
        >
          <FontAwesomeIcon
            style={wifiIconStyle}
            color={theme[themePath].colors.white}
            icon="ethernet"
            fontSize={getREMFromPX(theme.spacing * 6)}
          />
          <Stack alignItems="start">
            <Typography
              fontSize={getREMFromPX(theme.spacing * 4.5)}
              color={theme[themePath].colors.white}
            >
              Price
            </Typography>
            <Typography
              fontSize={getREMFromPX(theme.spacing * 5)}
              fontWeight={theme.fonts.weights.bolder}
              color={theme[themePath].colors.white}
            >
              ${selectedPlan?.plan.fields.Price}/mo
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Typography
        fontWeight={theme.fonts.weights.mediumBold}
        fontSize={getREMFromPX(theme.spacing * 4.5)}
      >
        How much will this affect my billing?
      </Typography>
      <Table
        controlledSelectedRows={selectedRow}
        headers={tableHeaders}
        columns={tableColumns}
        items={tableItems}
      />
      <Divider />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        mt={getREMFromPX(theme.spacing * 6)}
      >
        <Button
          startIcon={<FontAwesomeIcon icon="angle-left" style={iconStyle} />}
          onClick={goBackOneStep}
          variant="text"
          text="Back"
          size="medium"
          disabled={loading}
        />
        <Button
          data-testid="continue_button"
          text="Subscribe"
          size="medium"
          style={buttonStyle}
          disabled={loading}
          onClick={() =>
            handleSubscription({
              port_interface_id: previousValues.step1.port?.id as string,
              serviceplan_id: selectedPlan?.plan.id as string,
            })
          }
        />
      </Stack>
      <Backdrop open={loading}>
        <CircularProgress data-testid="progressSpinner" color="inherit" />
      </Backdrop>
      <form
        style={phantomFormStyles}
        ref={formRef}
        method="POST"
        action={selectedPlan?.provider.subscription_redirect_url}
      >
        {subscriptionBody &&
          Array.from(subscriptionBody).map(([name, value]) => (
            <input
              hidden
              key={name}
              value={value as string}
              name={name}
              readOnly
            />
          ))}
      </form>
    </Stack>
  );
};

export default Step3;
