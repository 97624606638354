const formatExpirationDate = (month?: number, year?: number) => {
  if (!month || !year) {
    return ".. / ..";
  }
  return `${month?.toString().padStart(2, "0")} / ${year
    ?.toString()
    .slice(-2)}`;
};

export default formatExpirationDate;
