import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { ServiceInfoBody } from "../api/interfaces/ServiceInfo";
import fetcher from "../utils/fetchWrapper";

const getServicesWithPlans = async (id: string, accountType: string) => {
  const loading = 
    "Servicetype," +
    "Provider.Contacts," +
    "Plans" +
    "&filter=" +
      `account_types like "${accountType}"::` +
      "display_type eq PUBLIC::" +
      "status eq ACTIVE::" +
      "Provider.status eq ACTIVE::" +
      "Plans.status eq ACTIVE";
  
  const response = await fetcher(
    `${endpoints.getAllServicesWithPlans}/${id}/service?loading=${loading}`
  );

  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const useGetServicesWithPlans = (
  id: string,
  accountType: string,
  options?: UseQueryOptions<
    ServiceInfoBody,
    Error,
    ServiceInfoBody,
    Array<string>
  >
): UseQueryResult<ServiceInfoBody, Error> =>
  useQuery(
    [id, accountType],
    () => getServicesWithPlans(id, accountType),
    options
  );
