import { theme } from "../../theme";

export const getStepperStyles = (themePath = "default") => ({
  "& .Mui-active": {
    color: `${theme[themePath].colors.secondary[500]} !important`,
  },
  "& .Mui-completed": {
    color: `${theme[themePath].colors.okStatus} !important`,
  },
});
