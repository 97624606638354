import { useMutation, UseMutationOptions } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import fetcher from "../utils/fetchWrapper";

type ReturnType = {
  message?: string;
  code?: string;
  duration: number;
  data?: any;
};

export type AchType = {
  routing_number: string;
  account_number: string;
  account_type: string;
  account_holder_type: string;
  items: string[];
  is_default_payment_method: boolean;
};

const submitAch = async (accountId: string, body: AchType) => {
  const res = await fetcher(`${endpoints.account}/${accountId}/billing/ach`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  return await res.json();
};

export const useSubmitAch = (
  accountId: string,
  options?: UseMutationOptions<ReturnType, Error, AchType>
) => useMutation((body: AchType) => submitAch(accountId, body), options);
