import { Typography } from "@mui/material";
import { SystemProps, TypographyProps } from "@mui/system";
import { theme } from "../../theme";
import { ENTRYPOINT_LINK } from "../../api/constants/entryPointLink";
import getREMFromPX from "../../utils/getREMFromPX";
import ExternalLink from "../ExternalLink";
import { linkStyles } from "./Styles";
import useGetThemePath from "../../hooks/useGetThemePath";

const EntryPointLink = ({ ...props }: TypographyProps & SystemProps) => {
  const themePath = useGetThemePath();
  return (
    <Typography
      fontWeight={theme.fonts.weights.bolder}
      fontSize={getREMFromPX(theme.spacing * 3.5)}
      color={theme[themePath].colors.textPrimary.secondary}
      {...props}
    >
      Powered By
      <ExternalLink
        target="_blank"
        rel="noopener noreferrer"
        style={linkStyles}
        size="medium"
        href={ENTRYPOINT_LINK}
      >
        Entry Point Networks
      </ExternalLink>
    </Typography>
  );
};

export default EntryPointLink;
