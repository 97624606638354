import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export enum LinkSize {
  small = "small",
  medium = "medium",
  large = "large",
}

const fontSizes = {
  small: getREMFromPX(theme.spacing * 3),
  medium: getREMFromPX(theme.spacing * 3.5),
  large: getREMFromPX(theme.spacing * 4),
};

const fontWeights = {
  small: theme.fonts.weights.mediumBold,
  medium: theme.fonts.weights.bolder,
  large: theme.fonts.weights.bolder,
};

export const getLinkStyle = (
  size: keyof typeof LinkSize,
  themePath = "default",
  disabled?: boolean
) => `
  &:hover {
    color: ${
      disabled
        ? theme[themePath].colors.textPrimary.disabled
        : theme[themePath].colors.primary[700]
    };
  }
  font-size: ${fontSizes[size]};
  color: ${
    disabled
      ? theme[themePath].colors.textPrimary.disabled
      : theme[themePath].colors.primary[500]
  };
  font-weight: ${fontWeights[size]};
  text-decoration: none;
  cursor: pointer;
  `;
