import Box, { BoxProps } from "@mui/material/Box";
import { getBoxStyles } from "./Styles";

const StyledBox = ({
  hasBoxShadow,
  ...props
}: BoxProps & {
  hasBoxShadow?: boolean;
}) => <Box sx={getBoxStyles(hasBoxShadow)} {...props} />;

export default StyledBox;
