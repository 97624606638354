const createFormData = (
  object: Object & { [key: string]: any },
  form?: FormData,
  namespace?: string
): FormData => {
  const formData = form || new FormData();
  for (let property in object) {
    if (!object.hasOwnProperty(property) || !object[property]) {
      continue;
    }
    const formKey = namespace ? `${namespace}[${property}]` : property;
    if (
      typeof object[property] === "object" &&
      !(object[property] instanceof File)
    ) {
      createFormData(object[property], formData, formKey);
    } else {
      formData.append(formKey, object[property]);
    }
  }
  return formData;
};
export default createFormData;
