import CardWrapper from "../../../components/NewCardWrapper";
import getREMFromPX from "../../../utils/getREMFromPX";
import { Grid, Box, Stack } from "@mui/material";
import EditPassword from "./EditPassword";
import EditEmail from "./EditEmail";
import EditSecurityQuestions from "./EditSecurityQuestions";

const SecurityDetails = () => {
  const divider = (
    <Box
      sx={{
        height: getREMFromPX(1),
        width: "100%",
        backgroundColor: "rgba(126, 130, 129, 0.45)",
        margin: "30px 0px",
      }}
    />
  );

  return (
    <Grid item xs={12} sm={12} md={6} lg={6}>
      <CardWrapper
        title="Password & Security"
        sx={{ minHeight: getREMFromPX(167), padding: "10px 20px" }}
      >
        <Stack sx={{ padding: "30px 0px" }}>
          <EditEmail />
          {divider}
          <EditPassword />
          {divider}
          <EditSecurityQuestions />
        </Stack>
      </CardWrapper>
    </Grid>
  );
};
export default SecurityDetails;
