import { useMutation, UseMutationOptions } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import fetcher from "../utils/fetchWrapper";

type ReturnType = {
  message?: string;
  code?: string;
  duration: number;
  data?: any;
};

const markNotificationReadStatus = async (messageId: string) => {
  if (!messageId) {
    return;
  }

  const body = {
    read_status: "READ",
  };

  const response = await fetcher(`${endpoints.message}/${messageId}`, {
    method: "PUT",
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const useMarkNotificationReadStatus = (
  messageId: string,
  options?: UseMutationOptions<ReturnType, Error, any>
) => useMutation(() => markNotificationReadStatus(messageId), options);
