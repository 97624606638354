import React, { useState, useMemo } from "react";
import NavContainer from "../../components/NewNavContainer";
import CardWrapper from "../../components/NewCardWrapper";
import { Grid, Typography, Stack, styled } from "@mui/material";
import { theme } from "../../theme";
import { BillingContext, BillingType } from "../../components/BillingProvider";
import PaymentHistory from "../../components/NewPaymentHistory/PaymentHistory";
import PaymentMethodCard from "../../components/NewPaymentMethodCard/PaymentMethodCard";
import Button from "../../components/NewButton";
import { WarningOctagonIcon } from "../../assets/icons";
import MyPlan from "./components/my-plan";
import formatNumber from "../../utils/formatNumber";
import getREMFromPX from "../../utils/getREMFromPX";

const MyPlanFooter = styled(Typography)`
  font-size: 2.25rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #000;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  margin-right: 34px;
`;

const PendingStatus = () => {
  const { setOpenModal } = React.useContext(BillingContext) as BillingType;
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{ flex: 1, paddingTop: getREMFromPX(20) }}
    >
      <Stack flex={1} alignItems="center" pt={getREMFromPX(10)}>
        <WarningOctagonIcon />
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: getREMFromPX(20),
            fontWeight: "600",
            fontStyle: "normal",
            textAlign: "center",
            color: theme["new"].colors.formGreyDark,
          }}
        >
          Verify Your Account
        </Typography>
      </Stack>
      <Stack sx={{ flex: 1.5, justifyContent: "space-between" }}>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: getREMFromPX(12),
            fontWeight: "500",
            fontStyle: "normal",
            lineHeight: getREMFromPX(30),
            letterSpacing: 0,
            textAlign: "left",
            paddingBottom: getREMFromPX(10),
            color: theme["new"].colors.formGreyDark,
          }}
        >
          To verify your account, please update your ACH Payment by confirming
          the two small deposits made to your account. This process may take 1-2
          days. If you require immediate service, we suggest using a credit card
          payment.
        </Typography>
        <Button
          mode="black"
          sx={{ maxWidth: "262px", width: "100%", alignSelf: "flex-end" }}
          text="Verify Payment"
          onClick={() => setOpenModal(true)}
        />
      </Stack>
    </Stack>
  );
};

const Billing = () => {
  const [totalPlanAmount, setTotalPlanAmount] = useState<number | undefined>(
    undefined
  );
  const { paymentMethod, setOpenModal, verificationRequired, paymentHistory } =
    React.useContext(BillingContext) as BillingType;

  const status = useMemo(() => {
    if (verificationRequired) {
      return "Pending";
    } else {
      return "Paid";
    }
  }, [verificationRequired]);
  const doubleWide = { item: true, xs: 12, sm: 12, lg: 6 };

  const planStatus = paymentHistory.length
    ? paymentHistory[0].status
    : "Overdue";

  return (
    <NavContainer title="Billing">
      <Grid container spacing={2} sx={{ width: "100%" }}>
        <Grid {...doubleWide}>
          <CardWrapper title="My Plan" status={planStatus}>
            <MyPlan
              status={planStatus}
              onTotal={(total: number | undefined) => setTotalPlanAmount(total)}
            />
          </CardWrapper>
        </Grid>
        <Grid {...doubleWide}>
          <CardWrapper
            title="Payment Method"
            status={status}
            isPaymentMethodCard
            actions={
              <Typography
                sx={{
                  color:
                    status === "Paid"
                      ? theme["new"].colors.teal
                      : theme["new"].colors.black,
                  fontFamily: "Montserrat",
                  fontSize: getREMFromPX(16),
                  fontWeight: "bold",
                  cursor: "pointer",
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    borderRadius: "50%",
                  },
                }}
                onClick={() => setOpenModal(true)}
              >
                Edit
              </Typography>
            }
          >
            {status === "Pending" ? (
              <PendingStatus />
            ) : (
              <PaymentMethodCard
                paymentMethod={paymentMethod}
                status={status}
              />
            )}
          </CardWrapper>
        </Grid>
        <Grid item xs={12} lg={12}>
          <CardWrapper title="Payment History" hasOverflow>
            <PaymentHistory data={paymentHistory} />
          </CardWrapper>
        </Grid>
      </Grid>
    </NavContainer>
  );
};

export default Billing;
