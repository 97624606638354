import { styled } from "@mui/system";
import useGetThemePath from "../../hooks/useGetThemePath";
import CityIcon from "../CityIcon";
import { getHeaderStyles, headerLogoImageStyles } from "./Styles";

const StyledHeader = styled("header")(() => {
  const themePath = useGetThemePath();
  return `
  ${getHeaderStyles(themePath)}
`;
});

const Header = ({
  children,
  ...props
}: React.HtmlHTMLAttributes<HTMLElement>) => (
  <StyledHeader {...props}>
    <CityIcon style={headerLogoImageStyles} />
    {children}
  </StyledHeader>
);
export default Header;
