import React, { useContext, useRef, useState } from "react";
import { Stack, Typography, Box, styled } from "@mui/material";
import getREMFromPX from "../../utils/getREMFromPX";
import { theme } from "../../theme";
import Label from "../NewLabel";
import { useForm } from "react-hook-form";
import Input from "../Input";
import { BillingContext, BillingType } from "../../components/BillingProvider";
import { useSubmitMicrodeposit } from "../../hooks/useSubmitMicrodeposit";
import Button from "../NewButton";
import sendErrorToast from "../../utils/sendErrorToast";
import { useUpdateAccount } from "../../hooks/useUpdateAccount";
import { useUpdateUser } from "../../hooks/useUpdateUser";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import MicrodepositCodeExample from "../../assets/microdeposit-code-example.png";

type MicrodepositFormType = {
  amount1?: string;
  amount2?: string;
  descriptorCode?: string;
};

interface MicrodepositProps {
  children?: React.ReactNode;
  onSubmit?: Function;
  onSuccess?: Function;
  onCancel: Function;
  setIsValid?: (isValid: boolean) => void;
  setIsLoading?: (isLoading: boolean) => void;
  accountId: string;
}

const DigitInput = styled("input")`
  width: 64px;
  height: 80px;
  flex-grow: 0;
  margin: 0 8px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(201, 218, 216, 0.6);
  border: solid 1px var(--minimal-grey);
  background-color: var(--white);
  font-size: 36px;
`;

const Microdeposit = ({
  onSuccess,
  onCancel,
  setIsLoading,
  accountId,
}: MicrodepositProps) => {
  const {
    refetch: refreshErrors,
    microdepositType,
    microdepositDate,
  } = useContext(BillingContext) as BillingType;

  const { userInfo, currentAccount, setUserInfo } = React.useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const [errorMessage, setErrorMessage] = useState("");

  const [digitValues, setDigitValues] = useState(["", "", "", ""]);
  const handleDigitChange = (index: number, value: string) => {
    if (value.length <= 1) {
      const newDigitValues = [...digitValues];
      newDigitValues[index] = value;
      setDigitValues(newDigitValues);
      setValue("descriptorCode", "SM" + newDigitValues.join(""));
    }
    if (value !== "") {
      const nextIndex = index + 1;
      if (
        nextIndex < inputRefs.current.length &&
        inputRefs.current[nextIndex]
      ) {
        inputRefs.current[nextIndex]?.focus();
      }
    }
  };
  const concatenatedCode = "SM" + digitValues.join("");
  const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(6).fill(null));

  const { mutateAsync: updateUser } = useUpdateUser(
    userInfo?.data?.user?.id ?? "",
    {
      onError: () => sendErrorToast("There was an error activating the user."),
    }
  );

  const { mutateAsync: updateAccount } = useUpdateAccount(currentAccount?.id, {
    onError: () => sendErrorToast("There was an error activating the account."),
  });

  const { mutateAsync: submitMicrodeposit, isLoading } = useSubmitMicrodeposit(
    accountId,
    {
      onError: (error) => setErrorMessage(error.message),
      onSuccess: async (response) => {
        if (response.message) {
          // microdeposits weren't verified, display error message
          setErrorMessage(response.message);
        } else {
          // notify parent if verification is required
          const isRequired = response.data?.billing?.ach_verification_required;
          onSuccess?.(isRequired);
          if (userInfo?.data?.user?.status === "PENDING" && !isRequired) {
            // User and Account need to be set active because payment was accepted
            updateUser({
              status: "ACTIVE",
            });
            updateAccount({
              status: "ACTIVE",
            });
            setUserInfo((info: any) => ({
              ...info,
              data: {
                ...info?.data,
                user: {
                  ...info?.data?.user,
                  status: "ACTIVE",
                },
              },
            }));
          }
          setTimeout(async () => {
            // wait about 30 seconds for stripe to return any processing errors, then refetch to check for errors
            await refreshErrors();
          }, 30000);
        }
      },
    }
  );

  const {
    watch,
    register,
    setValue,
    formState: { errors },
  } = useForm<MicrodepositFormType>({
    mode: "onChange",
    defaultValues: {},
  });

  const amount1 = watch("amount1");
  const amount2 = watch("amount2");
  const descriptorCode = watch("descriptorCode");

  const onSubmitHandler = async () => {
    setIsLoading?.(true);
    const body = descriptorCode
      ? {
          descriptor_code: descriptorCode,
        }
      : {
          amounts: [amount1 ?? "", amount2 ?? ""],
        };
    submitMicrodeposit(body);
  };

  const { colors } = theme["new"];

  return (
    <Stack>
      <Stack
        spacing={getREMFromPX(theme.spacing * 3)}
        mt={getREMFromPX(theme.spacing * 3)}
        alignItems="flex-start"
        width="600px"
      >
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: getREMFromPX(20),
            fontWeight: "500",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "left",
            color: colors.darkGreenFontColor,
            marginBottom: getREMFromPX(16),
          }}
        >
          Micro Deposit Verification
        </Typography>
        {microdepositType === "amounts" ? (
          <Stack>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: getREMFromPX(12),
                fontWeight: "500",
                textAlign: "left",
                color: colors.errorRed,
                marginBottom: getREMFromPX(32),
              }}
            >
              {errorMessage ? (
                errorMessage
              ) : (
                <>
                  To verify your account, please update your ACH Payment by
                  confirming the two small deposits made to your account. This
                  process may take 1-2 days. If you require immediate service,
                  we suggest using a credit card payment.
                </>
              )}
            </Typography>
            <Label htmlFor="amount1">First Micro-deposit</Label>
            <Input
              data-testid="amount1"
              placeholder="$."
              {...register("amount1", {
                required: true,
                pattern: { value: /^\d{1,2}$/, message: "Invalid amount" },
              })}
              error={!!errors.amount1 && !!amount1?.length}
              style={{ marginBottom: "20px" }}
            />
            <Label htmlFor="amount2">Second Micro-deposit</Label>
            <Input
              data-testid="amount2"
              placeholder="$."
              {...register("amount2", {
                required: true,
                pattern: { value: /^\d{1,2}$/, message: "invalid amount" },
              })}
              error={!!errors.amount2 && !!amount2?.length}
            />
          </Stack>
        ) : (
          <Stack>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: getREMFromPX(12),
                fontWeight: "500",
                textAlign: "left",
                color: colors.errorRed,
                marginBottom: getREMFromPX(32),
              }}
            >
              {errorMessage ? (
                errorMessage
              ) : (
                <>
                  To verify your account, please enter the 6-digit code from the
                  micro-deposit made into your account. See example below of
                  highlighted code. This deposit is expected to arrive in your
                  account on {microdepositDate}. If you require immediate
                  service, we suggest using a credit card payment.
                </>
              )}
            </Typography>
            <img
              src={MicrodepositCodeExample}
              alt="account-statement-example"
              width="100%"
            />
            <p></p>
            <Label
              htmlFor="descriptorCode"
              style={{ textAlign: "center", marginTop: "3rem" }}
            >
              Enter code
            </Label>
            <div style={{ textAlign: "center" }}>
              <DigitInput value="S" disabled />
              <DigitInput value="M" disabled />
              {digitValues.map((digit, index) => (
                <DigitInput
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  type="text"
                  value={digit}
                  onChange={(e) => handleDigitChange(index, e.target.value)}
                />
              ))}
            </div>
          </Stack>
        )}
      </Stack>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "52px",
        }}
      >
        <Typography
          sx={{
            cursor: "pointer",
            fontFamily: "Montserrat",
            fontSize: getREMFromPX(20),
            fontWeight: 600,
            color: colors.teal,
            textDecoration: "underline",
          }}
          onClick={() => onCancel()}
        >
          Back
        </Typography>
        <Button
          text="Confirm"
          mode="default"
          data-testid="submit_button"
          sx={{ padding: "16px 67px" }}
          onClick={() => onSubmitHandler()}
          disabled={concatenatedCode.length !== 6 || isLoading}
          isLoading={isLoading}
        />
      </Box>
    </Stack>
  );
};

export default Microdeposit;
