export const theme: {
  [key: string]: any;
} = {
  spacing: 4,
  /* Note: fontFamily should NOT be edited unless the new font is installed first */
  fontFamily: "Nunito Sans, Roboto, sans-serif",
  fonts: {
    weights: {
      normal: 400,
      bold: 500,
      mediumBold: 600,
      bolder: 700,
    },
  },
  backgrounds: {
    main: "#F6F6F6",
    widget: "#FFFFFF",
    table: "#FAFAFA",
    primarySelection: "#F8FCF9",
    secondarySelection: "#F7FAFD",
    tertiarySelection: "#e6e6e6",
  },
  boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.04), inset 0px 0px 1px #BABABF",
  new: {
    colors: {
      formGrey: "#6d6d6d",
      subtitleGrey: "#12373f8a",
      darkGreen: "#253331",
      formGreyDark: "#434343",
      yellow: "#fce8b6",
      lightGreen: "#d7f1da",
      deepBlack: "#434343",
      minimalGrey: "#e8e8e8",
      green: "#488b5b",
      darkGreenFontColor: "#253331",
      grey: "#c5c5c5",
      errorRed: "#ec0000",
      white: "#fff",
      brightGreen: "#84e88e",
      lightGrey: "#efefef",
      lightestGreen: "#fbfffe",
      pink: "#ffd2d2",
      mediumGreen: "#02575c",
      notificationOrange: "#ff7a00",
      teal: "#55857e",
      softGreen: "#f0fbf2",
      dustyGreen: "#c5dcc8",
      softGrey: "#f0f0f0",
      mediumGrey: "#a9a9a9",
      darkRed: "#d80d0d",
      darkOrange: "#dd6a00",
      starYellow: "#f9cb28",
      lightWarningStatus: "#F84D04",
      strongWarningStatus: "#E53934",
      okStatus: "#4CAF81",
      dark: "#000000",
      red: "red",
      externalLink: "#0299FD",
      finalStepperFormIconColor: "#04A7F8",
      lightBorder: "#E4E7E5",
      hoverColor: "#f9f9f9",
      primary: {
        900: "#1E3E26",
        700: "#2E613B",
        500: "#3F8450",
        300: "#97CEA5",
        50: "#EEF7F0",
      },
      secondary: {
        900: "#0F2034",
        700: "#1A395B",
        500: "#255183",
        300: "#88B0DD",
        50: "#EBF2F9",
      },
      textPrimary: {
        primary: "#303632",
        secondary: "#616B64",
        placeholder: "#79867D",
        disabled: "#AFB6B1",
      },
      textSecondary: {
        primary: "#FFFFFF",
        secondary: "#F2F3F2",
        placeholder: "#AFB6B1",
        disabled: "#79867D",
      },
    },
    gradients: {
      greenMenuGradient: "linear-gradient(180deg, #cdefd4 0%, #7ab8b0 87%)",
    },
    boxShadows: {
      boxShadowBR: "8px 8px 10px -1px rgba(0,0,0,0.20)",
    },
  },
  default: {
    colors: {
      lightWarningStatus: "#F84D04",
      strongWarningStatus: "#E53934",
      okStatus: "#4CAF81",
      white: "#FFFFFF",
      red: "red",
      externalLink: "#0299FD",
      finalStepperFormIconColor: "#04A7F8",
      lightBorder: "#E4E7E5",
      primary: {
        900: "#1E3E26",
        700: "#2E613B",
        500: "#3F8450",
        300: "#97CEA5",
        50: "#EEF7F0",
      },
      secondary: {
        900: "#0F2034",
        700: "#1A395B",
        500: "#255183",
        300: "#88B0DD",
        50: "#EBF2F9",
      },
      textPrimary: {
        primary: "#303632",
        secondary: "#616B64",
        placeholder: "#79867D",
        disabled: "#AFB6B1",
      },
      textSecondary: {
        primary: "#FFFFFF",
        secondary: "#F2F3F2",
        placeholder: "#AFB6B1",
        disabled: "#79867D",
      },
    },
  },
  wraps: "wrap !important",
};
