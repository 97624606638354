import { useMutation, UseMutationOptions } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { SingleUser } from "../api/interfaces/User";
import fetcher from "../utils/fetchWrapper";

interface PasswordType {
  newPassword: string;
  confirmPassword: string;
}

const changePassword = async (body: PasswordType, userId: string) => {
  const response = await fetcher(`${endpoints.updateUser}/${userId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ password: body.newPassword }),
  });

  if (!response.ok) {
    throw new Error();
  }

  return await response.json();
};

export const useChangePassword = (
  userId: string,
  options?: UseMutationOptions<SingleUser, Error, PasswordType>
) => {
  return useMutation((body: PasswordType) => changePassword(body, userId), {
    ...options,
    onSuccess: async (
      data: SingleUser,
      variables: PasswordType,
      context: unknown
    ) => {
      options?.onSuccess?.(data, variables, context);
    },
  });
};
