import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import fetcher from "../utils/fetchWrapper";

const getPorts = async (userId: string, accountId: string) => {
  const loading =
    "Serviceplan.Service.Provider.Contacts,Serviceplan.Service.Servicetype,Portinterface.Device.Physicallocation";

  const response = await fetcher(
    `${endpoints.account}/${accountId}/subscription?loading=${loading}`
  );

  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const useGetMySubscriptions = (
  userId: string,
  accountId: string,
  options?: UseQueryOptions<any, Error, any, Array<string>>
): UseQueryResult<any, Error> =>
  useQuery(
    ["subscriptions", userId, accountId],
    () => getPorts(userId, accountId),
    options
  );
