import { Typography } from "@mui/material";
import { theme } from "../../theme";

const ConfirmPasswordValidation = ({
  confirmPassword,
  passwordsMatch,
}: {
  confirmPassword: string;
  passwordsMatch: boolean;
}) => {
  const style = {
    fontFamily: "Inter",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "400",
  };

  const getMessage = () => {
    if (!confirmPassword) {
      return (
        <Typography sx={{ ...style, color: theme.new.colors.formGreyDark }}>
          Value must match new password
        </Typography>
      );
    } else if (!passwordsMatch) {
      return (
        <Typography
          sx={{ ...style, color: theme.new.colors.lightWarningStatus }}
        >
          These passwords do not match
        </Typography>
      );
    } else {
      return (
        <Typography sx={{ ...style, color: theme.new.colors.green }}>
          Passwords match!
        </Typography>
      );
    }
  };

  return (
    <>
      <label
        style={{
          width: 0,
          height: 0,
          position: "absolute",
          opacity: 0,
        }}
        htmlFor="confirmPasswordRequirements"
      >
        Confirm Password Requirements
      </label>
      {getMessage()}
    </>
  );
};

export default ConfirmPasswordValidation;
