import { Stack, Typography } from "@mui/material";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

const NoResults = () => {
  const themePath = useGetThemePath();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      padding={getREMFromPX(theme.spacing * 8)}
      bgcolor={theme[themePath].colors.textSecondary.secondary}
    >
      <Typography
        color={theme[themePath].colors.textPrimary.secondary}
        fontSize={getREMFromPX(theme.spacing * 4.5)}
        fontWeight={theme.fonts.weights.mediumBold}
      >
        No result to show
      </Typography>
      <Typography
        marginTop={getREMFromPX(theme.spacing * 2)}
        color={theme[themePath].colors.textPrimary.secondary}
        fontSize={getREMFromPX(theme.spacing * 4)}
      >
        Try choosing different filters
      </Typography>
    </Stack>
  );
};
export default NoResults;
