import { useMutation, UseMutationOptions } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import fetcher from "../utils/fetchWrapper";

type ReturnType = {
  message?: string;
  code?: string;
  duration: number;
  data?: any;
};

export type CreditCardType = {
  name?: string;
  cc_num?: string;
  cc_exp?: string;
  cc_exp_month?: Number;
  cc_exp_year?: Number;
  cc_cvv?: string;
  cc_cvc?: string;
  cc_zip?: string;
  items: string[];
  is_default_payment_method: boolean;
};

const submitCreditCard = async (accountId: string, body: CreditCardType) => {
  const res = await fetcher(
    `${endpoints.account}/${accountId}/billing/creditcard`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );

  return await res.json();
};

export const useSubmitCreditCard = (
  accountId: string,
  options?: UseMutationOptions<ReturnType, Error, CreditCardType>
) =>
  useMutation(
    (body: CreditCardType) => submitCreditCard(accountId, body),
    options
  );
