import { useMutation, UseMutationOptions } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { AccountQueryList } from "../api/interfaces/Account";
import fetcher from "../utils/fetchWrapper";

interface LinkAccountDeviceBody {
  accountId: string;
  deviceId: string;
}

const linkAccountDevice = async (body: LinkAccountDeviceBody) => {
  const response = await fetcher(`${endpoints.account}/${body.accountId}/device/${body.deviceId}/link`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      account_id: body.accountId,
      device_id: body.deviceId,
    }),
  });

  if (!response.ok) {
    if (response.status === 409) {
      const account = await response.json();
      return {
        ...account,
        message: "account already exists",
      };
    }
    throw new Error();
  }

  return await response.json();
};

export const useLinkAccountDevice = (
  options?: UseMutationOptions<AccountQueryList, Error, LinkAccountDeviceBody>
) => useMutation((body: LinkAccountDeviceBody) => linkAccountDevice(body), options);
