import { KeyboardEvent, useState } from "react";

import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import { SortFilterLabel } from "./interfaces";
import { theme } from "../../theme";
import { Box, ClickAwayListener, Typography } from "@mui/material";

import FiltersMenu, { menuOptions } from "./FiltersMenu";
import SearchIcon from "@mui/icons-material/Search";

import SelectButton from "../../components/SelectButton/SelectButton";
import sendErrorToast from "../../utils/sendErrorToast";
import fetcher from "../../utils/fetchWrapper";

const StyledInput = styled("input")`
  width: 260px;
  height: 37px;
  padding: 11px;
  border-radius: 23.9px;
  border: solid 2px #e4edea;
  background-repeat: no-repeat;
  background-position: 10px center;
  font-family: "Montserrat";
  &::placeholder {
    color: #a9a9a9;
  }
  &:hover {
    outline: 2px solid ${theme.new.colors.teal};
  }
  &:focus {
    outline: 2px solid ${theme.new.colors.teal};
  }

  @media (max-width: 1300px) {
    width: 100%;
    marginright: 32px;
  }
`;

export interface TableFilterProps {
  options: Array<any>;
  handleChangeTypeOfView: (val: number) => void;
  setSortFilter: (v: SortFilterLabel | null) => void;
  sortFilter: SortFilterLabel | null;
  handleInputChange?: (val: string) => void;
}

export const TableFilters = ({
  options,
  handleChangeTypeOfView,
  setSortFilter,
  sortFilter,
  handleInputChange = () => {},
}: TableFilterProps) => {
  const navigate = useNavigate();
  const [showFiltersMenu, setShowFiltersMenu] = useState(false);
  const [accessCode, setAccessCode] = useState("");

  const submit = async () => {
    const loading = "Servicetype,Provider.Contacts,Plans";
    const response = await fetcher(
      `${process.env.REACT_APP_BE_URL}/service/private/${accessCode}?loading=${loading}`
    );
    if (!response.ok) {
      sendErrorToast("There was an error fetching the private service");
    }
    const parsed = await response.json();
    const service = parsed?.data?.service;

    if (service?.servicetype_id) {
      navigate(`/services/${service.servicetype_id}/plans`, { state: service });
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      submit();
    }
  };

  const [sortByOptions, setSortByOptions] = useState(menuOptions);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap="10px"
      >
        <Box sx={{ position: "relative" }}>
          <SearchIcon
            style={{
              position: "absolute",
              left: "6px",
              top: "7px",
              color: theme.new.colors.teal,
            }}
          />
          <StyledInput
            style={{ marginRight: "28px", paddingLeft: "30px" }}
            onChange={(ev) => handleInputChange(ev.target.value)}
            data-cy="services-search-input"
          />
        </Box>
        <ClickAwayListener onClickAway={() => setShowFiltersMenu(false)}>
          <Box height="36px" width="133px">
            <SelectButton
              content="Sort by"
              mode="default"
              onClick={() => setShowFiltersMenu(!showFiltersMenu)}
              dataCy="services-select-sortby"
            />
            {!!showFiltersMenu && (
              <FiltersMenu
                setSortFilter={(arg) => {
                  setShowFiltersMenu(false);
                  setSortFilter(arg);
                }}
                sortFilter={sortFilter}
                sortByOptions={sortByOptions}
                setSortByOptions={(opts) => setSortByOptions(opts)}
              />
            )}
          </Box>
        </ClickAwayListener>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <StyledInput
          placeholder="Private Code"
          id="privateAccess"
          onChange={(e) => setAccessCode(e.target.value)}
          value={accessCode}
          onKeyDown={handleKeyDown}
          data-cy="services-input-private-code"
        />
        <Typography marginLeft="24px">View By:</Typography>
        {options.map(({ icon, active }, idx) => (
          <Box
            sx={{
              backgroundColor: active ? "#d7f1da" : "",
              marginRight: idx === 0 ? "19px" : "",
              marginLeft: idx === 0 ? "8px" : "",
              borderRadius: "30px",
              padding: "5px",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: theme.new.colors.minimalGrey,
              },
            }}
            key={idx}
            onClick={() => handleChangeTypeOfView(idx)}
          >
            {icon}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
