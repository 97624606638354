import defaultImg from "../../assets/EPLogoColor.svg";

const CityIcon = ({ ...props }: React.ImgHTMLAttributes<HTMLImageElement>) => {
  const handleError = (e: any) => {
    if (!e.target.src.endsWith(defaultImg)) {
      e.target.src = defaultImg;
    }
  };

  return (
    <img
      {...props}
      src={`${process.env.PUBLIC_URL}/assets/provider/${process.env.REACT_APP_GUID}.png`}
      alt="city_logo"
      onError={handleError}
    />
  );
};

export default CityIcon;
