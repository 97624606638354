import { theme } from "../../../../../theme";
import getREMFromPX from "../../../../../utils/getREMFromPX";

export const firstCol = {
  width: getREMFromPX(theme.spacing * 10),
  padding: `0 ${getREMFromPX(theme.spacing * 4)}`,
};

export const portCol = {
  width: getREMFromPX(theme.spacing * 12),
};

export const activeServiceCol = {
  width: getREMFromPX(theme.spacing * 32),
};

export const linkStateCol = {
  flex: 1,
};
