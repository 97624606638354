import { Checkbox, CheckboxProps } from "@mui/material";
import React from "react";
import useGetThemePath from "../../hooks/useGetThemePath";
import { getCheckboxStyles } from "./Styles";

const StyledCheckbox = (
  props: CheckboxProps,
  ref: React.ForwardedRef<HTMLInputElement>
) => {
  const themePath = useGetThemePath();
  return (
    <Checkbox
      sx={getCheckboxStyles(themePath)}
      inputRef={ref}
      {...props}
      color="secondary"
    />
  );
};

const CheckboxWithRef = React.forwardRef(StyledCheckbox);
export default CheckboxWithRef;
