import { Stack, Grid, colors } from "@mui/material";

import EmptyMyServicesMessage from "./EmptyMyServicesMessage";
import CardWrapper from "../NewCardWrapper";
import { ForwardArrowIcon } from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import { routes } from "../../App";
import MyServicesTile from "./MyServicesTile";
import getREMFromPX from "../../utils/getREMFromPX";

export interface CurrentSubscription {
  serviceType: string;
  serviceName: string;
  serviceLogo: string;
  devicePort: string;
  deviceName: string;
}

interface MyServicesCardProps {
  subscriptions: Array<CurrentSubscription>;
}

const MyServicesCard = ({ subscriptions }: MyServicesCardProps) => {
  const navigate = useNavigate();
  const arrowAction = (
    <ForwardArrowIcon
      onClick={() =>
        navigate({
          pathname: routes.services.path,
          search: "?tab=My Services",
        })
      }
      style={{ cursor: "pointer" }}
      data-cy="dashboard-my-services"
    />
  );

  return (
    <Grid item xl={12}>
      <CardWrapper
        sx={{
          bgcolor: colors.lightGreen,
          height: getREMFromPX(393),
          overflowY: "auto",
        }}
        title="My Services"
        actions={arrowAction}
      >
        <Stack spacing="1rem" width="100%" sx={{ mt: "1rem" }}>
          {subscriptions?.length > 0 ? (
            subscriptions?.map((subscription: any, index: number) => (
              <MyServicesTile
                key={`${index}-${subscription.id}`}
                subscription={subscription}
              />
            ))
          ) : (
            <EmptyMyServicesMessage />
          )}
        </Stack>
      </CardWrapper>
    </Grid>
  );
};

export default MyServicesCard;
