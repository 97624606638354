import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { SingleUser } from "../api/interfaces/User";
import fetcher from "../utils/fetchWrapper";

const getPorts = async (userId: string, accountId: string) => {
  const loading = 
    "Accounts.Devices.Physicallocation," +
    "Accounts.Devices.Portinterfaces.Subscriptions.Serviceplan.Service.Servicetype," +
    "Accounts.Devices.Physicallocation," +
    "Accounts.Devices.Portinterfaces.Subscriptions.Serviceplan.Service.Plans," +
    "Accounts.Devices.Portinterfaces.Subscriptions.Serviceplan.Service.Provider.Contacts," +
    "Accounts.Devices.Xrefaccountdevice" +
    "&filter=" +
      "Accounts.Devices.Portinterfaces.Subscriptions.status EQ ACTIVE::" + 
      "Accounts.Devices.Portinterfaces.type EQ LAN::" +
      `Accounts.Devices.Portinterfaces.Subscriptions.user_id EQ ${userId}::` + 
      `Accounts.Devices.Portinterfaces.Subscriptions.account_id EQ ${accountId}::` + 
      `Accounts.Devices.Xrefaccountdevice.account_guid EQ ${accountId}` +
    `&order=Accounts.Devices.Portinterfaces.display_name`; 
  
  const response = await fetcher(
    `${endpoints.getPorts}/${userId}?loading=${loading}`
  );

  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const useGetPorts = (
  userId: string,
  accountId: string,
  options?: UseQueryOptions<SingleUser, Error, SingleUser, Array<string>>
): UseQueryResult<SingleUser, Error> =>
  useQuery(
    ["ports", userId, accountId],
    () => getPorts(userId, accountId),
    options
  );
