import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { UnreadNotficationCountQuery } from "../api/interfaces/Notification";
import fetcher from "../utils/fetchWrapper";

const getUnreadMessageCount = async (userId: string) => {
  if (!userId) {
    return;
  }

  const response = await fetcher(
    `${endpoints.getUser}/${userId}/messageportaluser/unreadmessagecount`
  );

  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const useGetUnreadMessageCount = (
  userId: string,
  options?: UseQueryOptions<
    UnreadNotficationCountQuery,
    Error,
    UnreadNotficationCountQuery,
    Array<string>
  >
): UseQueryResult<UnreadNotficationCountQuery, Error> =>
  useQuery(
    ["unreadCount", userId],
    () => getUnreadMessageCount(userId),
    options
  );
