export const convertToDecimalIfNeeded = (val: number): string => {
  if (Number.isInteger(val) && val !== 0) {
    return `${val}.0`;
  } else if (!Number.isInteger(val)) {
    if (val.toString().length > 2) {
      return val.toString().substring(0, 3);
    }
    return val.toString();
  }
  return "0";
};
