import React from "react";
import { Box, Typography, styled, Stack } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import NewButton from "../../../components/NewButton";
import { theme } from "../../../theme";
import { Plan } from "../utils/intefaces/Plan";
import { convertToDecimalIfNeeded } from "../utils/convertToDecimal";
import { OverviewDataModel } from "../../../components/AllServices/AllServices";
import { ContactInfo } from "../../../api/interfaces/User/ContactInfo";
import { FieldIcon } from "../utils/intefaces/FieldIcon";
import getREMFromPX from "../../../utils/getREMFromPX";

export const LogoContainer = styled("div")<{
  width: string;
  height: string;
  isCoverImage?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  margin: auto;
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: ${(p) =>
      p.isCoverImage
        ? "cover"
        : "contain"}; /* Set to "contain" for fitting height */
  }
`;

const Speed = ({
  field,
  speed,
  label,
}: {
  field: string;
  speed: string;
  label: string;
}) => {
  return (
    <Box
      sx={{
        backgroundColor: theme.new.colors.minimalGrey,
        borderRadius: "4px",
        padding: "2px 4px",
        margin: "0 4px",
      }}
    >
      <Typography sx={{ fontSize: getREMFromPX(12), fontWeight: "700" }}>
        {field}:{" "}
        <span style={{ fontWeight: "600" }}>
          {speed}
          {label}
        </span>
      </Typography>
    </Box>
  );
};

const CardService = ({
  plan,
  setOpenSubscribeModal,
  setModalOverviewData,
  setOpenWorkflowSubscriptionModal,
  support_url,
  subscription_redirect_url,
  Contacts,
  dataCy,
}: {
  setOpenSubscribeModal: () => void;
  setOpenWorkflowSubscriptionModal: () => void;
  plan: Plan;
  setModalOverviewData: (param: OverviewDataModel) => void;
  Contacts?: Partial<Array<ContactInfo>>;
  support_url?: string;
  subscription_redirect_url?: string;
  dataCy: string;
}) => {
  const providerInfo = Contacts?.find(
    (c: any) =>
      c.status === "ACTIVE" && (c.type === "SUPPORT" || c.type === "SALES")
  );

  const starsReviewAverage = convertToDecimalIfNeeded(
    Number(plan.ratings_sum) / Number(plan.total_reviews)
  );

  const setModalData = (
    plan: Plan,
    providerInfo?: ContactInfo,
    support_url?: string,
    subscription_redirect_url?: string
  ) => {
    setModalOverviewData({
      fields: {
        Down: plan.fields?.Down as string,
        LinkSpeed: plan.fields?.LinkSpeed as number,
        Name: plan.fields?.Name as string,
        Price: plan.fields?.Price as string,
        Up: plan.fields?.Up as string,
      },
      description: plan.description as string,
      providerName: plan.providerName as string,
      name: plan.name as string,
      planName: plan.planName as string,
      service_id: plan.service_id,
      provider_id: plan.providerId as string,
      planId: plan.id as string,
      ratings_sum: plan.ratings_sum as string,
      total_reviews: plan.total_reviews as string,
      email: plan.Contacts[0]?.email,
      phone: plan.Contacts[0]?.phone,
      support_url: plan.Contacts[0]?.support_url || plan.support_url,
      subscription_redirect_url: plan.subscription_redirect_url,
      fields_icons: plan.fields_icons as Array<FieldIcon>,
    });
  };

  return (
    <Box
      sx={{
        width: "268px",
        borderRadius: "16px",
        border: `2px solid ${theme.new.colors.minimalGrey}`,
        boxShadow: "0 4px 10px 0 rgba(201, 218, 216, 0.6)",
        position: "relative",
        "&:hover": {
          boxShadow: "0 0 17px 0 rgba(126, 130, 129, 0.39)",
          shadowOpacity: 1,
        },
        cursor: "pointer",
      }}
      onClick={() => {
        setModalData(
          plan,
          providerInfo,
          support_url,
          subscription_redirect_url
        );
        setOpenSubscribeModal();
      }}
      data-cy={dataCy}
    >
      <Box
        sx={{
          borderTopLeftRadius: "14px",
          borderTopRightRadius: "14px",
          display: "flex",
          height: "40px",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme.new.colors.minimalGrey,
        }}
      >
        <Typography
          color={theme.new.colors.darkGreenFontColor}
          fontSize={14}
          fontFamily="Montserrat"
          fontWeight="bold"
          textAlign="center"
          textTransform="uppercase"
        >
          {plan.name}
        </Typography>
      </Box>
      <Stack sx={{ padding: "8px 20px 32px 20px" }}>
        <LogoContainer width="180" height="60">
          <img
            src={`${process.env.PUBLIC_URL}/assets/provider/${plan.providerId}.png`}
            alt=""
            style={{ width: "120%" }}
          />
        </LogoContainer>
        <Typography
          color={theme.new.colors.darkGreenFontColor}
          fontSize={getREMFromPX(32)}
          fontFamily="Montserrat"
          fontWeight={600}
          textAlign="center"
        >
          ${plan.fields?.Price}
        </Typography>
        <Typography
          color={theme.new.colors.darkGreenFontColor}
          fontSize={getREMFromPX(14)}
          fontFamily="Montserrat"
          fontWeight={700}
          textAlign="center"
          height={38}
          lineHeight="normal"
        >
          {plan.planName}
        </Typography>
        <Stack
          flexDirection="row"
          sx={{ justifyContent: "center", marginBottom: "4px", height: "20px" }}
        >
          {!!plan?.fields?.Up && (
            <Speed field="UP" speed={plan.fields?.Up} label="mb" />
          )}
          {!!plan?.fields?.Down && (
            <Speed field="DOWN" speed={plan.fields?.Down} label="mb" />
          )}
        </Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {Number(plan.total_reviews) === 0 ? (
            <Typography
              fontFamily="Montserrat"
              fontSize={getREMFromPX(12)}
              color={theme.new.colors.darkGreen}
            >
              No reviews
            </Typography>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <StarIcon
                sx={{ color: "#f9cb28", fontSize: "28px", marginRight: "8px" }}
              />
              <Typography
                color={theme.new.colors.darkGreenFontColor}
                fontSize={16}
                fontFamily="Montserrat"
                fontWeight={500}
              >
                {starsReviewAverage}
              </Typography>
            </Box>
          )}
          <NewButton
            mode="default"
            text="Subscribe"
            onClick={() => {
              setModalData(
                plan,
                providerInfo,
                support_url,
                subscription_redirect_url
              );
              setOpenWorkflowSubscriptionModal();
            }}
            sx={{
              width: "120px",
              height: "40px",
              fontSize: "12px",
              borderRadius: "30px",
              marginLeft: "16px",
            }}
            data-cy={`service-plan-card-${
              plan.providerName
                ? plan.providerName.toLowerCase().replaceAll(" ", "-") + "-"
                : ""
            }${
              plan.planName
                ? plan.planName.toLowerCase().replaceAll(" ", "-")
                : ""
            }`}
            dataCy={`${dataCy}-subscribe`}
          />
        </Stack>
      </Stack>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: "50%",
          transform: "translate(-50%, 50%)",
          padding: "6px 24px",
          borderRadius: 15,
          background: "white",
          border: "2px solid #E8E8E8",
        }}
      >
        <Typography
          sx={{ fontSize: getREMFromPX(14), fontFamily: "Montserrat" }}
        >
          More Info
        </Typography>
      </Box>
    </Box>
  );
};

export default CardService;
