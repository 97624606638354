import { Stack, Box, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import Input from "../../../../components/Input";
import Label from "../../../../components/Label";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { Step1Type } from "../../ForgotPassword";
import useViewport from "../../../../hooks/useViewport";
import NewButton from "../../../../components/NewButton";
import EntryPointLink from "../../../../components/NewEntryPointLink/EntryPointLink";
import { userNameInputStyle } from "./Styles";

interface Step1Props {
  changeStep: (args: Step1Type) => void;
  previousValues: {
    step1: Step1Type;
  };
  goBackOneStep: () => void;
  error?: string;
}

const Step1 = ({
  changeStep,
  previousValues,
  goBackOneStep,
  error,
}: Step1Props) => {
  const { colors } = theme.new;
  const { isMobile } = useViewport(600);
  const { watch, register, handleSubmit } = useForm<Step1Type>({
    defaultValues: {
      userName: previousValues.step1.userName,
    },
  });

  const userName = watch("userName").trim();

  const disableSubmitButton = !userName;

  const onSubmit = () => {
    const selectedOption = {
      userName,
    };
    changeStep(selectedOption);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        sx={{
          flex: 1,
          width: "100%",
          maxWidth: 460,
          padding: "0 30px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            textAlign: "left",
            width: "100%",
            fontFamily: "Montserrat",
            fontSize: isMobile ? getREMFromPX(14) : getREMFromPX(20),
            fontWeight: 600,
            marginBottom: isMobile ? "8px" : "16px",
            color: colors.textPrimary.primary,
          }}
        >
          Forgot Password
        </Typography>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: isMobile ? getREMFromPX(10) : getREMFromPX(16),
            fontWeight: 500,
            lineHeight: isMobile ? 1.5 : 1.56,
            marginBottom: isMobile ? "24px" : "36px",
            textAlign: "left",
          }}
        >
          To recover your password, please enter your username or email address
          below
        </Typography>
        <Stack
          sx={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack sx={{ width: "100%" }}>
            <Stack style={{ width: "100%" }}>
              <Label
                htmlFor="username"
                style={{
                  fontFamily: "Montserrat",
                  fontSize: isMobile ? getREMFromPX(12) : getREMFromPX(16),
                  fontWeight: "500",
                  textAlign: "left",
                  marginBottom: "8px",
                  color: colors.formGreyDark,
                }}
              >
                Username or Email
              </Label>
              <Input
                id="username"
                style={userNameInputStyle}
                data-testid="usernameInput"
                {...register("userName")}
              />
            </Stack>
            {error && (
              <Typography
                sx={{
                  marginTop: "8px",
                  fontFamily: "Inter",
                  fontSize: isMobile ? getREMFromPX(8) : getREMFromPX(11),
                  fontWeight: "400",
                  textAlign: "left",
                  color: theme.new.colors.errorRed,
                }}
              >
                {error}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Box sx={{ width: "100%", marginTop: isMobile ? "16px" : "10px" }}>
          <>
            <NewButton
              text="Next"
              mode="default"
              type="submit"
              disabled={disableSubmitButton}
              sx={{
                width: "100%",
                mt: 10,
                marginBottom: "30px",
                borderRadius: `${isMobile ? "9.3px" : "15px"}`,
              }}
            />
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: isMobile ? getREMFromPX(10) : getREMFromPX(14),
                fontWeight: "600",
                textAlign: "center",
                color: "#497c76",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={goBackOneStep}
            >
              Login
            </Typography>
          </>
        </Box>
        <div style={{ marginTop: isMobile ? "16px" : "30px" }}>
          <EntryPointLink />
        </div>
      </Stack>
    </form>
  );
};
export default Step1;
