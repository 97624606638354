import { routes } from "../App";
import history from "../utils/history";
import Cookies from "js-cookie";

function updateOptions(options: RequestInit) {
  const existingToken = localStorage.getItem("token");
  const update = { ...options };
  update.headers = {
    Application: process.env.REACT_APP_APPLICATION_NAME ?? "",
    Authorization: existingToken ? `Bearer ${existingToken}` : "", // Anonymous authorization header has been removed
    ...update.headers,
  };

  return update;
}

export default async function fetcher(url: string, options?: RequestInit) {
  const { pathname } = window.location;
  const response = await fetch(url, updateOptions(options || {}));
  if (response.status === 409) {
    const copiedResponse = response.clone();
    const body = await copiedResponse.json();
    if (
      body.message === "Invalid token" ||
      body.message === "No key parameter found."
    ) {
      localStorage.removeItem("token");
      Cookies.remove("jwt");
      history.push(routes.login.path, {
        formerLocation: pathname,
      });
      throw Error(body.message);
    }
  }
  return response;
}
