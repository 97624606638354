import Select, { SelectProps } from "@mui/material/Select";
import React from "react";
import useGetThemePath from "../../hooks/useGetThemePath";
import SelectOption from "../SelectOption";
import { getPlaceholderStyle, getSelectStyles } from "./Styles";

const StyledSelect = (
  { children, placeholder, ...props }: SelectProps,
  ref: React.ForwardedRef<HTMLInputElement>
) => {
  const themePath = useGetThemePath();
  return (
    <Select
      sx={getSelectStyles(themePath)}
      fullWidth
      displayEmpty
      inputRef={ref}
      {...props}
    >
      <SelectOption value="">
        <span style={getPlaceholderStyle(themePath)}>
          {placeholder || "Select"}
        </span>
      </SelectOption>
      {children}
    </Select>
  );
};
const SelectWithRef = React.forwardRef(StyledSelect);
export default SelectWithRef;
