import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { ServiceList } from "../api/interfaces/Service";
import fetcher from "../utils/fetchWrapper";

const getServices = async () => {
  const response = await fetcher(`${endpoints.getServices}?loading=Services.Provider&filter=Services.status EQ ACTIVE::display eq true::Services.display_type eq PUBLIC::Services.Provider.status EQ ACTIVE`);

  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const useGetServices = (
  options?: UseQueryOptions<ServiceList, Error, ServiceList, Array<string>>
): UseQueryResult<ServiceList, Error> =>
  useQuery(["services"], getServices, options);
