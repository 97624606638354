import { Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import getREMFromPX from "../../../../utils/getREMFromPX";

interface StepProps {
  label: string;
  icon: any;
  isActive: boolean;
  isCompleted: boolean;
  customAlignment?: boolean;
}

const Step = ({ icon, isActive, isCompleted }: StepProps) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: `${
            !!isCompleted ? "#d7f1da" : isActive ? "#02575C" : "#c5c5c5"
          }`,
          color: `${isActive ? "#fff" : "#000"}`,
          fill: "#fff",
          width: "40px",
          height: "40px",
          fontSize: getREMFromPX(14), // Adjust the font size here to resize the icon
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "4rem",
        }}
      >
        {!isCompleted ? icon : <CheckIcon />}
      </Box>
    </>
  );
};

export default Step;
