import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import fetcher from "../utils/fetchWrapper";

export interface BillingError {
  code: string;
  created_at: string;
  decline_code: string;
  message: string;
}

export interface BillingPrices {
  cc_initial_processing_fee: {
    active: boolean;
    amount: number;
    currency: string;
    description: string;
    tax_behavior: string;
    type: string;
  };
  cc_recurring_processing_fee: {
    active: boolean;
    amount: number;
    currency: string;
    description: string;
    tax_behavior: string;
    type: string;
    recurring: {
      interval: string;
      interval_count: number;
    };
  };
  utility_fee: {
    active: boolean;
    amount: number;
    currency: string;
    description: string;
    tax_behavior: string;
    type: string;
    recurring: {
      interval: string;
      interval_count: number;
    };
  };
}

interface BillingPricesData {
  data: {
    billing: {
      prices: BillingPrices;
    };
  };
  duration: number;
  messages: [];
}

const getBillingPrices = async () => {
  const response = await fetcher(
    `${process.env.REACT_APP_BE_URL}/billing/prices`
  );

  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const useGetBillingPrices = (
  options?: UseQueryOptions<
    BillingPricesData,
    Error,
    BillingPricesData,
    Array<string>
  >
): UseQueryResult<BillingPricesData, Error> =>
  useQuery(["billingPrices"], () => getBillingPrices(), options);
