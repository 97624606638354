import * as React from "react";
import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import { styled } from "@mui/system";
import {
  getDefaultBorderAndOutlineStyles,
  InputSizeEnum,
  getInputStyles,
} from "./Styles";
import getREMFromPX from "../../utils/getREMFromPX";
import { theme } from "../../theme";
import useGetThemePath from "../../hooks/useGetThemePath";
import useViewport from "../../hooks/useViewport";

export interface InputProps extends InputBaseProps {
  ownerState?: InputBaseProps & {
    error: boolean;
    softError?: boolean;
  };
}

const StyledBigSizeInputElement = styled(InputBase)((props: InputProps) => {
  const themePath = useGetThemePath();
  return getInputStyles(themePath).concat(
    `padding: ${getREMFromPX(theme.spacing * 4)} ${getREMFromPX(
      theme.spacing * 6
    )};
`.concat(getDefaultBorderAndOutlineStyles(props, themePath))
  );
});

const StyledMediumSizeInputElement = styled(InputBase)((props: InputProps) => {
  const themePath = useGetThemePath();
  const { isMobile } = useViewport();
  return getInputStyles(themePath)
    .concat(
      `padding: ${getREMFromPX(
        theme.spacing * (isMobile ? 3 : 4)
      )} ${getREMFromPX(theme.spacing * (isMobile ? 3 : 4))};
      ${isMobile ? "font-size: 12px;" : ""};
      border-radius: 15px;
    `
    )
    .concat(getDefaultBorderAndOutlineStyles(props, themePath));
});

const decideInputSize = (size?: keyof typeof InputSizeEnum) => {
  if (size === InputSizeEnum.medium) {
    const components = {
      Input: StyledMediumSizeInputElement,
    };
    return components;
  }
  const components = {
    Input: StyledBigSizeInputElement,
  };
  return components;
};

const CustomStyledInput = (
  props: InputProps & {
    size?: keyof typeof InputSizeEnum;
    softError?: boolean;
  },
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const Input = decideInputSize(props.size || InputSizeEnum.medium);
  return <InputBase components={Input} {...props} ref={ref} />;
};

const CustomInput = React.forwardRef(CustomStyledInput);

export default CustomInput;
