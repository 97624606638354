import { useMutation, UseMutationOptions } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { SingleUser } from "../api/interfaces/User";
import fetcher from "../utils/fetchWrapper";

interface Username {
  email: string;
}

const updateUsername = async (body: Username, userId: string, contactId: string) => {

  const userResponse = await fetcher(
    `${endpoints.updateUser}/${userId}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username: body.email }),
    }
  );

  const contactResponse = await fetcher(
    `${endpoints.updateContact}/${contactId}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );

  if (!userResponse.ok || !contactResponse.ok) {
    throw new Error();
  }

  const res = await userResponse.json();

  return res;
};

export const useUpdateUsername = (
  userId: string,
  contactId: string,
  options?: UseMutationOptions<SingleUser, Error, Username>
) => {
  return useMutation((body: Username) => updateUsername(body, userId, contactId), {
    ...options,
    onSuccess: async (
      data: SingleUser,
      variables: Username,
      context: unknown
    ) => {
      options?.onSuccess?.(data, variables, context);
    },
  });
};
