import { useCallback, useContext, useState } from "react";
import { Box, Card, Typography } from "@mui/material";
import { theme } from "../../theme";
import NewButton from "../NewButton";
import { useDeleteSubscription } from "../../hooks/useDeleteSubscription";
import sendSuccessToast from "../../utils/sendSuccessToast";
import useGetUserId from "../../hooks/useGetUserId";
import { useQueryClient } from "react-query";
import sendErrorToast from "../../utils/sendErrorToast";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../AddressAndAccountProvider";
import UnsubscribePopup from "../../routes/Services/components/UnsubscribePopup";
import EmptyMyServicesMessage from "../../routes/Services/components/EmptyMyServicesMessage";
import useViewport from "../../hooks/useViewport";
import MySubscriptionTableRow from "../../routes/Services/components/MySubscriptionTableRow";
import { useIsPortLive } from "../../hooks/useIsPortLive";

const MyServicesRow = ({
  port,
  deviceId,
  currentIndex,
  unsubscribeId,
  dataCy,
}: {
  port: any;
  deviceId: string;
  currentIndex: number;
  unsubscribeId: string;
  dataCy?: string;
}) => {
  const { currentAccount, userInfo } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState("");
  const queryClient = useQueryClient();
  const userId = useGetUserId();
  const { isMobile } = useViewport();
  const { mutate: deleteSubscription, isLoading: isDeleting } =
    useDeleteSubscription({
      onSuccess: () => {
        setSelectedSubscriptionId("");
        queryClient.invalidateQueries(["ports", userId, currentAccount.id]);
        sendSuccessToast("Subscription deleted successfully!");
      },
      onError: () =>
        sendErrorToast(
          "There was a problem deleting the subscription, please try again"
        ),
    });

  const { data: livePort } = useIsPortLive(deviceId, port?.id);

  const portWithLive = {
    ...port,
    ...livePort,
  } as any;

  const toggleModal = useCallback(
    (id = "") => {
      if (isDeleting) {
        return;
      }
      setSelectedSubscriptionId((oldId) => (oldId ? "" : id));
    },
    [isDeleting]
  );

  const review = userInfo?.data?.user?.Review?.find((r) => {
    // find the review that matches the subscription, current account, and has an active status
    return (
      r.Subscriptionhistory?.Serviceplan?.id ===
        portWithLive?.servicePlan?.id &&
      r.Subscriptionhistory.account_id === currentAccount.id &&
      r.status === "ACTIVE"
    );
  });

  const historyId = userInfo?.data?.user?.Subscriptionhistory?.find((h) => {
    return h.serviceplan_id === portWithLive?.servicePlan?.id;
  });

  return (
    <Box data-cy={dataCy}>
      {isMobile ? (
        <Card
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "8px 0",
            alignItems: "center",
            padding: "15px 12px",
            gap: "8px",
            boxShadow: "0 4px 10px 0 rgba(201, 218, 216, 0.6)",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <img
                width="78px"
                height="23px"
                src={`${process.env.PUBLIC_URL}/assets/provider/${portWithLive.providerId}.png`}
                alt=""
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  margin: "8px auto 0px",
                }}
              >
                <Typography
                  fontFamily="Montserrat"
                  fontSize="10px"
                  fontWeight="500"
                  color={theme.new.colors.darkGreenFontColor}
                  data-cy={`my-services-row-${currentIndex}-port`}
                >
                  {portWithLive.display_name}
                </Typography>
                <Box
                  sx={{
                    color: "#84e88e",
                    background: "#84e88e",
                    width: "6.6px",
                    height: "6.6px",
                    borderRadius: "10px",
                    border: "1px solid #84e88e",
                    marginLeft: "4px",
                  }}
                >
                  &nbsp;
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography
              fontFamily="Montserrat"
              fontSize="8px"
              fontWeight="600"
              color={theme.new.colors.mediumGrey}
              marginBottom="6px"
              data-cy={`my-services-row-${currentIndex}-provider`}
            >
              {portWithLive?.servicePlan?.Service?.Servicetype?.name}
            </Typography>
            <Typography
              fontFamily="Montserrat"
              fontSize="8px"
              fontWeight="500"
              marginBottom="8px"
              color={theme.new.colors.darkGreenFontColor}
              data-cy={`my-services-row-${currentIndex}-plan`}
            >
              {portWithLive.servicePlan.name}
            </Typography>
            <Typography
              fontFamily="Montserrat"
              fontSize="8px"
              fontWeight="600"
              color={theme.new.colors.darkGreenFontColor}
              data-cy={`my-services-row-${currentIndex}-price`}
            >
              ${portWithLive.servicePlan.fields.Price}
            </Typography>
            <Typography
              fontFamily="Montserrat"
              fontSize="8px"
              fontWeight="bold"
              color={theme.new.colors.darkGreenFontColor}
              sx={{ textDecoration: "underline" }}
              marginTop="6px"
              data-cy={`my-services-row-${currentIndex}-add-review`}
            >
              Add Review
            </Typography>
          </Box>
          <Box>
            <NewButton
              text="Unsubscribe"
              mode="default"
              sx={{
                width: "72px",
                height: "23px",
                fontSize: "9px",
                fontWeight: 600,
                backgroundColor: "#ff7a00",
                color: "#fff",
              }}
              onClick={(e) => {
                setSelectedSubscriptionId(unsubscribeId ?? "");
                e.stopPropagation();
              }}
              data-cy={`my-services-row-${currentIndex}-unsubscribe`}
            />
          </Box>
        </Card>
      ) : (
        <>
          {portWithLive.display_name && portWithLive.servicePlan.name ? (
            <>
              <MySubscriptionTableRow
                providerId={portWithLive.providerId}
                port={portWithLive.display_name}
                serviceType={
                  portWithLive?.servicePlan?.Service?.Servicetype?.name
                }
                planName={portWithLive.servicePlan.name}
                unsubscribeId={unsubscribeId}
                currentIndex={currentIndex}
                price={portWithLive.servicePlan.fields.Price}
                total_reviews={portWithLive.servicePlan.total_reviews}
                ratings_sum={portWithLive.servicePlan.ratings_sum}
                review={review}
                historyId={
                  review?.subscriptionhistory_id ?? historyId?.id ?? ""
                }
                dataCy={dataCy}
              />
            </>
          ) : (
            <EmptyMyServicesMessage />
          )}
        </>
      )}
      <UnsubscribePopup
        open={!!selectedSubscriptionId}
        onClickSubscribe={() => deleteSubscription(unsubscribeId)}
        onClose={() => toggleModal()}
        isLoading={isDeleting}
        name={portWithLive?.servicePlan?.Service?.Servicetype?.name}
        price={portWithLive.servicePlan.fields.Price}
        selectedId={portWithLive.providerId}
      />
    </Box>
  );
};

export default MyServicesRow;
