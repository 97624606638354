import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import fetcher from "../utils/fetchWrapper";

interface ServicePlan {
  Serviceplan: {
    fields: {
      Price: string;
    };
  };
}

interface MyPlanEstimatedFees {
  data: {
    subscription: ServicePlan[];
  };
  duration: number;
  messages: [];
}

const getMyPlanEstimatedFees = async (accountId: string) => {
  if (!accountId) {
    return null;
  }

  // Estimated fees
  const response = await fetcher(
    `${endpoints.account}/${accountId}/subscription?loading=Serviceplan`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const useGetEstimatedFees = (
  accountId: string,
  options?: UseQueryOptions<
    MyPlanEstimatedFees,
    Error,
    MyPlanEstimatedFees,
    Array<string>
  >
): UseQueryResult<MyPlanEstimatedFees, Error> =>
  useQuery(
    ["myPlanEstimatedFees", accountId],
    () => getMyPlanEstimatedFees(accountId),
    options
  );
