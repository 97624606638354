import { useMutation, UseMutationOptions } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import fetcher from "../utils/fetchWrapper";

const deleteReview = async (id: string, accountId: string) => {
  const response = await fetcher(
    `${endpoints.account}/${accountId}/review/${id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  if (!response.ok) {
    throw new Error();
  }
  return true;
};

export const useDeleteReview = (
  accountId: string,
  options?: UseMutationOptions<boolean, Error, string>
) => useMutation((id: string) => deleteReview(id, accountId), options);
