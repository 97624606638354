import { theme } from "../../../../../../theme";
import getREMFromPX from "../../../../../../utils/getREMFromPX";

export const checkBoxStyles = {
  width: getREMFromPX(theme.spacing * 6),
  height: getREMFromPX(theme.spacing * 6),
};

export const dividerStyles = {
  height: getREMFromPX(theme.spacing * 5),
  alignSelf: "center",
};
