import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const providerBoxStyles = {
  maxWidth: getREMFromPX(theme.spacing * 70.5),
  borderRadius: "8px",
  backgroundColor: theme.backgrounds.widget,
  boxShadow: theme.boxShadow,
};

export const dividerStyle = {
  marginTop: `${getREMFromPX(theme.spacing * 4)}!important`,
  width: "100%",
};

export const logoStyles = {
  width: "100%",
  marginBottom: getREMFromPX(theme.spacing * 2),
  objectFit: "cover",
} as React.CSSProperties;
