import { Divider, Stack, Typography } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import Button from "../Button";
import { dividerStyle, providerBoxStyles, logoStyles } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@mui/material/Tooltip";
import Rating from "../Rating";
import { useGetConfig } from "../../hooks/useGetConfig";

const ProviderBox = ({
  name,
  onClickPlans,
  tooltipDesc,
  rating,
  id,
  ...props
}: BoxProps & {
  name: string;
  rating: number;
  id: string;
  tooltipDesc: string;
  onClickPlans?: () => void;
}) => {
  const themePath = useGetThemePath();

  const { data: config } = useGetConfig();
  const showReviews = config?.data?.config?.enabled_features?.USER_REVIEWS?.enabled;

  return (
    <Box sx={providerBoxStyles} {...props}>
      <Stack
        spacing={getREMFromPX(theme.spacing * 2)}
        padding={getREMFromPX(theme.spacing * 6)}
        alignItems="center"
        justifyContent="center"
      >
        <img
          style={logoStyles}
          alt={name}
          src={`${process.env.PUBLIC_URL}/assets/provider/${id}.png`}
        />
        {showReviews && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography
              color={theme[themePath].colors.textPrimary.secondary}
              fontSize={getREMFromPX(theme.spacing * 3)}
            >
              Avg Rating
            </Typography>
            <Rating
              value={Number(rating)}
              individual
              readOnly
              data-testid={`provider_box_test_id_${rating}`}
            />
          </Stack>
        )}
        <Divider sx={dividerStyle} />
        <Stack
          width="100%"
          flexDirection="row"
          alignItems="center"
          mt={`${getREMFromPX(theme.spacing * 4)} !important`}
          justifyContent="space-between"
        >
          <Tooltip title={tooltipDesc} enterTouchDelay={0} arrow>
            <Stack gap={getREMFromPX(theme.spacing * 2)} flexDirection="row">
              <FontAwesomeIcon icon="circle-info" />
              <Typography
                color={theme[themePath].colors.textPrimary.secondary}
                fontSize={getREMFromPX(theme.spacing * 3)}
              >
                Info
              </Typography>
            </Stack>
          </Tooltip>
          <Button variant="outlined" text="View Plans" onClick={onClickPlans} />
        </Stack>
      </Stack>
    </Box>
  );
};
export default ProviderBox;
