import { Stack, Step, StepLabel } from "@mui/material";
import { useCallback, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Plan } from "../../api/interfaces/Plan";
import { Port } from "../../api/interfaces/Port";
import { ServiceInfo } from "../../api/interfaces/ServiceInfo";
import { routes } from "../../App";
import Box from "../../components/Box";
import FinalStepScreen from "../../components/FinalStepScreen";
import Stepper from "../../components/Stepper";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import sendErrorToast from "../../utils/sendErrorToast";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import { stepperContainerStyle } from "./Styles";
import fetcher from "../../utils/fetchWrapper";
import useViewport from "../../hooks/useViewport";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import { useLocalStorage } from "../../hooks/useLocalStorage";

interface IObjectKeys {
  [key: number]: JSX.Element;
}

const steps = [...Array(3).keys()];

export type Step2Type = {
  checked: boolean;
};

export type Step1Type = {
  port: Port | null;
};

export type MappedProviderWithPlan = {
  provider: ServiceInfo;
  plan: Plan;
};

export type Step3Type = {
  selectedPlan: MappedProviderWithPlan | null;
};

const SubscribeToISP = () => {
  const { getItemFromStorage } = useLocalStorage();

  const serviceId = getItemFromStorage("serviceId");
  const planId = getItemFromStorage("planId");

  const { isMobile } = useViewport();
  const [services, setServices] = useState<ServiceInfo[]>();

  const { selectedAccountId, selectedDeviceId, userInfo } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const account = userInfo?.data?.user?.Accounts?.find(
    (account) => account.id === selectedAccountId
  );
  const device = account?.Devices?.find(
    (device) => device.id === selectedDeviceId
  );
  const resetDelay = Number(device?.reset_delay) ?? 5;

  useEffect(() => {
    const fetchService = async () => {
      const loading =
        "Servicetype," +
        "Provider.Contacts," +
        "Plans" +
        "&filter=" +
        "status eq ACTIVE::" +
        "Provider.status eq ACTIVE::" +
        "Plans.status eq ACTIVE";

      const response = await fetcher(
        `${process.env.REACT_APP_BE_URL}/servicetype/${serviceId}/service?loading=${loading}`
      );

      if (!response.ok) {
        sendErrorToast(
          "There was an error getting the Internet Services, please try again"
        );
      }

      const r = await response.json();
      setServices(r?.data?.service);
      return r;
    };

    fetchService();
  }, [serviceId, planId]);

  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState<{
    step1: Step1Type;
    step2: Step2Type;
    step3: Step3Type;
  }>({
    step1: {
      port: null,
    },
    step2: {
      checked: false,
    },
    step3: {
      selectedPlan: null,
    },
  });

  const navigate = useNavigate();

  const changeStep = useCallback(
    (incomingValues?: Step1Type | Step2Type) => {
      const newStep = activeStep + 1;
      if (incomingValues) {
        setFormValues((formValues) => ({
          ...formValues,
          [`step${newStep}`]: incomingValues,
        }));
      }
      setActiveStep(newStep);
    },
    [activeStep]
  );

  const goBackOneStep = useCallback(() => {
    if (activeStep === 0) {
      return navigate(-1);
    }
    const newStep = activeStep - 1;

    setActiveStep(newStep);
  }, [activeStep, navigate]);

  const stepsComponents = {
    0: (
      <Step1
        previousValues={formValues}
        changeStep={changeStep}
        goBackOneStep={goBackOneStep}
      />
    ),
    1: (
      <Step2
        changeStep={changeStep}
        previousValues={formValues}
        goBackOneStep={goBackOneStep}
      />
    ),
    2: (
      <Step3
        previousValues={formValues}
        goBackOneStep={goBackOneStep}
        changeStep={changeStep}
      />
    ),
  } as IObjectKeys;

  useEffect(() => {
    if (activeStep === steps.length) {
      // add 3 seconds to reset delay for extra processing time
      setTimeout(() => {
        window.location.replace(routes.dashboard.path);
      }, (resetDelay + 3) * 1000);
    }
  }, [activeStep, navigate, formValues.step3.selectedPlan, resetDelay]);

  useEffect(() => {
    if (services) {
      let planMatch: Plan;
      const selectedPlan = services
        .filter((service) =>
          service.Plans.find((plan) => {
            if (plan.id === planId) {
              planMatch = plan;
            }
            return plan.id === planId;
          })
        )
        .map((plan) => ({ provider: plan, plan: planMatch }))[0];
      if (selectedPlan) {
        setFormValues((oldFormValues) => ({
          ...oldFormValues,
          step3: {
            ...oldFormValues.step3,
            selectedPlan,
          },
        }));
        return;
      }
      sendErrorToast("We couldn´t find the requested plan");
    }
  }, [services, planId, serviceId]);

  return (
    <>
      <div style={stepperContainerStyle}>
        <Stepper activeStep={activeStep}>
          {steps.map((step) => (
            <Step key={step}>
              <StepLabel />
            </Step>
          ))}
        </Stepper>
      </div>
      <Stack
        justifyContent="center"
        alignItems="center"
        mt={getREMFromPX(theme.spacing * 6)}
      >
        {activeStep !== steps.length ? (
          <Box
            padding={getREMFromPX(theme.spacing * 6)}
            width={
              isMobile
                ? "-webkit-fill-available"
                : getREMFromPX(theme.spacing * 172.5)
            }
          >
            {stepsComponents[activeStep]}
          </Box>
        ) : (
          <FinalStepScreen />
        )}
      </Stack>
    </>
  );
};

export default SubscribeToISP;
