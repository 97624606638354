import { useMutation, UseMutationOptions } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { UserCreateBody, SingleUser } from "../api/interfaces/User";
import fetcher from "../utils/fetchWrapper";

const createUser = async (body: UserCreateBody) => {
  const response = await fetcher(`${endpoints.createUser}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    if (response.status === 409) {
      const user = await response.json();
      return {
        ...user,
        message: "username already exists",
      };
    }
    throw new Error();
  }
  return await response.json();
};

export const useCreateUser = (
  options?: UseMutationOptions<SingleUser, Error, UserCreateBody>
) => useMutation((body: UserCreateBody) => createUser(body), options);
