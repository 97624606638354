const getProperLabelValue = (BEValue: string, value: string) => {
  if (BEValue === "Data Cap" && value === "None") {
    return "Unlimited";
  } else if (BEValue === "LinkSpeed" && Number(value) === 0) {
    return "Max";
  } else if (BEValue === "Price") {
    return `$${value}`;
  } else if (BEValue === "Up" || BEValue === "Down") {
    return `${value} mb/s`;
  }
  return value;
};
export default getProperLabelValue;
