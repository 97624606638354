import { endpoints } from "../api/constants/endpoints";
import fetcher from "../utils/fetchWrapper";
import { ReviewQueryList } from "../api/interfaces/Review";
import { useMutation, UseMutationOptions } from "react-query";

export type ReviewBody = {
  id?: string;
  comment: string;
  rating: number | null;
  status?: string;
  subscriptionhistory_id?: string;
  user_id: string;
};

type ReviewType = {
  id?: string;
  comment: string;
  rating: number | null;
  subscriptionhistory_id?: string;
}

const createReview = async (review: ReviewType, userId:string) => {
  const body = {
    comment: review.comment,
    rating: review.rating,
    status: "ACTIVE",
    subscriptionhistory_id: review.subscriptionhistory_id,
    user_id: userId,
  }

  const response = await fetcher(`${endpoints.createReview}${review.id ? `/${review.id}` : ""}`,
    {
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: review.id ? "PUT" : "POST",
    }
  );

  if (!response.ok) {
    throw new Error();
  }

  return await response.json();
}

export const useCreateReview = (
  userId: string,
  options?: UseMutationOptions<ReviewQueryList, Error, ReviewType>
) => useMutation((body: ReviewType) => createReview(body, userId), options);
