import { styled } from "@mui/material";
import { ForwardArrowIcon } from "../../assets/icons";
import { theme } from "../../theme";

export const BackStyle = styled("span")`
  cursor: pointer;
  pointer-events: auto;
  color: #55857e;
`;

export const Description = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%; /* Set the desired height for the container */

  color: var(--dark-green-font-color, #253331);
  font-family: Montserrat;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const ImageContainer = styled("div")`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  align-self: flex-end;
  margin-top: 2rem;
`;

export const ContinueIcon = styled(ForwardArrowIcon)`
  pointer-events: auto;
  cursor: pointer;
  z-index: 9;
`;

export const SubDescription = styled("p")`
  width: 100%;
  height: 51px;
  margin: 12px 244.9px 20px 0px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--dark-green-font-color, #253331);
`;

export const SupportInfo = styled("p")`
  margin: 5.7px 0 16px 0px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--dark-green-font-color, #253331);
`;

export const iconStyles = {
  width: "30px",
  height: "30px",
  margin: "0 25.6px 1.3px 0",
  color: "#55857e",
};

export const IconText = styled("span")`
  vertical-align: 10px;
  margin: 3.3px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--dark-green-font-color, #253331);
`;

export const ServiceProviderMultiSubHeader = styled("span")`
  flex-grow: 0;
  margin: 26px 0 0 0;
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--dark-green-font-color, #253331);
`;

export const ServiceProviderMultDesc = styled("span")`
  flex-grow: 0;
  margin: 16px 0 25px 5px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
`;

export const ButtonService = styled("button")`
  width: 150px;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20px 79.3px 0 10px;
  padding: 16px 50px;
  border-radius: 15px;
  background-color: #d7f1da;
  flex-grow: 0;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark-green-font-color, #253331);
  border: none;
  cursor: pointer;
`;

export const HelpCenterCardStyles = {
  cursor: "pointer",
  "&:hover": {
    background: theme.new.colors.hoverColor,
    boxShadow: theme.new.boxShadows.boxShadowBR,
  },
};
