import { Stack } from "@mui/material";
import ValidationMessage from "./ValidationMessage";

const PasswordValidation = ({ password }: { password: string }) => {
  return (
    <>
      <label
        style={{ width: 0, height: 0, position: "absolute", opacity: 0 }}
        htmlFor="passwordRequirements"
      >
        Password Requirements
      </label>
      <Stack id="passwordRequirements">
        <Stack direction="row">
          <ValidationMessage
            valid={password.length >= 6}
            message="6 characters minimum"
          />
          <ValidationMessage
            valid={/[0-9]/.test(password)}
            message="One number"
          />
        </Stack>
        <Stack direction="row">
          <ValidationMessage
            valid={/[A-Z]/.test(password)}
            message="One uppercase letter"
          />
          <ValidationMessage
            valid={/[!@#$%^&*]/.test(password)}
            message="One special character !@#$%^&*"
          />
        </Stack>
      </Stack>
    </>
  );
};

export default PasswordValidation;
