import { useMutation, UseMutationOptions } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import fetcher from "../utils/fetchWrapper";

const deleteSubscription = async (id: string) => {
  const response = await fetcher(`${endpoints.deleteSubscription}/${id}`, {method:"DELETE"});
  if (!response.ok) {
    throw new Error();
  }
  return true;
};

export const useDeleteSubscription = (
  options?: UseMutationOptions<boolean, Error, string>
) => useMutation((id: string) => deleteSubscription(id), options);
