import { Stack, Divider, Typography } from "@mui/material";
import { Port as PortInterface } from "../../../../../../api/interfaces/Port";
import Checkbox from "../../../../../../components/Checkbox";
import useGetThemePath from "../../../../../../hooks/useGetThemePath";
import { theme } from "../../../../../../theme";
import getREMFromPX from "../../../../../../utils/getREMFromPX";
import { checkBoxStyles } from "./Styles";
import { dividerStyles } from "./Styles";
import * as Styles from "../CommonStyles";

const Port = ({
  selected,
  port,
  disabled,
  setSelectedPort,
}: {
  disabled?: boolean;
  port: PortInterface;
  selected: boolean;
  setSelectedPort: React.Dispatch<React.SetStateAction<PortInterface | null>>;
}) => {
  const themePath = useGetThemePath();

  return (
    <Stack
      alignItems="center"
      direction="row"
      gap={getREMFromPX(theme.spacing * 4)}
      bgcolor={!selected ? "None" : theme.backgrounds.primarySelection}
      padding={`${getREMFromPX(theme.spacing * 2)} 0`}
    >
      <Stack {...Styles.firstCol}>
        <Checkbox
          disabled={disabled}
          style={checkBoxStyles}
          checked={selected}
          onChange={() => setSelectedPort(selected ? null : port)}
          sx={{
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
              borderRadius: "50px",
            },
          }}
        />
      </Stack>
      <Divider style={dividerStyles} flexItem orientation="vertical" />
      <Stack {...Styles.portCol}>
        <Typography
          whiteSpace="nowrap"
          color={
            disabled
              ? theme[themePath].colors.textPrimary.disabled
              : theme[themePath].colors.textPrimary.primary
          }
        >
          {port.display_name}
        </Typography>
      </Stack>
      <Divider style={dividerStyles} flexItem orientation="vertical" />
      <Stack {...Styles.activeServiceCol}>
        <Typography
          color={
            disabled
              ? theme[themePath].colors.textPrimary.disabled
              : theme[themePath].colors.textPrimary.primary
          }
        >
          {port.Subscriptions.length === 0
            ? "No Active Service"
            : port.Subscriptions.map(
                (subscription) =>
                  subscription.Serviceplan.Service.Provider?.name
              )}
        </Typography>
      </Stack>
      <Divider style={dividerStyles} flexItem orientation="vertical" />
      <Stack {...Styles.linkStateCol}>
        <Typography
          color={
            disabled
              ? theme[themePath].colors.textPrimary.disabled
              : theme[themePath].colors.textPrimary.primary
          }
        >
          {port?.live?.link_state ?? ""}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Port;
