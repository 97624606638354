import { Stack, Typography } from "@mui/material";
import { theme } from "../../theme";

const Warning = ({ message }: { message: string }) => {
  return (
    <Stack>
      <Typography color={theme.default.colors.strongWarningStatus} fontSize={20}>
        {message}
      </Typography>
    </Stack>
  );
}
export default Warning;
