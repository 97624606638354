import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../App";
import NavContainer from "../../../../components/NewNavContainer";
import CardWrapper from "../../../../components/NewCardWrapper";
import { BackStyle } from "../../Styles";
import {
  Grid,
  Box,
  Typography,
  Paper,
  ClickAwayListener,
  Stack,
} from "@mui/material";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";
import { LocationIcon, RotateIcon } from "../../../../assets/icons";
import {
  VbgTitle,
  Location,
  VbgInfo,
  StreetInfo,
  Router,
  Hardware,
  MyService,
  ViewMyService,
  MyServiceRow,
} from "./Styles";
import { ButtonService } from "../../Styles";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { theme } from "../../../../theme";
import MyDevicePortsRow from "../../components/DeviceGuide/MyDevicePortsRow";
import useViewport from "../../../../hooks/useViewport";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RouterIcon from "@mui/icons-material/Router";
import {
  VenoenDeviceFront,
  VenoenDeviceBack,
  AaeonDeviceFront,
  AaeonDeviceBack,
} from "../../../../assets/device";

import { useIsPortLive } from "../../../../hooks/useIsPortLive";

const DeviceGuide = () => {
  const navigate = useNavigate();

  const backAction = (
    <BackStyle
      onClick={() => {
        navigate(routes.help.path);
      }}
    >
      Back to Help
    </BackStyle>
  );

  const { setCurrentDevice, currentDevice, devices } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const portsWithSubscriptions = currentDevice?.Portinterfaces?.filter(
    (portinterface) =>
      portinterface.display === true &&
      portinterface.type === "LAN" &&
      portinterface.Subscriptions?.length > 0
  );

  const ports = currentDevice?.Portinterfaces;

  const lanOnePorts = ports?.filter(
    (portinterface) =>
      portinterface.display === true &&
      portinterface.type === "LAN" &&
      portinterface.display_name === "LAN 1"
  );

  const lanOnePort = lanOnePorts ? lanOnePorts[0] : null;

  const { data: liveLanOnePort } = useIsPortLive(
    lanOnePort?.device_id ?? "",
    lanOnePort?.id ?? ""
  );

  const lanOnePortWithLive = {
    ...lanOnePort,
    ...liveLanOnePort,
  } as any;

  const lanTwoPorts = ports?.filter(
    (portinterface) =>
      portinterface.display === true &&
      portinterface.type === "LAN" &&
      portinterface.display_name === "LAN 2"
  );

  const lanTwoPort = lanTwoPorts ? lanTwoPorts[0] : null;

  const { data: liveLanTwoPort } = useIsPortLive(
    lanTwoPort?.device_id ?? "",
    lanTwoPort?.id ?? ""
  );

  const lanTwoPortWithLive = {
    ...lanTwoPort,
    ...liveLanTwoPort,
  } as any;

  const lanThreePorts = ports?.filter(
    (portinterface) =>
      portinterface.display === true &&
      portinterface.type === "LAN" &&
      portinterface.display_name === "LAN 3"
  );

  const lanThreePort = lanThreePorts ? lanThreePorts[0] : null;

  const { data: liveLanThreePort } = useIsPortLive(
    lanThreePort?.device_id ?? "",
    lanThreePort?.id ?? ""
  );

  const lanThreePortWithLive = {
    ...lanThreePort,
    ...liveLanThreePort,
  } as any;

  const lanFourPorts = ports?.filter(
    (portinterface) =>
      portinterface.display === true &&
      portinterface.type === "LAN" &&
      portinterface.display_name === "LAN 4"
  );

  const lanFourPort = lanFourPorts ? lanFourPorts[0] : null;

  const { data: liveLanFourPort } = useIsPortLive(
    lanFourPort?.device_id ?? "",
    lanFourPort?.id ?? ""
  );

  const lanFourPortWithLive = {
    ...lanFourPort,
    ...liveLanFourPort,
  } as any;

  const sfpOnePorts = ports?.filter(
    (portinterface) =>
      portinterface.display === true &&
      portinterface.type === "LAN" &&
      portinterface.display_name === "SFP 1"
  );

  const sfpOnePort = sfpOnePorts ? sfpOnePorts[0] : null;

  const { data: liveSfpOnePort } = useIsPortLive(
    sfpOnePort?.device_id ?? "",
    sfpOnePort?.id ?? ""
  );

  const sfpOnePortWithLive = {
    ...sfpOnePort,
    ...liveSfpOnePort,
  } as any;

  const deviceHaveSubscription =
    (portsWithSubscriptions?.length ?? 0) > 0 ? true : false;

  const wanPorts = currentDevice?.Portinterfaces?.filter(
    (portinterface) =>
      portinterface.display === true && portinterface.type === "WAN"
  );

  const wanPort = wanPorts ? wanPorts[0] : null;

  const { data: livePort } = useIsPortLive(
    wanPort?.device_id ?? "",
    wanPort?.id ?? ""
  );

  const wanPortWithLive = {
    ...wanPort,
    ...livePort,
  } as any;

  const wanPortActive =
    wanPortWithLive?.data?.portinterface.live?.link_state === "up"
      ? true
      : false;

  const lanPortsCollection = [
    lanOnePortWithLive,
    lanTwoPortWithLive,
    lanThreePortWithLive,
    lanFourPortWithLive,
    sfpOnePortWithLive,
  ];

  const allPortsCollection = [
    lanOnePortWithLive,
    lanTwoPortWithLive,
    lanThreePortWithLive,
    lanFourPortWithLive,
    sfpOnePortWithLive,
    wanPortWithLive,
  ];

  const statusMessage = wanPortActive
    ? deviceHaveSubscription
      ? "Having trouble with your services? Please contact your service provider."
      : "Your device is connected. Please head to the services page to subscribe to a service."
    : "Your device is not connected. Please troubleshoot your device.";

  const statusHeader = wanPortActive ? "Status:" : "Status Report:";

  const serviceAction = wanPortActive ? (
    <ButtonService
      onClick={() => navigate(routes.services.path)}
      style={{
        pointerEvents: "auto",
        margin: "0",
        width: "187px",
        fontSize: "0.75rem",
        display: "inline-flex",
        height: "38px",
        borderRadius: "10px",
      }}
    >
      More Services
    </ButtonService>
  ) : (
    <ButtonService
      style={{
        pointerEvents: "auto",
        margin: "0",
        width: "187px",
        fontSize: "0.75rem",
        display: "inline-flex",
        height: "38px",
        backgroundColor: "#e8e8e8",
        cursor: "auto",
        borderRadius: "10px",
      }}
    >
      More Services
    </ButtonService>
  );

  const deviceStatus = wanPortActive ? (
    <ButtonService
      style={{
        margin: "0",
        width: "294px",
        fontSize: "0.875rem",
        display: "inline-flex",
        height: "48px",
        backgroundColor: "#84e88e",
        cursor: "auto",
      }}
    >
      Device Connected
    </ButtonService>
  ) : (
    <ButtonService
      style={{
        margin: "0",
        width: "294px",
        fontSize: "0.875rem",
        display: "inline-flex",
        height: "48px",
        backgroundColor: "#e8e8e8",
        cursor: "auto",
      }}
    >
      Device is Disabled
    </ButtonService>
  );

  const subscriptionMessage =
    !deviceHaveSubscription && wanPortActive ? (
      <div>
        <Typography
          color={theme.new.colors.darkGreen}
          fontSize={getREMFromPX(12)}
          fontFamily="Montserrat"
          fontWeight={500}
          textAlign="left"
        >
          You are not currently subscribed to any services. Please click "more
          services" to subscribe.
        </Typography>
      </div>
    ) : (
      ""
    );

  const [openDevicesList, setOpenDevicesList] = useState<boolean>(false);

  const { isMobile } = useViewport();

  const optionsDevices = devices?.map((item: any, i: number) => ({
    label: `Device ${i + 1} - ${item?.Physicallocation.house_number} ${
      item?.Physicallocation.street
    }`,
    value: item?.id,
  })) as Array<{
    label: string;
    value: string;
  }>;

  const findDevice = (deviceId: string) =>
    devices.find((device) => device?.id === deviceId);

  const listWithoutDuplicatedRecords = optionsDevices?.filter(
    (item, index, arr) => {
      const firstIndex = arr.findIndex(
        (element) => element.value === item.value
      );
      return index === firstIndex;
    }
  );

  const [showFront, setShowFront] = useState(true);

  const DeviceFront =
    currentDevice.Product?.Manufacturer?.name === "Aaeon"
      ? AaeonDeviceFront
      : VenoenDeviceFront;
  const DeviceBack =
    currentDevice.Product?.Manufacturer?.name === "Aaeon"
      ? AaeonDeviceBack
      : VenoenDeviceBack;
  const DeviceImage = showFront ? DeviceFront : DeviceBack;

  const colors = theme["new"].colors;

  return (
    <NavContainer title="Help">
      <CardWrapper title="Device Information" actions={backAction}>
        <Grid container overflow={"auto"}>
          <Grid item md={12} lg={6}>
            <VbgTitle>Virtual Broadband Gateway</VbgTitle>
            <VbgInfo>
              <Box
                display="flex"
                alignItems="center"
                onClick={() => setOpenDevicesList(!openDevicesList)}
                sx={{
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <Typography
                  fontSize={isMobile ? getREMFromPX(9.8) : getREMFromPX(16)}
                  fontWeight={500}
                  color="#000"
                  fontFamily="Montserrat"
                  marginRight="5.4px"
                >
                  <Location>
                    <LocationIcon />
                  </Location>
                  <StreetInfo>
                    {`${currentDevice?.Physicallocation.house_number} ${currentDevice?.Physicallocation.street}`}
                  </StreetInfo>
                </Typography>

                {devices?.length > 1 && (
                  <>
                    <ArrowDropDownIcon />
                    {!!openDevicesList && (
                      <ClickAwayListener
                        onClickAway={() => setOpenDevicesList(false)}
                      >
                        <Paper
                          sx={{
                            position: "absolute",
                            zIndex: 10,
                            padding: "16px 8px",
                            width: isMobile ? "200px" : "237px",
                            borderRadius: "10px",
                            boxShadow: "0 4px 25px 0 rgba(0, 0, 0, 0.25)",
                            top: "34px",
                            left: isMobile ? "20px" : "130px",
                          }}
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              fontSize={getREMFromPX(14)}
                              color={theme.new.colors.darkGreen}
                              fontFamily="Montserrat"
                              fontWeight={600}
                            >
                              Switch Device
                            </Typography>
                            {listWithoutDuplicatedRecords.map((val) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                  marginTop: "20px",
                                }}
                                onClick={() => {
                                  const newDevice = findDevice(val.value);
                                  if (newDevice) {
                                    setCurrentDevice(newDevice);
                                  }
                                }}
                                key={val.value}
                              >
                                <RouterIcon />
                                <StreetInfo>{val.label}</StreetInfo>
                              </Box>
                            ))}
                          </Box>
                        </Paper>
                      </ClickAwayListener>
                    )}
                    <Typography
                      color={theme.new.colors.grey}
                      fontSize={isMobile ? getREMFromPX(9.8) : getREMFromPX(16)}
                      marginRight="5.4px"
                    >
                      |
                    </Typography>
                  </>
                )}

                <Typography
                  fontSize={isMobile ? getREMFromPX(9.8) : getREMFromPX(16)}
                  fontWeight={500}
                  color="#000"
                  fontFamily="Montserrat"
                  marginRight="5.4px"
                  marginLeft="20px"
                >
                  <Router>
                    <RouterIcon />
                  </Router>
                  <Hardware>{currentDevice?.hardware_version}</Hardware>
                </Typography>
              </Box>
            </VbgInfo>
            <MyServiceRow>
              <MyService>My Services</MyService>
              <ViewMyService onClick={() => navigate(routes.myServices.path)}>
                View My Services
              </ViewMyService>
            </MyServiceRow>
            <Grid
              container
              style={{ marginLeft: "16px", width: "97%", marginBottom: "35px" }}
            >
              <Grid item>{subscriptionMessage}</Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              alignItems="center"
              sx={{
                backgroundColor: "rgba(217, 217, 217, 0.27)",
              }}
              borderRadius="20px"
              style={{ marginLeft: "16px", width: "97%" }}
            >
              <Grid
                item
                xs={2}
                textAlign="center"
                style={{
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  paddingLeft: "20px",
                }}
              >
                <Typography
                  color={theme.new.colors.formGrey}
                  fontSize={getREMFromPX(10)}
                  fontFamily="Montserrat"
                  fontWeight={500}
                  textAlign="left"
                >
                  Port
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                borderLeft="2px solid white"
                style={{ paddingTop: "4px", paddingBottom: "4px" }}
              >
                <Typography
                  color={theme.new.colors.formGrey}
                  fontSize={getREMFromPX(10)}
                  fontFamily="Montserrat"
                  fontWeight={500}
                  textAlign="left"
                >
                  Service
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ paddingTop: "4px", paddingBottom: "4px" }}
              >
                <Typography
                  color={theme.new.colors.formGrey}
                  fontSize={getREMFromPX(10)}
                  fontFamily="Montserrat"
                  fontWeight={500}
                  textAlign="left"
                ></Typography>
              </Grid>
              <Grid
                item
                xs={2}
                borderLeft="2px solid white"
                style={{ paddingTop: "4px", paddingBottom: "4px" }}
              >
                <Typography
                  color={theme.new.colors.formGrey}
                  fontSize={getREMFromPX(10)}
                  fontFamily="Montserrat"
                  fontWeight={500}
                  textAlign="left"
                >
                  Type
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ paddingTop: "4px", paddingBottom: "4px" }}
                borderLeft="2px solid white"
              >
                <Typography
                  color={theme.new.colors.formGrey}
                  fontSize={getREMFromPX(10)}
                  fontFamily="Montserrat"
                  fontWeight={500}
                  textAlign="left"
                >
                  Connection
                </Typography>
              </Grid>
            </Grid>
            {lanPortsCollection?.map((port) =>
              Object.entries(port).length < 1 ? null : (
                <Grid
                  container
                  style={{
                    marginLeft: "16px",
                    width: "97%",
                    marginTop: "8px",
                    marginBottom: "16px",
                    height: "63px",
                  }}
                  key={port.id}
                >
                  <MyDevicePortsRow portName={port?.display_name} port={port} />
                </Grid>
              )
            )}
            <Grid item style={{ textAlign: "center", marginTop: "24px" }}>
              {serviceAction}
            </Grid>
            <Grid
              item
              style={{
                fontSize: "0.875rem",
                textAlign: "center",
                marginTop: "93.5px",
              }}
            >
              <p>
                Having trouble with your services? Please contact your service
                provider.
              </p>
            </Grid>
          </Grid>
          <Grid item md={12} lg={6} sx={{ zIndex: 2 }}>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: getREMFromPX(12),
                fontWeight: "800",
                fontStyle: "normal",
                letterSpacing: 0,
                textAlign: "right",
                color: colors.teal,
                marginRight: getREMFromPX(3),
                marginBottom: "115px",
                marginTop: "103px",
              }}
            >
              <span
                style={{ cursor: "pointer" }}
                onClick={() => navigate(routes.helpTroubleshoot.path)}
              >
                Troubleshooting
              </span>
            </Typography>
            <div style={{ marginLeft: "60px", textAlign: "center" }}>
              <DeviceImage ports={allPortsCollection} />
              <Stack
                onClick={() => setShowFront(!showFront)}
                sx={{
                  marginTop: getREMFromPX(-30),
                  width: "100%",
                  maxWidth: getREMFromPX(550),
                  paddingRight: getREMFromPX(30),
                  alignSelf: "center",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: getREMFromPX(12),
                    fontWeight: "500",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    textAlign: "left",
                    color: colors.teal,
                    marginRight: getREMFromPX(3),
                  }}
                >
                  {showFront ? "Back" : "Front"}
                </Typography>
                <RotateIcon />
              </Stack>
            </div>
            <div style={{ marginLeft: "60px", marginTop: "135px" }}>
              <CardWrapper actions={""}>
                <Grid
                  container
                  alignItems="center"
                  style={{ marginTop: "-6px" }}
                >
                  <Grid
                    item
                    borderLeft="2px solid white"
                    style={{
                      paddingTop: "3px",
                      paddingBottom: "4px",
                      paddingLeft: "12px",
                    }}
                  >
                    <Typography
                      color={theme.new.colors.darkGreenFontColor}
                      fontSize={getREMFromPX(14)}
                      fontFamily="Montserrat"
                      fontWeight={800}
                      marginBottom="5px"
                    >
                      <>{statusHeader}</>
                    </Typography>
                    <Typography
                      color={theme.new.colors.darkGreenFontColor}
                      fontSize={getREMFromPX(12)}
                      fontFamily="Montserrat"
                      fontWeight={500}
                    >
                      <>{statusMessage}</>
                    </Typography>
                  </Grid>
                </Grid>
              </CardWrapper>
            </div>
            <div style={{ textAlign: "right", marginTop: "48px" }}>
              {deviceStatus}
            </div>
          </Grid>
        </Grid>
      </CardWrapper>
    </NavContainer>
  );
};
export default DeviceGuide;
