import { styled } from "@mui/material";
import getREMFromPX from "../../utils/getREMFromPX";
import { theme } from "../../theme";

export const Table = styled("table")`
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeader = styled("th")`
  text-align: center;
  vertical-align: middle;
  background-color: rgba(217, 217, 217, 0.27);
  height: 24px;
  white-space: nowrap;

  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7e8281;
  padding-left: 1rem;
  padding-right: 1rem;

  &:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding-left: 1.75rem;
  }

  &:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
`;

export const TableRow = styled("tr")`
  & + & {
    border-top: 1px solid #e0e0e0;
  }
  :hover {
    background: ${theme.new.colors.hoverColor};
  }
`;

export const TableHeaderRow = styled("tr")`
  & + & {
    border-top: 1px solid #e0e0e0;
  }
`;

export const TableCellLeft = styled("td")`
  padding: 1rem;
  content-align: center;
  align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: 1px;

  font-family: Montserrat;
  font-size: ${getREMFromPX(14)};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;

  &:first-child {
    padding-left: 14px;
  }
`;

export const TableCell = styled(TableCellLeft)`
  text-align: center;
`;
