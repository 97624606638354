import { Box, Card, Grid, Button, Typography } from "@mui/material";
import getREMFromPX from "../../../../../utils/getREMFromPX";
import { theme } from "../../../../../theme";
import { OverviewDataModel } from "../../../../../components/AllServices/AllServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import useViewport from "../../../../../hooks/useViewport";

const CardContent = ({
  modalOverviewData,
  handleRestart,
}: {
  modalOverviewData: OverviewDataModel;
  handleRestart: Function;
}) => {
  const { isMobile } = useViewport();
  return (
    <Box>
      <Typography
        textAlign="left"
        fontSize={getREMFromPX(isMobile ? 14 : 20)}
        fontFamily="Montserrat"
        color={theme.new.colors.darkGreen}
        fontWeight="bold"
        paddingBottom="19px"
        ml="16px"
        mt="8px"
      >
        Service Confirmation
      </Typography>
      <Card
        sx={{
          justifyContent: "space-between",
          height: isMobile ? "350px" : "224px",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          py: "30px",
          position: "relative",
          boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
          maxWidth: "624px",
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              style={{
                padding: "0 20px ",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/provider/${modalOverviewData.provider_id}.png`}
                  alt=""
                />
              </Box>
              <Box>
                <Typography
                  textAlign="left"
                  fontSize={getREMFromPX(16)}
                  fontFamily="Montserrat"
                  color={theme.new.colors.darkGreen}
                  fontWeight={600}
                >
                  {modalOverviewData.fields.Name}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {isMobile && <hr color="#ddd" style={{ width: "80%" }} />}
          <Grid item xs={12} sm={6}>
            <Grid
              container
              justifyContent="center"
              display="flex"
              columnSpacing={2}
              rowSpacing={1}
              style={{ margin: "20px" }}
            >
              <Grid item xs={6}>
                <Box display="flex" alignItems="center">
                  <FontAwesomeIcon
                    icon={
                      modalOverviewData?.fields_icons?.[1]?.icon as IconProp
                    }
                    style={{
                      marginRight: "8px",
                      color: theme.new.colors.teal,
                    }}
                  />
                  <Typography
                    textAlign="left"
                    fontSize={getREMFromPX(16)}
                    fontFamily="Montserrat"
                    color={theme.new.colors.darkGreen}
                    fontWeight={600}
                  >
                    Link Speed:
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Typography
                    textAlign="left"
                    fontSize={getREMFromPX(16)}
                    fontFamily="Montserrat"
                    pl={2}
                    fontWeight={500}
                    color={theme.new.colors.darkGreen}
                  >
                    {modalOverviewData.fields.LinkSpeed}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box display="flex" alignItems="center">
                  <FontAwesomeIcon
                    icon={
                      modalOverviewData?.fields_icons?.[0]?.icon as IconProp
                    }
                    style={{
                      marginRight: "8px",
                      color: theme.new.colors.teal,
                    }}
                  />
                  <Typography
                    textAlign="left"
                    fontSize={getREMFromPX(16)}
                    fontFamily="Montserrat"
                    fontWeight={600}
                    color={theme.new.colors.darkGreen}
                  >
                    Up:
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Typography
                    textAlign="left"
                    fontSize={getREMFromPX(16)}
                    fontFamily="Montserrat"
                    pl={2}
                    fontWeight={600}
                    color={theme.new.colors.darkGreen}
                  >
                    {modalOverviewData.fields.Up}mb/s
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box display="flex" alignItems="center">
                  <FontAwesomeIcon
                    icon={
                      modalOverviewData?.fields_icons?.[2]?.icon as IconProp
                    }
                    style={{
                      marginRight: "8px",
                      color: theme.new.colors.teal,
                    }}
                  />

                  <Typography
                    textAlign="left"
                    fontSize={getREMFromPX(16)}
                    fontWeight={600}
                    fontFamily="Montserrat"
                    color={theme.new.colors.darkGreen}
                  >
                    Down:
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Typography
                    textAlign="left"
                    fontSize={getREMFromPX(16)}
                    fontFamily="Montserrat"
                    pl={2}
                    fontWeight={600}
                    color={theme.new.colors.darkGreen}
                  >
                    {modalOverviewData.fields.Down}mb/s
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box display="flex" alignItems="center">
                  <FontAwesomeIcon
                    icon={
                      modalOverviewData?.fields_icons?.[4]?.icon as IconProp
                    }
                    style={{
                      marginRight: "12px",
                      color: theme.new.colors.teal,
                    }}
                  />
                  <Typography
                    textAlign="left"
                    fontSize={getREMFromPX(16)}
                    fontWeight={600}
                    fontFamily="Montserrat"
                    color={theme.new.colors.darkGreen}
                  >
                    Price:
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Typography
                    textAlign="left"
                    fontSize={getREMFromPX(14)}
                    fontFamily="Montserrat"
                    pl={2}
                    color={theme.new.colors.darkGreen}
                    fontWeight="bold"
                  >
                    ${modalOverviewData.fields.Price}/month
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Button
          color="primary"
          onClick={() => handleRestart()}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            p: 2,
          }}
        >
          <Typography
            textAlign="center"
            fontSize={getREMFromPX(16)}
            fontFamily="Montserrat"
            fontWeight="bold"
            sx={{ color: theme.new.colors.teal, textTransform: "capitalize" }}
          >
            Change
          </Typography>
        </Button>
      </Card>
      <Box sx={{ textAlign: "end", pr: "16px" }}>
        {/* We need to do some work for Ammon before we can turn this back on.
        <Typography
          fontSize={getREMFromPX(16)}
          fontFamily="Montserrat"
          fontWeight="500"
          fontStyle="italic"
          color={"#6d6d6d"}
          py={1}
          pt="26px"
        >
          How much will this affect my billing?
        </Typography>
        <Typography
          fontSize={getREMFromPX(20)}
          fontFamily="Montserrat"
          fontWeight="600"
          color={"#253331"}
          py={1}
        >
          Estimated Total: $67.07/month
        </Typography> */}
        <Typography
          fontSize={getREMFromPX(12)}
          fontFamily="Montserrat"
          fontWeight="500"
          fontStyle="italic"
          color={"#6d6d6d"}
          py="26px"
        >
          *Your Network Operator and Service Provider Fees are billed separately
        </Typography>
      </Box>
    </Box>
  );
};

export default CardContent;
