import { styled } from "@mui/material";
import { Link, LinkProps } from "react-router-dom";
import useGetThemePath from "../../hooks/useGetThemePath";
import { getLinkStyle, LinkSize } from "./Styles";

interface StyledLinkProps extends LinkProps {
  size?: keyof typeof LinkSize;
  disabled?: boolean;
}

const StyledLinkComponent = styled(Link)<StyledLinkProps>(
  ({ size, disabled }) => {
    const themePath = useGetThemePath();
    return getLinkStyle(size || LinkSize.small, themePath, disabled);
  }
);

const StyledLink = ({ children, ...props }: StyledLinkProps) => (
  <StyledLinkComponent {...props}>{children}</StyledLinkComponent>
);
export default StyledLink;
