import { useCallback, useMemo, useState } from "react";

const useMenuAnchor = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement | SVGSVGElement>(
    null
  );
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement | SVGSVGElement>) =>
      setAnchorEl(event.currentTarget),
    []
  );

  const handleClose = useCallback(() => setAnchorEl(null), []);

  return {
    open,
    handleClick,
    handleClose,
    anchorEl,
  };
};
export default useMenuAnchor;
